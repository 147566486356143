import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-select-risorse-avanzamode',
  templateUrl: './select-risorse-avanzamode.component.html',
  styleUrls: ['./select-risorse-avanzamode.component.scss'],
})
export class SelectRisorseAvanzamodeComponent {
  @Input() avanzaMode = 0;
  @Output() avanzaModeChange = new EventEmitter<number>();
  avanzaModes = [
    {value: 0, label: 'Standard', description: 'Viene eseguito solo avanzamento senza trasferimenti'},
    {value: 1, label: 'Misurazione', description: 'Durante avanzamento il materiale viene trasferito nel magazzino impostato in risorsa (in base a conforme, scarto o nc)'},
    {value: 2, label: 'Controllo Qualità', description: 'Viene eseguito avanzamento e trasferimento (magazzino di risorsa) durante i controlli qualità'},
    {value: 3, label: 'Consumo Contenitori Entrata', description: 'Viene eseguito avanzamento dei contenitori del materiale. Viene caricato il prodotto nel contenitore del materiale consumato. Scansione solo dei contenitori durante scelta materiale.'},
    {value: 4, label: 'Consumo Contenitori Selezionati', description: 'Viene eseguito avanzamento dei contenitori selezionati tra quelli caricati per il materiale. Viene caricato il prodotto nel contenitore del materiale consumato. Scansione dei contenitori per carico e scarico.'},
    {value: 5, label: 'Consumo Contenitori Uscita', description: 'Viene eseguito avanzamento dei contenitori in uscita (durante carico prodotto). Viene caricato il prodotto nel contenitore del materiale consumato. Scansione solo dei contenitori durante carico prodotto.'},
    {value: 6, label: 'Consuntivo totale', description: 'Durante carico viene richiesta di inserire la quantità totale del prodotto.'}
  ];
  selectAvanzaMode = (e: any) => this.avanzaModeChange.emit(+e.target.value);
}
