import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Movimento} from '../_models/Movimento';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {HttpClient} from '@angular/common/http';
import {SaveMessage} from '../_helpers/save-message';
import {OrganizzazioniService} from './organizzazioni.service';
import {environment} from '../../environments/environment';
import {SearchResult} from '../_models/SearchResult';
import {Giacenza} from '../_models/Giacenza';
import {Contenitore} from '../_models/Contenitore';
import * as signalR from "@microsoft/signalr";

@Injectable({ providedIn: 'root' })
export class MovimentiService {
  public lastMovimentoCreated: BehaviorSubject<Movimento> = new BehaviorSubject<Movimento>(null);
  public lastMovimentoUpdated: BehaviorSubject<Movimento> = new BehaviorSubject<Movimento>(null);
  public lastMovimentoDeleted: BehaviorSubject<Movimento> = new BehaviorSubject<Movimento>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private hubConnection: HubConnection;

  constructor(
    private http: HttpClient,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {}

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/movimento`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(movimento: Movimento) => {
        if (movimento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo movimento creato!');
          this.lastMovimentoCreated.next(movimento);
        }
      });

      this.hubConnection.on('SendUpdated',(movimento: Movimento) => {
        if (movimento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Movimento aggiornato!');
          this.lastMovimentoUpdated.next(movimento);
        }
      });

      this.hubConnection.on('SendDeleted',(movimento: Movimento) => {
        if (movimento.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Movimento eliminato!');
          this.lastMovimentoDeleted.next(movimento);
        }
      });
    });
  }

  async getMovimentiFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Movimento>>(`${environment.localApiUrl}Movimenti/MovimentiFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getMovimentoAsync(orgId: number, id: number) {
    // eslint-disable-next-line max-len
    return await this.http.get<Movimento>(`${environment.localApiUrl}Movimenti/Movimento?orgId=${orgId}&id=${id}`)
      .toPromise();
  }

  async createMovimentoAsync(movimento: Movimento) {
    return await this.http.post<Movimento>(`${environment.localApiUrl}Movimenti/CreateMovimento`, movimento)
      .toPromise();
  }

  async updateMovimentoAsync(movimento: Movimento) {
    return await this.http.post<Movimento>(`${environment.localApiUrl}Movimenti/UpdateMovimento`, movimento)
      .toPromise();
  }

  async deleteMovimentoAsync(orgId: number, id: number) {
    return await this.http.get<any>(`${environment.localApiUrl}Movimenti/DeleteMovimento/${orgId}/${id}`)
      .toPromise();
  }

  async getContenitoriFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean, codArt: string) {
    return await this.http.post<SearchResult<Contenitore>>(`${environment.localApiUrl}Movimenti/ContenitoriFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc, codArt })
      .toPromise();
  }

  async getContenitoreAsync(orgId: number, codBox: string) {
    // eslint-disable-next-line max-len
    return await this.http.get<Contenitore>(`${environment.localApiUrl}Movimenti/Contenitore?orgId=${orgId}&codBox=${codBox}`)
      .toPromise();
  }

  async checkContenitoreAsync(orgId: number, codBox: string) {
    // eslint-disable-next-line max-len
    return await this.http.get<Contenitore>(`${environment.localApiUrl}Movimenti/CheckContenitore?orgId=${orgId}&codBox=${codBox}`)
      .toPromise();
  }

  async createContenitoreAsync(contenitore: Contenitore): Promise<Contenitore> {
    return await this.http.post<Contenitore>(`${environment.localApiUrl}Movimenti/CreateContenitore`, contenitore)
      .toPromise();
  }

  async updateContenitoreAsync(contenitore: Contenitore) {
    return await this.http.post<Contenitore>(`${environment.localApiUrl}Movimenti/UpdateContenitore`, contenitore)
      .toPromise();
  }

  async deleteContenitoreAsync(orgId: number, id: number) {
    return await this.http.get<any>(`${environment.localApiUrl}Movimenti/DeleteContenitore/${orgId}/${id}`)
      .toPromise();
  }

  async emptyContenitoreAsync(orgId: number, codBox: string) {
    return await this.http.get<any>(`${environment.localApiUrl}Movimenti/EmptyContenitore?orgId=${orgId}&codBox=${codBox}`)
      .toPromise();
  }

  async getGiacenzaByCodBoxAsync(orgId: number, codBox: string) {
    return await this.http.post<Giacenza>(`${environment.localApiUrl}Movimenti/GetGiacenzaByCodBox?orgId=${orgId}`,
      { codBox })
      .toPromise();
  }

  async ddtTrasferimentoInternoAsync(orgId: number, ddtType: string, movimenti: Movimento[]) {
    return await this.http.post<any>(`${environment.localApiUrl}Movimenti/DdtTrasferimentoInterno`,
      { orgId, ddtType, movimenti })
      .toPromise();
  }

  padLeft(num: number, size: number) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
