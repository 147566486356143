import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AnagraficaIndirizzo} from '../../_models/AnagraficaIndirizzo';
import {SaveMessage} from '../../_helpers/save-message';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LocalService} from '../../_services/local.service';
import {SearchResult} from '../../_models/SearchResult';

@Component({
  selector: 'app-anagrafica-indirizzo-input',
  templateUrl: './anagrafica-indirizzo-input.component.html',
  styleUrls: ['./anagrafica-indirizzo-input.component.scss'],
})
export class AnagraficaIndirizzoInputComponent  implements OnInit {
  @Input() codCliFor: number;
  @Input() numProgr = -1;
  @Output() numProgrChange = new EventEmitter<number>();
  @Input() listMode = false;
  @Input() tipoAnagr = 0;
  indirizzi: AnagraficaIndirizzo[] = [];
  showIndirizzi = false;
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioneService: OrganizzazioniService,
    private localService: LocalService) { }

  ngOnInit() {
    this.showIndirizzi = this.listMode;
    if (this.listMode) {
      this.getIndirizziOfAnagrafica();
    }
  }

  getIndirizzi = () => {
    this.showIndirizzi = !this.showIndirizzi;
    if (!this.showIndirizzi) {
      this.indirizzi = [];
      return false;
    }
    this.getIndirizziOfAnagrafica();
  };

  getIndirizziOfAnagrafica() {
    this.loading = true;
    this.localService.getIndirizziOfAnagrafiche(this.organizzazioneService.organizzazioneId, this.codCliFor, this.tipoAnagr)
      .then((_indirizzi: SearchResult<AnagraficaIndirizzo>) => {
        if (_indirizzi && _indirizzi.totalCount > 0) {
          this.indirizzi = _indirizzi.result;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento'))
      .finally(() => this.loading = false);
  }

  selectIndirizzo = (indirizzo: AnagraficaIndirizzo) => {
    if (!indirizzo) {
      return false;
    }
    if (!this.listMode) {
      this.showIndirizzi = false;
      this.indirizzi = [];
    }
    this.numProgrChange.emit(indirizzo.numProgr);
  };
}
