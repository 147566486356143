import { Component, OnInit, OnDestroy } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {constants} from '../../../_helpers/constants';
import {Subscription} from 'rxjs';
import {QualitaService} from '../../../_services/qualita.service';
import {AuthenticationService} from '../../../_services/authentication.service';
import {OrdineGroup} from '../../../_models/OrdineGroup';
import {Contenitore} from '../../../_models/Contenitore';
import {AvanzamentoService} from '../../../_services/avanzamento.service';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SaveMessage} from '../../../_helpers/save-message';
import {Movimento} from '../../../_models/Movimento';
import {MovimentiService} from '../../../_services/movimenti.service';
import {LocalService} from '../../../_services/local.service';

@Component({
  selector: 'app-controlli-qualita-ordine',
  templateUrl: './controlli-qualita-ordine.component.html',
  styleUrls: ['./controlli-qualita-ordine.component.scss'],
})
export class ControlliQualitaOrdineComponent  implements OnInit, OnDestroy {
  ordine: OrdineGroup;
  tipoControllo = 0;
  statoControlliQualita = 0;
  codBox = '';
  isConforme = true;
  activeTransfer = false;
  codMagTransfer = '';
  codMagAreaTransfer = '';
  codMagTransferNc = '';
  codMagAreaTransferNc = '';
  quantita = 0;
  subscriptions: Subscription;
  boxes: Contenitore[] = [];
  loading = false;
  protected readonly constants = constants;
  constructor(
    public authService: AuthenticationService,
    private qualitaService: QualitaService,
    private avanzamentoService: AvanzamentoService,
    private movimentiService: MovimentiService,
    private localService: LocalService,
    private saveMessage: SaveMessage,
    private organizzazioneService: OrganizzazioniService,
    private modalCtrl: ModalController) {
    this.subscriptions = this.localService.searchOrdineGroup
      .subscribe((ordineGroup: OrdineGroup) => this.ordine = ordineGroup);
    this.subscriptions.add(this.qualitaService.statoControlli
      .subscribe((_stato: number) => {
        this.statoControlliQualita = _stato;
        if (_stato === 2) {
          let qtaConforme = 0;
          let qtaNonConforme = 0;
          const dataEvento = Math.floor(new Date().getTime() / 1000);
          this.boxes.forEach((_contenitore: Contenitore) => {
            this.inviaTrasferimento(dataEvento, _contenitore);
            qtaConforme += _contenitore.isNc ? 0 : _contenitore.giacenza;
            qtaNonConforme += !_contenitore.isNc ? 0 : _contenitore.giacenza;
          });
          this.ordine.quantitaLavorata += qtaConforme;
          this.ordine.quantitaNonConforme += qtaNonConforme;
          this.localService.updateOrdine(this.ordine)
            .catch(() => this.saveMessage.messageError('Ci sono problemi con salvataggio ordine'));
          this.modalCtrl.dismiss(true, 'confirm');
        }
      }));
  }

  ngOnInit() {
    if (this.ordine && this.ordine.risorsa) {
      this.activeTransfer = this.ordine.risorsa.avanzaMode === 2;
      this.codMagTransfer = this.ordine.risorsa.codMagazzino;
      this.codMagAreaTransfer = this.ordine.risorsa.codMagArea;
      this.codMagTransferNc = this.ordine.risorsa.codMagNc;
      this.codMagAreaTransferNc = this.ordine.risorsa.codMagAreaNc;
      this.loading = true;
    }
    if (this.activeTransfer) {
      this.localService.getMateriale()
        .finally(() => this.loading = false);
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  foundNc = () => {
    this.isConforme = false;
  };
  
  setBoxNc = (box: Contenitore) => box.isNc = !box.isNc; 

  selectBox = (box: Contenitore) => {
    if (this.boxes && this.boxes.length > 0) {
      const valid = this.boxes
        .filter((_con: Contenitore) => _con.codice === box.codice);
      if (valid && valid.length) {
        return false;
      }
    }
    if (box && box.giacenza > 0 &&
      this.boxes.indexOf(box) === -1) {
      this.quantita += box.giacenza;
      this.boxes.push(box);
      this.createMovimentoMateriale(box);
    }
  };
  removeBox = (box: Contenitore) => {
    this.quantita -= box.giacenza;
    this.boxes = this.boxes.filter((_con: Contenitore) => _con !== box);
  };

  inviaTrasferimento = (dataEvento: number, contenitore: Contenitore) => {
    this.loading = true;
    this.ordine.codMagPrincipale = contenitore.isNc ? this.codMagTransferNc : this.codMagTransfer;
    this.ordine.codAreaMagPrinc = contenitore.isNc ? this.codMagAreaTransferNc : this.codMagAreaTransfer;
    const ordineGroup: OrdineGroup = {...this.ordine,
      trasfCodMag: contenitore.isNc ? this.codMagTransferNc : this.codMagTransfer,
      trasfCodArea: contenitore.isNc ? this.codMagAreaTransferNc : this.codMagAreaTransfer,
      codBox: contenitore.codice, ordini: [this.ordine], avvioType: 0 };
    const qtaConforme = contenitore.isNc ? 0 : this.ordine.quantitaLavorata + contenitore.giacenza;
    const qtaNonConforme = contenitore.isNc ? this.ordine.quantitaNonConforme + contenitore.giacenza : 0;
    const tipoTracciato = this.authService.checkPermesso(constants.MOD_PRO_BASE_LOTTI) ? 1 : 0;
    this.avanzamentoService.invioAvanzaGroup(this.organizzazioneService.organizzazioneId,dataEvento,
      ordineGroup, qtaConforme, 0, qtaNonConforme, tipoTracciato, this.authService.currentUserValue.userName)
      .then(() => this.createMovimento(dataEvento, contenitore))
      .catch(() => this.saveMessage.messageError('Ci sono problemi con creazione trasferimento'));
    this.loading = false;
  };
  
  createMovimentoMateriale = (box: Contenitore) => {
    const dataEvento = Math.floor(new Date().getTime() / 1000);
    const movimento: Movimento = {
      organizzazioneId: this.organizzazioneService.organizzazioneId,
      id: 0,
      dataUnixRegistrazione: dataEvento,
      rifOdP: this.ordine.rifOdP,
      idRigaMateriale: this.ordine.idRiga,
      codArt: box.codArt,
      unitaMisura: box.udM,
      quantita: 0,
      coeffX1: 1,
      codLottoAlfa: this.ordine.rifLottoProdotto,
      codBox: box.codice,
      contesto: 0,
      tipoMovimento: -1,
      //idAnagrafica: this.codCliFor,
      codMagazzinoIn: '',
      codMagAreaIn: '',
      codMagazzinoOut: box.codMagazzino,
      codMagAreaOut: box.codMagArea
    };
    this.movimentiService.createMovimentoAsync(movimento)
      .catch(() => this.saveMessage
        .messageError(`Ci sono problemi con creazione movimento ${box.codice}`));
  };

  createMovimento = (dataEvento: number, box: Contenitore) => {
    if (!box.codArt || box.giacenza <= 0) {
      return;
    }
    const movimento: Movimento = {
      organizzazioneId: this.organizzazioneService.organizzazioneId,
      id: 0,
      dataUnixRegistrazione: dataEvento,
      rifOdP: this.ordine.rifOdP,
      idRigaMateriale: this.ordine.idRiga,
      codArt: this.ordine.isLastOfRiga ? this.ordine.codiceArticolo : box.codArt,
      unitaMisura: this.ordine.udM,
      quantita: box.giacenza,
      coeffX1: 1,
      codLottoAlfa: this.ordine.rifLottoProdotto,
      codBox: box.codice,
      contesto: 0,
      tipoMovimento: 1,
      //idAnagrafica: this.codCliFor,
      codMagazzinoIn: box.isNc ? this.codMagTransferNc : this.codMagTransfer,
      codMagAreaIn: box.isNc ? this.codMagAreaTransferNc : this.codMagAreaTransfer,
      codMagazzinoOut: '',
      codMagAreaOut: ''
    };
    this.movimentiService.createMovimentoAsync(movimento)
      .catch(() => this.saveMessage
        .messageError(`Ci sono problemi con creazione movimento ${box.codice}`));
  };

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');
}
