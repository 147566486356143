import {Component, EventEmitter, Input, Output} from '@angular/core';
import {BilanceService} from '../../_services/bilance.service';
import {Subscription} from 'rxjs';
import {Pesata} from '../../_models/Pesata';

@Component({
  selector: 'app-bilanca-input',
  templateUrl: './bilanca-input.component.html',
  styleUrls: ['./bilanca-input.component.scss'],
})
export class BilancaInputComponent {
  @Input() peso = 0;
  @Output() pesoChange = new EventEmitter<number>();
  @Input() pesoNetto = 0;
  @Output() pesoNettoChange = new EventEmitter<number>();
  @Input() tara = 0;
  @Output() taraChange = new EventEmitter<number>();
  @Input() quantita = 0;
  @Output() quantitaChange = new EventEmitter<number>();
  @Input() selMode = 1;
  @Input() udM = 'KG';
  @Input() udM2 = 'M';
  @Input() codArt = '';
  pesoDaBilancia = 0;
  taraDaBilancia = 0;
  numCampione = 1;
  pesoCampione = 0;
  pesoUnitario = 0;
  selRisorsaCode = '';
  activeCodArt = '';
  subscription: Subscription;

  constructor(
    private bilanciaService: BilanceService) {
    this.selRisorsaCode = this.bilanciaService.searchBilanciaCode.value;
    this.subscription = this.bilanciaService.lastPesataCreated.subscribe((_pesata: Pesata) => {
      if (_pesata && this.selRisorsaCode === _pesata.codice &&
        (this.codArt === '' || this.codArt === this.activeCodArt)) {
        this.taraDaBilancia = _pesata.pesoTara != 0 && this.taraDaBilancia != _pesata.pesoTara ? _pesata.pesoTara : this.taraDaBilancia;
        this.pesoDaBilancia = _pesata.pesoNetto + _pesata.pesoTara;
        //this.confirmQta();
      }
    });
    this.subscription.add(this.bilanciaService.activeCodArt
      .subscribe(codArt => this.activeCodArt = codArt));
  }

  activeBilancia = () => this.bilanciaService
    .activeCodArt.next(this.codArt);
  setBilancia = (bilanciaCode: string) => this.bilanciaService.setBilanciaCode(bilanciaCode);
  setPesoUnitario = () =>
    this.pesoUnitario = this.numCampione > 0 ? this.pesoCampione / this.numCampione : 0;
  resetPesoUnitario = () => this.pesoUnitario = 0;
  resetBilanciaValue = () => {
    this.taraDaBilancia = 0;
    this.pesoDaBilancia = 0;
  };
  confirmQta = () => {
    this.pesoNetto = !this.pesoNetto ? 0 : this.pesoNetto
    this.tara += this.taraDaBilancia;
    this.peso += this.pesoDaBilancia;
    this.pesoNetto += (this.pesoDaBilancia - this.taraDaBilancia);
    this.taraChange.emit(this.tara);
    this.pesoChange.emit(this.peso);
    this.pesoNettoChange.emit(this.pesoNetto);
    this.quantita = (this.peso - this.tara) / (this.pesoUnitario > 0 ? this.pesoUnitario : 1);
    this.quantitaChange.emit(this.quantita);
    
    this.pesoDaBilancia = 0;
    this.taraDaBilancia = 0;
  };
}
