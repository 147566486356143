import { Component, Input } from '@angular/core';
import {ArticoloSchedeTec} from '../../_models/ArticoloSchedeTec';

@Component({
  selector: 'app-articolo-schede-tecniche',
  templateUrl: './articolo-schede-tecniche.component.html',
  styleUrls: ['./articolo-schede-tecniche.component.scss'],
})
export class ArticoloSchedeTecnicheComponent {
  @Input() schedeTec: ArticoloSchedeTec[] = [];
  @Input() codArt = '';
  isOpen = false;
  setOpen = () => this.isOpen = !this.isOpen;
}
