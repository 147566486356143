import { Component, OnInit } from '@angular/core';
import {AuthenticationService} from '../../../_services/authentication.service';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {EventiService} from '../../../_services/eventi.service';
import {ModalController} from '@ionic/angular';
import {SaveMessage} from '../../../_helpers/save-message';
import {OrdinispedizioniService} from '../../../_services/ordinispedizioni.service';
import {OrdSpedEsolver} from '../../../_models/OrdSpedEsolver';
import {OrdSped} from '../../../_models/OrdSped';
import { constants } from '../../../_helpers/constants';

@Component({
  selector: 'app-spedizione-esolver-launch',
  templateUrl: './spedizione-esolver-launch.component.html',
  styleUrls: ['./spedizione-esolver-launch.component.scss'],
})
export class SpedizioneEsolverLaunchComponent  implements OnInit {
  spedizione: OrdSpedEsolver;
  newSpedizione: OrdSped;
  loading = false;
  spedizioneUse: OrdSped;
  spedizioneInUse = false;
  today = new Date().getTime();
  constants = constants;
  constructor(
    public authService: AuthenticationService,
    private organizzazioniService: OrganizzazioniService,
    private spedizioniService: OrdinispedizioniService,
    private eventiService: EventiService,
    private modalCtrl: ModalController,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.newSpedizione = {
      ...this.spedizione,
      organizzazioneId: this.organizzazioniService.organizzazioneId
    };
  }

  createSpedizione(spedizione: OrdSped) {
    this.loading = true;
    this.spedizioniService.createOrdineSpedizione(spedizione)
      .then((_spedizione: OrdSped) => {
        this.saveMessage.messageInfo('Spedizione lanciata con successo');
        this.modalCtrl.dismiss(_spedizione, 'confirm');
      })
      .catch(() => this.saveMessage.messageError('Problemi durante il lancio di spedizione'))
      .finally(() => this.loading = false);
  }

  updateSpedizione = () => {};

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');
}
