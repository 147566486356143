import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalService} from '../../../_services/local.service';
import {Anagrafica} from '../../../_models/Anagrafica';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SearchResult} from '../../../_models/SearchResult';
import {SaveMessage} from '../../../_helpers/save-message';
import {Subscription} from 'rxjs';
import {Organizzazione} from '../../../_models/Organizzazione';

@Component({
  selector: 'app-select-terzisti',
  templateUrl: './select-terzisti.component.html',
  styleUrls: ['./select-terzisti.component.scss'],
})
export class SelectTerzistiComponent  implements OnInit {
  @Input() selectedTerzistaId = 0;
  @Input() codLav = '';
  @Output() terzistaChange = new EventEmitter<Anagrafica>();
  terzisti: Anagrafica[] = [];
  isOpen = true;
  subscription: Subscription;
  loading = false;
  constructor(
    private organizzazioneService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) {
    this.subscription = this.organizzazioneService.currentOrganizzazioneSubject
      .subscribe((_org: Organizzazione) => this.getTerzistiOrdiniAperti());
  }

  ngOnInit() {
    this.getTerzistiOrdiniAperti();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getTerzistiOrdiniAperti = () => {
    this.loading = true;
    this.localService
      .getTerzistiOrdiniApertiAsync(this.organizzazioneService.organizzazioneId, this.codLav)
      .then((_terzisti: SearchResult<Anagrafica>) => {
        if (_terzisti.totalCount > 0) {
          this.terzisti = _terzisti.result;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di terzisti'))
      .finally(() => this.loading = false);
  };

  selectTerzista = (terzista: Anagrafica) => {
    if (!this.isOpen) {
      this.isOpen = true;
      return false;
    }
    this.isOpen = false;
    this.selectedTerzistaId = terzista.id;
    this.terzistaChange.emit(terzista);
  };

  openList = () => this.isOpen = !this.isOpen;
}
