import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LocalService} from '../../_services/local.service';
import {Anagrafica} from '../../_models/Anagrafica';
import {SearchResult} from '../../_models/SearchResult';
import {SaveMessage} from '../../_helpers/save-message';

@Component({
  selector: 'app-anagrafica-input',
  templateUrl: './anagrafica-input.component.html',
  styleUrls: ['./anagrafica-input.component.scss'],
})
export class AnagraficaInputComponent  implements OnInit {
  @Input() codCliFor: number;
  @Output() codCliForChange = new EventEmitter<number>();
  @Output() cliForChange = new EventEmitter<Anagrafica>();
  @Input() tipoAnagr = 0;
  @Input() terzista = false;
  searchAnagrafica = '';
  anagrafiche: Anagrafica[] = [];
  showAnagrafiche = false;
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioneService: OrganizzazioniService,
    private localService: LocalService) { }

  ngOnInit() {}

  getAnagrafiche = (changeShow: boolean) => {
    if (changeShow) {
      this.showAnagrafiche = !this.showAnagrafiche;
      if (!this.showAnagrafiche) {
        this.anagrafiche = [];
        return false;
      }
    }
    this.loading = true;
    this.localService.getAnagrafiche(this.organizzazioneService.organizzazioneId, 
      this.tipoAnagr, this.terzista, this.searchAnagrafica, 0, 0)
      .then((_anagrafiche: SearchResult<Anagrafica>) => {
        if (_anagrafiche && _anagrafiche.totalCount > 0) {
          this.anagrafiche = _anagrafiche.result;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento'))
      .finally(() => this.loading = false);
  };

  selectAnagrafica = (anagrafica: Anagrafica) => {
    if (!anagrafica || !anagrafica.id) {
      return false;
    }
    this.showAnagrafiche = false;
    this.anagrafiche = [];
    this.codCliForChange.emit(anagrafica.id);
    this.cliForChange.emit(anagrafica);
  };
}
