import {Component, OnInit, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {IonInput} from '@ionic/angular';
import {MovimentiService} from '../../_services/movimenti.service';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {SaveMessage} from '../../_helpers/save-message';
import {Contenitore} from '../../_models/Contenitore';
import {SearchResult} from '../../_models/SearchResult';

@Component({
  selector: 'app-box-input',
  templateUrl: './box-input.component.html',
  styleUrls: ['./box-input.component.scss'],
})
export class BoxInputComponent  implements OnInit {
  @ViewChild('codBoxInput') codBoxInput!: IonInput;
  @Input() codBox = '';
  @Output() codBoxChange = new EventEmitter<string>();
  @Input() formato = 0;
  @Input() rifOdP = '';
  @Input() idAnagrafica = 0;
  @Input() codArt = '';
  @Input() desArt = '';
  @Input() udM = '';
  @Input() udM2 = '';
  @Input() codLottoAlfa = '';
  @Output() giacenzaOfBox = new EventEmitter<Contenitore>();
  @Input() showCreate = false;
  @Input() generaOnInit = false;
  @Input() onlyAvailable = false;
  contenitore: Contenitore;
  giacenze: Contenitore[] = [];
  showGiacenza = false;
  loading = false;
  isValid = 0;
  constructor(
    private organizzazioneService: OrganizzazioniService,
    private movimentiService: MovimentiService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    //this.codBoxInput.setFocus();
    if (this.generaOnInit){
      this.createContenitore();
    }
  }

  getGiacenza = () => {
    this.isValid = 0;
    if (!this.codBox || this.codBox === '') {
      return false;
    }
    this.loading = true;
    this.movimentiService
      .checkContenitoreAsync(this.organizzazioneService.organizzazioneId, this.codBox)
      .then((_giacenza: Contenitore) => {
        if ((!this.codArt && _giacenza.codArt) || (_giacenza.codArt === this.codArt)) {
          this.isValid = 1;
        }
        this.giacenzaOfBox.emit(_giacenza);
        this.codBoxChange.emit(this.codBox);
      })
      .catch(() => {
        this.isValid = -1;
        this.saveMessage.messageError('Ci sono problemi con caricamento di Contenitore');
      })
      .finally(() => this.loading = false);
  };

  getContenitori = () => {
    this.showGiacenza = !this.showGiacenza;
    if (!this.showGiacenza) {
      this.giacenze = [];
      return false;
    }
    this.loading = true;
    this.movimentiService.getContenitoriFilteredAsync(this.organizzazioneService.organizzazioneId,
      '',0,0,'codice',true,this.codArt)
      .then((_contenitori: SearchResult<Contenitore>) => {
        if (_contenitori.totalCount > 0) {
          _contenitori.result.forEach((_contenitore: Contenitore) => {
            if ((!this.onlyAvailable || _contenitore.giacenza > 0) &&
              (!this.rifOdP || _contenitore.rifOdP === this.rifOdP)) {
              this.giacenze.push(_contenitore);
            }
          });
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con carimento di giacenze'))
      .finally(() => this.loading = false);
  };

  selectContenitore(contenitore: Contenitore) {
    if (!contenitore || !contenitore.codice) {
      return false;
    }
    this.showGiacenza = false;
    this.giacenze = [];
    this.giacenzaOfBox.emit(contenitore);
    this.codBox = contenitore.codice;
    this.codBoxChange.emit(this.codBox);
  };

  createContenitore = () => {
    this.contenitore =  {
      id: 0,
      organizzazioneId: this.organizzazioneService.organizzazioneId,
      formato: this.formato,
      tipoBox: 0,
      codice: '',
      descrizione: this.desArt,
      codArt: this.codArt,
      udM: this.udM,
      udM2: this.udM2,
      codLottoAlfa: this.codLottoAlfa,
      rifOdP: this.rifOdP,
      idAnagrafica: this.idAnagrafica,
      isActive: true
    };
    this.loading = true;
    this.movimentiService.createContenitoreAsync(this.contenitore)
      .then((_contenitore: Contenitore) => {
        this.contenitore = _contenitore;
        this.codBoxChange.emit(this.contenitore.codice);
        this.isValid = 1;
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con creazione contenitore'))
      .finally(() => this.loading = false);
  };
}
