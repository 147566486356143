import {Risorsa, Lavorazione, Detail} from './_Index';
import {TipoUmTempo} from '../_helpers/TipoUmTempo';
import {Materiale} from './Materiale';
import {ArticoloSchedeTec} from './ArticoloSchedeTec';

export class OrdineKey extends Detail {
  idDocumento: number;
  idRiga: number;
  idRigaLavorazione: number;
}

export class OrdinePKey extends OrdineKey {
  organizzazioneId: number;
}

export class Ordine extends OrdinePKey {
  risorsaId: number;
  lavorazioneId: number;
  dataRegistrazione?: Date;
  numRegistrazione?: number;
  codiceArticolo?: string;
  descArticolo?: string;
  quantita: number;
  lotto: number;
  quantitaLavorata: number;
  quantitaLavorataUdM2?: number;
  quantitaScartata: number;
  quantitaScartataUdM2?: number;
  quantitaNonConforme?: number;
  quantitaNonConformeUdM2?: number;
  tempoLav?: string;
  quantitaOrdinataUdM2?: number;

  quantitaLavorataWait?: number;
  quantitaScartataWait?: number;
  quantitaNonConformeWait?: number;
  quantitaTrasferimento?: number;
  tempoLavWait?: number;
  fileNameWait?: string;

  qtaRimanente?: number;

  tempoParzialeLav?: string;
  totalWorkingHours?: number;
  partialWorkingHours?: number;
  countWorkingSessions?: number;
  workStartTime?: number;
  lastActionTime?: number;
  lastEventoTime?: number;

  omacState?: string;
  stato: number;
  flgTerminato: boolean;
  statoElimina: number;
  dataTermine: Date;
  commessa?: string;
  umTempo?: TipoUmTempo;

  note: string;

  codBox?: string;
  codMagPrincipale?: string;
  codAreaMagPrinc?: string;

  trasfCodMag?: string;
  trasfCodArea?: string;

  risorsa?: Risorsa;
  lavorazione?: Lavorazione;

  isLastOfRiga?: boolean;
  lastFase?: number;

  isLoading?: boolean;
  onQueue?: boolean;
  //qtaToEsolver?: number;
  erpFound?: boolean;

  rifLottoProdotto?: string;
  rifLottoMateriale?: string;
  boxScarico?: string;
  udM: string;
  udM2?: string;
  rifOdP?: string;
  numFase?: number;

  codCliente?: number;
  ragSocialeCliente?: string;
  faseContoLavoro?: number;
  codTerzista?: number;
  ragSocialeTerzista?: string;

  schedeTec?: ArticoloSchedeTec[];
  materiale?: Materiale[];

  idMovimento?: number;

  rifOrdine?: string;
  
  coeffUdM2? = () => this.quantitaOrdinataUdM2 / this.quantita;
}

export class OrdineCreate extends Ordine {
  codiceRisorsa: string;
  codiceLavorazione: string;
  lottoCode?: string;
}
