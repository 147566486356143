import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-select-type-movimento',
  templateUrl: './select-type-movimento.component.html',
  styleUrls: ['./select-type-movimento.component.scss'],
})
export class SelectTypeMovimentoComponent  {
  @Input() movimentoType: number;
  @Output() movimentoTypeChange = new EventEmitter<number>();
  public movimentoTypes =
    [
      { value: 1, label: 'Carico/Trasferimento' },
      { value: -1, label: 'Scarico' },
      { value: 0, label: 'Retifica' }
    ];
  selectMovimentoType = (e: any) => this.movimentoTypeChange.emit(+e.target.value);
}
