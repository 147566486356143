// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-wrapper {
  width: 80%;
  height: 70%;
  max-width: 600px;
  max-height: 500px;
  --width: 80%;
  --height: 70%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAGE;EACE,UAAA;EACA,WAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,aAAA;AAFJ","sourcesContent":["\n// --- MODAL --- //\n.custom-modal {\n  .modal-wrapper {\n    width: 80%;   // Larghezza personalizzata\n    height: 70%;  // Altezza personalizzata\n    max-width: 600px;\n    max-height: 500px;\n    --width: 80%; // Per supportare variabili CSS\n    --height: 70%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
