import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SearchResult, Ordine, OrdineGroup, OrdineCreate, OrdiniStat, FilterItem, OrdineEsolver, GroupResult, Lavorazione}
  from '../_models/_Index';
import { environment } from '../../environments/environment';
import { OrganizzazioniService } from './organizzazioni.service';
import { HubConnection, HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import {SaveMessage} from '../_helpers/save-message';
import { Subject, BehaviorSubject } from 'rxjs';
import {OrdineEsolverGroup} from '../_models/OrdineEsolverGroup';
import {OrdiniShowColumns} from '../_models/OrdiniShowColumns';
import {GroupEsolverResult} from '../_models/GroupEsolverResult';
import {Anagrafica} from '../_models/Anagrafica';
import {RigaOrdEsolver} from '../_models/RigaOrdEsolver';
import {AnagraficaIndirizzo} from '../_models/AnagraficaIndirizzo';
import {Allegato} from '../_models/Allegato';
import * as signalR from "@microsoft/signalr";

const cachePrefix = 'archiconnect-v2-';
const emptyOrdine = {
  organizzazioneId: 0,
  idDocumento: 0,
  idRiga: 0,
  idRigaLavorazione: 0,
  risorsaId: 0,
  lavorazioneId: 0,
  quantita: 0,
  lotto: 1,
  quantitaLavorata: 0,
  quantitaScartata: 0,
  stato: 0,
  flgTerminato: false,
  statoElimina: 0,
  dataTermine: undefined,
  note: '',
  commessa: '',
  udM: '',
  isLoading: true
};
const emptyOrdineEsolver = {
  dbgruppo: '',
  idDocumento: 0,
  dataRegistrazione: new Date(),
  numRegistrazione: 0,
  idRiga: 0,
  codiceArticolo: '',
  descArt: '',
  idRigaLavorazione: 0,
  lavorazione: '',
  desLavorazione: '',
  stato: 0,
  quantita: 0,
  risorsa: '',
  reparto: '',
  desRisorsa: '',
  dataTermine: new Date(),
  hasErrors: false,
  hasWarnings: false,
  saldato: false,
  //rifOrdine: '',
  quantitaLavorata: 0,
  quantitaScartata: 0,
  quantitaNonConforme: 0,
  tempo: '',
  qtaOrdine: 0,
  commessa: '',
  rifLottoAlfanum: '',
  udM: '',
  numFase: 1
};
const baseShowColumns = {
  rifOrdine: false,
  numRegistrazione: true,
  rifOdP: true,
  dataTermine: true,
  risorsa: true,
  lavorazione: true,
  codiceArticolo: true,
  quantita: true,
  stato: true,
  numFase: false,
  rifLottoProdotto: false,
  rifLottoMateriale: false,
  udM: false
};

@Injectable({ providedIn: 'root' })
export class LocalService {
    public handleRefresh = new Subject();
    public handleSave: Subject<number> = new Subject();
    public totalCount = new BehaviorSubject<number>(0);
    public localFilters = new BehaviorSubject<FilterItem<any>[]>([]);

    public searchOrdineGroup: BehaviorSubject<OrdineGroup> = new BehaviorSubject<OrdineGroup>(emptyOrdine);
    public searchStato: BehaviorSubject<number> = new BehaviorSubject(-1);
    public searchDataDa: BehaviorSubject<string> = new BehaviorSubject('');
    public searchDataA: BehaviorSubject<string> = new BehaviorSubject('');
    public searchText: BehaviorSubject<string> = new BehaviorSubject('');
    public key: BehaviorSubject<string> = new BehaviorSubject('');
    public sortDirection: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public showColumns = new BehaviorSubject<OrdiniShowColumns>(baseShowColumns);

    public lastOrdineLaunched: Subject<Ordine> = new Subject<Ordine>();
    public lastOrdineCreated: BehaviorSubject<Ordine> = new BehaviorSubject<Ordine>(null);
    public lastOrdineUpdated: BehaviorSubject<Ordine> = new BehaviorSubject<Ordine>(null);
    public lastOrdineDeleted: BehaviorSubject<Ordine> = new BehaviorSubject<Ordine>(null);
    public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    private hubConnection: HubConnection;
    private currentOrdineGroup: OrdineGroup;

    constructor(
      private http: HttpClient,
      private organizzazioniService: OrganizzazioniService,
      private saveMessage: SaveMessage) {
      const ordineGroup = JSON.parse(localStorage.getItem(cachePrefix+'ordineGroup')) || emptyOrdine;
      this.searchOrdineGroup.next(ordineGroup);
      this.currentOrdineGroup = ordineGroup;
      const ordiniShowColumns = JSON.parse(localStorage.getItem(cachePrefix+'ordiniShowColumns')) || baseShowColumns;
      this.showColumns.next(ordiniShowColumns);
    }

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/ordine`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .configureLogging(LogLevel.Debug)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));
      this.hubConnection.onreconnected(() => this.hubState.next(1));
      this.hubConnection.onreconnecting(() => this.hubState.next(-1));
      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(ordine: Ordine) => {
        if (ordine.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo ordine creato!');
          this.lastOrdineCreated.next(ordine);
        }
      });

      this.hubConnection.on('SendUpdated',(ordine: Ordine) => {
        if (ordine.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Ordine aggiornato!');
          this.lastOrdineUpdated.next(ordine);
        }
      });

      this.hubConnection.on('SendDeleted',(ordine: Ordine) => {
        if (ordine.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Ordine eliminato!');
          this.lastOrdineDeleted.next(ordine);
        }
      });
    });
  }

    getEmptyOrder = () => emptyOrdine;
    getEmptyOrderEsolver = () => emptyOrdineEsolver;

    async getOrdineWithTimeInfo(ordine: Ordine) {
        return this.getOrdiniTempoAsync(ordine.organizzazioneId, ordine.idDocumento, ordine.idRiga, ordine.idRigaLavorazione)
            .then(async (_ordineTempo: Ordine) => {
                ordine = _ordineTempo;
                this.setOrdineFromEsolver(ordine);
            });
    }

    setOrdineFromEsolver(ordine: Ordine) {
      if (this.currentOrdineGroup.ordini == null){
        return false;
      }
      for (let i = 0; i < this.currentOrdineGroup.ordini.length; i++) {
        if (this.currentOrdineGroup.ordini[i].idDocumento === ordine.idDocumento &&
          this.currentOrdineGroup.ordini[i].idRiga === ordine.idRiga &&
          this.currentOrdineGroup.ordini[i].idRigaLavorazione === ordine.idRigaLavorazione) {
          this.currentOrdineGroup.ordini[i] = ordine;
        }
      }
      this.calculateOrdineGroup();
    }

  async setSumOrdineGroup(ordineGroup: OrdineGroup, getOrdiniTimeInfo?: boolean) {
      this.currentOrdineGroup = ordineGroup;
      if (this.currentOrdineGroup.ordini == null || this.currentOrdineGroup.ordini.length === 0) {
          return false;
      }
      if (getOrdiniTimeInfo) {
        this.currentOrdineGroup.isLoading = true;
        this.getOrdiniGroupTempoAsync(ordineGroup)
          .then((_ordineGroup: OrdineGroup) => {
            this.currentOrdineGroup = _ordineGroup;
            this.calculateOrdineGroup();
          })
          .finally(() =>this.currentOrdineGroup.isLoading = false);
      } else {
        this.calculateOrdineGroup();
      }
  }

  calculateOrdineGroup() {
      this.currentOrdineGroup.quantita = this.currentOrdineGroup.ordini.reduce((sum, current) => sum + current.quantita, 0);
      this.currentOrdineGroup.quantitaLavorata = this.currentOrdineGroup.ordini.reduce((sum, current) => sum + current.quantitaLavorata, 0);
      this.currentOrdineGroup.quantitaScartata = this.currentOrdineGroup.ordini.reduce((sum, current) => sum + current.quantitaScartata, 0);
      this.currentOrdineGroup.quantitaNonConforme = this.currentOrdineGroup.ordini
        .reduce((sum, current) => sum + (current.quantitaNonConforme ?? 0), 0) ?? 0;
      this.currentOrdineGroup.qtaRimanente = this.currentOrdineGroup.ordini.reduce((sum, current) => sum + current.qtaRimanente, 0);
      this.currentOrdineGroup.lastFase = this.currentOrdineGroup.ordini.reduce((sum, current) => sum + current.lastFase, 0);
      this.currentOrdineGroup.totalWorkingHours = this
          .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.totalWorkingHours, 0);
      this.currentOrdineGroup.partialWorkingHours = this
          .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.partialWorkingHours, 0);
      this.currentOrdineGroup.countWorkingSessions = this
          .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.countWorkingSessions, 0);
      this.currentOrdineGroup.quantitaLavorataWait = this
        .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.quantitaLavorataWait, 0);
      this.currentOrdineGroup.quantitaScartataWait = this
        .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.quantitaScartataWait, 0);
    this.currentOrdineGroup.quantitaNonConformeWait = this
      .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.quantitaNonConformeWait, 0);
      this.currentOrdineGroup.tempoLavWait = this
        .currentOrdineGroup.ordini.reduce((sum, current) => sum + current.tempoLavWait, 0);
    this.currentOrdineGroup.tempoLav = this
      .currentOrdineGroup.ordini.reduce((sum, current) => sum + parseFloat(current.tempoLav), 0).toString();
      this.currentOrdineGroup.flgTerminato = this.currentOrdineGroup.ordini.every(({flgTerminato}) => flgTerminato);
      this.currentOrdineGroup.erpFound = this.currentOrdineGroup.ordini.every(({erpFound}) => erpFound);
      this.currentOrdineGroup.workStartTime = this.currentOrdineGroup.ordini[0].workStartTime;
      let maxActionTime = this.currentOrdineGroup.ordini[0].lastActionTime;
      this.currentOrdineGroup.ordini
        .forEach((ordine) => maxActionTime = ordine.lastActionTime > maxActionTime ? ordine.lastActionTime : maxActionTime);
      this.currentOrdineGroup.lastActionTime = maxActionTime;
      let maxEventoTime = this.currentOrdineGroup.ordini[0].lastEventoTime;
      this.currentOrdineGroup.ordini
        .forEach((ordine) => maxEventoTime = ordine.lastEventoTime > maxEventoTime ? ordine.lastEventoTime : maxEventoTime);
      this.currentOrdineGroup.lastEventoTime = maxEventoTime;
      this.currentOrdineGroup.stato = this.currentOrdineGroup.ordini[0].stato;
      this.setOrdineGroup(this.currentOrdineGroup);
  }

    setOrdineGroup(ordineGroup: OrdineGroup, onlyStorageUpdate?: boolean) {
        localStorage.setItem(cachePrefix+'ordineGroup', JSON.stringify(ordineGroup));
        if (!onlyStorageUpdate){
            this.searchOrdineGroup.next(ordineGroup);
            this.currentOrdineGroup = ordineGroup;
        }
    }

  setOrdiniShowColumns(showColumns: OrdiniShowColumns, onlyStorageUpdate?: boolean) {
    localStorage.setItem(cachePrefix+'ordiniShowColumns', JSON.stringify(showColumns));
    if (!onlyStorageUpdate){
      this.showColumns.next(showColumns);
    }
  }

  resetOrdineGroup(onlyStorageUpdate?: boolean, isLoading?: boolean) {
      const copyOrdine = {...emptyOrdine, isLoading };
    localStorage.setItem(cachePrefix+'ordineGroup', JSON.stringify(copyOrdine));
    if (!onlyStorageUpdate){
      this.searchOrdineGroup.next(copyOrdine);
    }
  }

  getOrdiniInviatiFilteredGroup(
    orgId: number,
    terminato: boolean,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    filters: FilterItem<any>[],
    personal: boolean,
    groupColumns: string[]) {
    const method = personal ? `MyOrdiniInviatiGroup` : `OrdiniInviatiGroup`;
    return this.http.post<SearchResult<OrdineGroup>>(`${environment.localApiUrl}Ordini/${method}`,
      { orgId, terminato, search, page, count, sortField, sortAsc, filters,
        filtroOrdini: this.organizzazioniService.filtroOrdini, groupColumns })
      .toPromise();
  }

  getOrdiniInviatiFilteredGroupByMany(
    orgId: number,
    terminato: boolean,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    filters: FilterItem<any>[],
    groupColumns: string[]) {
    return this.http.post<SearchResult<GroupResult<Ordine>>>(`${environment.localApiUrl}Ordini/MyOrdiniInviatiGroupByMany`,
      { orgId, terminato, search, page, count, sortField, sortAsc,
        filtroOrdini: this.organizzazioniService.filtroOrdini, filters, groupColumns })
      .toPromise();
  }

    getOrdiniInviatiFiltered(
        orgId: number,
        terminato: boolean,
        search: string,
        page: number,
        count: number,
        sortField: string,
        sortAsc: boolean,
        filters: FilterItem<any>[],
        personal: boolean) {
        const method = personal ? `MyOrdiniInviati` : `OrdiniInviati`;
        return this.http.post<SearchResult<Ordine>>(`${environment.localApiUrl}Ordini/${method}`,
            { orgId, terminato, search, page, count, sortField,
              filtroOrdini: this.organizzazioniService.filtroOrdini, sortAsc, filters })
            .toPromise();
    }

  getOrdiniEsolver(
    orgId: number,
    group: string,
    connectionName: string,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    saldato: boolean,
    isPersonal: boolean,
    filters: FilterItem<any>[],
    noKeyFilter: boolean) {
    return this.http.post<SearchResult<OrdineEsolver>>(`${environment.localApiUrl}Esolver/Ordini`,
      { orgId, group, connectionName, search, page, count, sortField, sortAsc,
        filtroOrdini: !isPersonal ? 0 : this.organizzazioniService.filtroOrdini, saldato, filters, noKeyFilter })
      .toPromise();
  }

  getOrdiniGroupEsolver(
    orgId: number,
    group: string,
    connectionName: string,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    saldato: boolean,
    isPersonal: boolean,
    filters: FilterItem<any>[],
    groupColumns: string[]) {
    return this.http.post<SearchResult<OrdineEsolverGroup>>(`${environment.localApiUrl}Esolver/OrdiniGroup`,
      { orgId, group, connectionName, search, page, count, sortField, sortAsc,
        filtroOrdini: !isPersonal ? 0 : this.organizzazioniService.filtroOrdini, saldato, filters, groupColumns })
      .toPromise();
  }

  getOrdiniGroupByManyEsolver(
    orgId: number,
    group: string,
    connectionName: string,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    saldato: boolean,
    isPersonal: boolean,
    filters: FilterItem<any>[],
    groupColumns: string[]) {
    return this.http.post<SearchResult<GroupEsolverResult<OrdineEsolver>>>(`${environment.localApiUrl}Esolver/OrdiniGroupByMany`,
      { orgId, group, connectionName, search, page, count, sortField, sortAsc,
        filtroOrdini: !isPersonal ? 0 : this.organizzazioniService.filtroOrdini, saldato, filters, groupColumns })
      .toPromise();
  }

  async getMaterialeOfOrdineGroup(ordineGroup: OrdineGroup) {
    return this.http.post<OrdineGroup>(`${environment.localApiUrl}Esolver/MaterialeOfOrdineGroup`, ordineGroup)
      .toPromise();
  }

  getMateriale() {
    return this.getMaterialeOfOrdineGroup(this.searchOrdineGroup.value)
      .then((_ordineGroup: OrdineGroup) => {
        this.currentOrdineGroup = _ordineGroup;
        this.calculateOrdineGroup();
        //this.searchOrdineGroup.next(_ordineGroup);
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento materiale'));
  }

    async createOrdine(ordine: OrdineCreate) {
        return this.http.post<Ordine>(`${environment.localApiUrl}Ordini/CreaOrdineWithCode`, ordine)
            .toPromise();
    }

    async updateOrdine(ordine: Ordine) {
        return this.http.post<Ordine>(`${environment.localApiUrl}Ordini/UpdateOrdine`, ordine)
            .toPromise();
    }

    async updloadFileOrdineAsync(idDocumento: number, idRiga: number, idRigaLavorazione: number, orgId: number, files: any) {
        if (files.length === 0) {
            return;
        }
        const fileToUpload = files[0] as File;
        const formData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        return await this.http.post(`${environment.localApiUrl}Ordini/Upload/${idDocumento}/${idRiga}/${idRigaLavorazione}/${orgId}`,
          formData)
          .toPromise();
    }

  getOrdiniGroupTempoAsync(ordineGroup: OrdineGroup) {
    // eslint-disable-next-line max-len
    return this.http.post<OrdineGroup>(`${environment.localApiUrl}Ordini/CalcoloTempoOrdineGroup`, ordineGroup)
      .toPromise();
  }

    getOrdiniTempoAsync(orgId: number, idDocumento: number, idRiga: number, idRigaLavorazione: number) {
      // eslint-disable-next-line max-len
        return this.http.get<Ordine>(`${environment.localApiUrl}Ordini/CalcoloTempoOrdine?orgId=${orgId}&idDocumento=${idDocumento}&idRiga=${idRiga}&idRigaLavorazione=${idRigaLavorazione}`)
            .toPromise();
    }

    getOrdineAsync(orgId: number, idDocumento: number, idRiga: number, idRigaLavorazione: number) {
      // eslint-disable-next-line max-len
        return this.http.get<Ordine>(`${environment.localApiUrl}Ordini/Ordine?orgId=${orgId}&idDocumento=${idDocumento}&idRiga=${idRiga}&idRigaLavorazione=${idRigaLavorazione}`)
            .toPromise();
    }

  getOrdineByRifOdP(orgId: number, rifOdP: string) {
    return this.http.get<Ordine>(`${environment.localApiUrl}Ordini/OrdineByRifOdP?orgId=${orgId}&rifOdP=${rifOdP}`)
      .toPromise();
  }

  deleteOrdineAsync(orgId: number, idDocumento: number, idRiga: number, idRigaLavorazione: number) {
    // eslint-disable-next-line max-len
    return this.http.get<Ordine>(`${environment.localApiUrl}Ordini/DeleteOrdine?orgId=${orgId}&idDocumento=${idDocumento}&idRiga=${idRiga}&idRigaLavorazione=${idRigaLavorazione}`)
      .toPromise();
  }

  getStatistics(orgId: number) {
      return this.http.get<OrdiniStat>(`${environment.localApiUrl}Ordini/GetOrdiniStatistiche?orgId=${orgId}`)
        .toPromise();
  }

  async getAnagrafiche(orgId: number, tipo: number, terzista: boolean, search: string, page: number, count: number) {
    // eslint-disable-next-line max-len
    return await this.http.post<SearchResult<Anagrafica>>(`${environment.localApiUrl}Esolver/Anagrafiche/${orgId}/${tipo}`,
      { anagTerzista: terzista, search, page, count})
      .toPromise();
  }

  async getIndirizziOfAnagrafiche(orgId: number, codCliFor:number, tipo: number) {
    // eslint-disable-next-line max-len
    return await this.http.get<SearchResult<AnagraficaIndirizzo>>(`${environment.localApiUrl}Esolver/IndirizziOfAnagrafica/${orgId}/${codCliFor}/${tipo}`)
      .toPromise();
  }

  async getFornitoriOrdiniApertiAsync(orgId: number, gruppoDoc: string, groupDocOriginale: number) {
    // eslint-disable-next-line max-len
    return await this.http.get<SearchResult<Anagrafica>>(`${environment.localApiUrl}Esolver/FornitoriOrdiniAperti/${orgId}/${gruppoDoc}/${groupDocOriginale}`)
      .toPromise();
  }

  async getRigheOrdiniApertiByFornitoreAsync(orgId: number, idAnagrafica: number, rifDoc: string, gruppoDoc: string) {
    // eslint-disable-next-line max-len
    return await this.http.post<SearchResult<RigaOrdEsolver>>(`${environment.localApiUrl}Esolver/RigheOrdiniApertiByFornitore`,
      {orgId, idAnagrafica, rifDoc, gruppoDoc})
      .toPromise();
  }

  async getTerzistiOrdiniApertiAsync(orgId: number, codLav: string) {
    // eslint-disable-next-line max-len
    return await this.http.get<SearchResult<Anagrafica>>(`${environment.localApiUrl}Esolver/TerzistiOrdiniAperti/${orgId}?codLav=${codLav}`)
      .toPromise();
  }

  async getContoLavoroApertiAsync(orgId: number) {
    // eslint-disable-next-line max-len
    return await this.http.get<SearchResult<Lavorazione>>(`${environment.localApiUrl}Esolver/ContoLavoroAperti/${orgId}`)
      .toPromise();
  }

  async getOrdiniTerzisitByRifOdPAsync(orgId: number, rifOdP: string) {
    // eslint-disable-next-line max-len
    return await this.http.post<SearchResult<OrdineEsolver>>(`${environment.localApiUrl}Esolver/OrdiniTerzistiByRifOdP/${orgId}`,
      {search: rifOdP, page: 0, count: 0})
      .toPromise();
  }

  async getOrdiniContoLavoroByRifOdP(orgId: number, rifOdP: string) {
    // eslint-disable-next-line max-len
    return await this.http.post<SearchResult<OrdineEsolver>>(`${environment.localApiUrl}Esolver/OrdiniContoLavoroByRifOdP/${orgId}`,
      {search: rifOdP, page: 0, count: 0})
      .toPromise();
  }

  async getAllegatiOrdineByRifOdPAsync(orgId: number, rifOdP: string) {
    // eslint-disable-next-line max-len
    return await this.http.post<SearchResult<Allegato>>(`${environment.localApiUrl}Esolver/Allegati/${orgId}`,
      {search: rifOdP, page: 0, count: 0})
      .toPromise();
  }

  async getAllegatoOrdineAsync(orgId: number, path: string, fileName: string) {
    // eslint-disable-next-line max-len
    return await this.http.post<any>(`${environment.localApiUrl}Esolver/Allegato/${orgId}`,
      { path: path, fileName: fileName}, { responseType: 'blob' as 'json', observe: 'response' })
      .toPromise();
  }

  padLeft(num: number, size: number) {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }
}
