import { Component, OnInit } from '@angular/core';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LocalService} from '../../_services/local.service';
import {SaveMessage} from '../../_helpers/save-message';
import {SearchResult} from "../../_models/SearchResult";
import {Allegato} from "../../_models/Allegato";
import {ModalController} from '@ionic/angular';

@Component({
  selector: 'app-ordine-allegati',
  templateUrl: './ordine-allegati.component.html',
  styleUrls: ['./ordine-allegati.component.scss'],
})
export class OrdineAllegatiComponent  implements OnInit {
  rifOdP = '';
  allegati: Allegato[] = [];
  loading = false;
  constructor(
    private modalCtrl: ModalController,
    private organizzazioneService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.getAllegati();
  }

  getAllegati = () => {
    if (!this.rifOdP)
      return false;
    this.loading = true;
    this.localService.getAllegatiOrdineByRifOdPAsync(this.organizzazioneService.organizzazioneId,
      this.rifOdP)
      .then((_allegati: SearchResult<Allegato>) => {
        if (_allegati && _allegati.totalCount > 0) {
          this.allegati = _allegati.result;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento'))
      .finally(() => this.loading = false);
    
  }
  
  openAllegato(allegato: Allegato) {
    this.loading = true;
    this.localService.getAllegatoOrdineAsync(this.organizzazioneService.organizzazioneId,
      allegato.percorso, allegato.nomeFile)
      .then(response => {
        const blob = response.body!;

        // Estrai il nome del file dall'header "Content-Disposition"
        const contentDisposition = response.headers.get('Content-Disposition');
        let fileName = 'downloaded-file.pdf'; // Default

        if (contentDisposition) {
          const match = contentDisposition.match(/filename="(.+)"/);
          if (match?.[1]) {
            fileName = match[1];
          }
        }

        // Crea un link per scaricare il file
        const fileURL = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = fileURL;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      })
      .catch((err) =>
      {
        this.saveMessage.messageError('Ci sono problemi con caricamento');
      })
      .finally(() => this.loading = false);
  }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');
}
