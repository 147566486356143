import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrdSped} from '../../../_models/OrdSped';
import {Subscription} from 'rxjs';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {MessageConfirm} from '../../../_helpers/message-confirm.service';
import {SaveMessage} from '../../../_helpers/save-message';
import {ModalController} from '@ionic/angular';
import {Ordine} from '../../../_models/Ordine';
import {OrdinispedizioniService} from '../../../_services/ordinispedizioni.service';
import {SpedizioneUpdateSendComponent} from '../spedizione-update-send/spedizione-update-send.component';

@Component({
  selector: 'app-spedizione-locale-item',
  templateUrl: './spedizione-locale-item.component.html',
  styleUrls: ['./spedizione-locale-item.component.scss'],
})
export class SpedizioneLocaleItemComponent  implements OnInit {
  @Input() spedizione: OrdSped;
  @Input() updateEnable: boolean;
  @Output()  spedizioneSended = new EventEmitter<OrdSped>();
  dataOggi = new Date().toISOString();
  subscription: Subscription;
  today = new Date().toISOString();
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private messageConfirm: MessageConfirm,
    private saveMessage: SaveMessage,
    private modalCtrl: ModalController,
    private spedizioniService: OrdinispedizioniService) { }

  ngOnInit() {
    this.subscription = this.spedizioniService.lastOrdSpedUpdated.subscribe((_spedizione: OrdSped) => {
      if (_spedizione == null) {
        return false;
      }
      if (!this.spedizione.isLoading &&
        _spedizione.numRegistraz > 0 &&
        this.spedizione.idDocumento === _spedizione.idDocumento &&
        this.spedizione.idRiga === _spedizione.idRiga) {
        this.spedizione = _spedizione;
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  deleteSpedizione = (spedizione: OrdSped) => {
    this.messageConfirm
      .presentAlert(`Confermi di eliminare spedizione ${spedizione.numRegistraz} (${spedizione.idDocumento}.${spedizione.idRiga})?`, () => {
        this.spedizioniService.deleteOrdineSpedizione(this.organizzazioniService.organizzazioneId, spedizione.idDocumento,
          spedizione.idRiga)
          .then(() => {
            this.spedizione.onQueue = true;
            this.saveMessage.messageInfo('Richiesta di eliminare spedizione inviata con successo');
          })
          .catch(() => this.saveMessage.messageError('Ci sono problemi con eliminazione di spedizione.'));
      });
  };

  selectSpedizione = (spedizione: OrdSped) => {
    if (this.updateEnable && !spedizione.isLoading) {
      this.openModal(spedizione);
    } else {
      this.spedizioneSended.emit(spedizione);
    }
  };

  async openModal(element) {
    const modal = await this.modalCtrl.create({
      component: SpedizioneUpdateSendComponent,
      backdropDismiss: false,
      componentProps: { spedizione: element, data: this.dataOggi }
    });
    await modal.present();
    const { data, role } = await modal.onWillDismiss();
    if (role === 'save' || role === 'send') {
      //this.localService.handleRefresh.next(1);
      //this.ordine.onQueue = true;
    }
  }

}
