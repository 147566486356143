// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  localApiUrl: window['env']['localApiUrl'] || 'http://localhost:4200/',
  localHubUrl: window['env']['localHubUrl'] || 'http://localhost:4500/',
  pesataHubUrl: window['env']['pesataHubUrl'] || 'http://localhost:4600/'
  //localApiUrl: 'https://api-local.archiconnect.it/',
  //localHubUrl: 'https://hub-local.archiconnect.it/',
  //pesataHubUrl: 'https://hub-pesata.archiconnect.it/'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
