export enum TracciatoSorgentes {
  ordini = 0,
  boxMaterial = 1,
  boxProduct = 2
}

export const TRACCIATO_SORGENTES = [
  { value: TracciatoSorgentes.ordini, label: 'Ordini' },
  { value: TracciatoSorgentes.boxMaterial, label: 'Contenitori Materiale' },
  { value: TracciatoSorgentes.boxProduct, label: 'Contenitori Prodotto' }
];
