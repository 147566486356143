import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-select-risorse-format',
  templateUrl: './select-risorse-format.component.html',
  styleUrls: ['./select-risorse-format.component.scss'],
})
export class SelectRisorseFormatComponent {
  @Input() formato = '';
  @Output() formatoChange = new EventEmitter<string>();
  @Input() tipoRisorsa = 0;
  formats: string[] = ['MANUALE', 'SYEK', 'MAZAK', 'STYLE', 'OPCUA', 'MODBUS', 'ZUND', 'GMM', 'DB_MSSQL', 'DB_PGSQL',
    'ETH_DINI','PRT_STD', 'PRT_ZEBRA'];

  constructor() {}
  selectFormat = (e: any) => this.formatoChange.emit(e.target.value);
}
