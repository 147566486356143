import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {AvanzamentoService} from '../../../_services/avanzamento.service';
import {Evento} from '../../../_models/Evento';
import {SaveMessage} from '../../../_helpers/save-message';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {OrdineGroup} from '../../../_models/OrdineGroup';
import {SearchResult} from '../../../_models/SearchResult';

@Component({
  selector: 'app-select-avanzamenti',
  templateUrl: './select-avanzamenti.component.html',
  styleUrls: ['./select-avanzamenti.component.scss'],
})
export class SelectAvanzamentiComponent  implements OnInit {
  @Input() ordine: OrdineGroup;
  @Input() avanzamento: Evento;
  @Output() avanzamentoChange = new EventEmitter<Evento>();
  @Input() codBox = '';
  avanzamenti: Evento[] = [];
  loading = false;
  isOpen = true;
  selectedAvanzaId = 0;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private avanzamentiService: AvanzamentoService,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.getAvanzamenti();
  }

  getAvanzamenti = () => {
    this.loading = true;
    this.avanzamentiService
      .getAvanzamentiOrdine(this.organizzazioniService.organizzazioneId,
        this.ordine.idDocumento, this.ordine.idRiga, this.ordine.idRigaLavorazione,
        this.codBox)
      .then((_avanzamenti: SearchResult<Evento>) => {
        if (_avanzamenti && _avanzamenti.totalCount > 0) {
          this.avanzamenti = _avanzamenti.result;
        }
      })
      .catch((err) => this.saveMessage.messageError('Ci sono problemi con caricamento di avanzamenti'))
      .finally(() => this.loading = false);
  };

  selectAvanzamento = (avanzamento: Evento) => {
    if (!this.isOpen) {
      this.isOpen = true;
      return false;
    }
    this.isOpen = false;
    this.selectedAvanzaId = avanzamento.id;
    this.avanzamentoChange.emit(avanzamento);
  };

  openList = () => this.isOpen = !this.isOpen;
}
