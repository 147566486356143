import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {ArticoliService} from '../../_services/articoli.service';
import {Magazzino} from '../../_models/Magazzino';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {SearchResult} from '../../_models/SearchResult';
import {SaveMessage} from '../../_helpers/save-message';
import {MagazzinoArea} from '../../_models/MagazzinoArea';

@Component({
  selector: 'app-magazzino-input',
  templateUrl: './magazzino-input.component.html',
  styleUrls: ['./magazzino-input.component.scss'],
})
export class MagazzinoInputComponent  implements OnInit {
  @Input() codMag = '';
  @Output() codMagChange = new EventEmitter<string>();
  @Input() codAreaMag = '';
  @Output() codAreaMagChange = new EventEmitter<string>();
  magazzini: Magazzino[] = [];
  aree: MagazzinoArea[] = [];
  showMagazzini = false;
  showAree = false;
  loading = false;
  constructor(
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage,
    private articoliService: ArticoliService) { }

  ngOnInit() {}

  getMagazzini = () => {
    this.showMagazzini = !this.showMagazzini;
    if (!this.showMagazzini) {
      this.magazzini = [];
      return false;
    }
    this.loading = true;
    this.articoliService.getMagazzini(this.organizzazioniService.organizzazioneId, 0)
      .then((_magazzini: SearchResult<Magazzino>) => {
        if (_magazzini && _magazzini.totalCount > 0) {
          this.magazzini = _magazzini.result;
          if (this.magazzini.filter(m => m.codMag === this.codMag).length > 0) {
            this.codMagChange.emit(this.codMag);
          }
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di magazzini'))
      .finally(() => this.loading = false);
  };

  selectMagazzino = (magazzino: Magazzino) => {
    if (!magazzino || !magazzino.codMag) {
      return false;
    }
    this.showMagazzini = false;
    this.magazzini = [];
    this.codMagChange.emit(magazzino.codMag);
  };

  getAree = (codMag: string) => {
    this.showAree = !this.showAree;
    if (!this.showAree) {
      this.aree = [];
      return false;
    }
    this.loading = true;
    this.articoliService.getAreeOfMagazzino(this.organizzazioniService.organizzazioneId, codMag)
      .then((_aree: SearchResult<MagazzinoArea>) => {
        if (_aree && _aree.totalCount > 0) {
          this.aree = _aree.result;
          if (this.aree.filter(a => a.codAreaMag === this.codAreaMag).length > 0) { 
            this.codAreaMagChange.emit(this.codAreaMag);
          }
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di aree'))
      .finally(() => this.loading = false);
  };

  selectArea = (area: MagazzinoArea) => {
    if (!area || !area.codAreaMag) {
      return false;
    }
    this.showAree = false;
    this.aree = [];
    this.codAreaMagChange.emit(area.codAreaMag);
  };
}
