export enum FormatoBox {
  nonDefinito = 0,
  gabbia = 1,
  cassetta = 2,
  bancale = 3,
  cartone = 4
}

export const FORMATO_BOX = [
  { value: FormatoBox.nonDefinito, label: 'Non definito' },
  { value: FormatoBox.gabbia, label: 'Gabbia' },
  { value: FormatoBox.cassetta, label: 'Cassetta' },
  { value: FormatoBox.bancale, label: 'Bancale' },
  { value: FormatoBox.cartone, label: 'Cartone' }
];
