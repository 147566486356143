import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {QualitaService} from '../../../_services/qualita.service';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SearchResult} from '../../../_models/SearchResult';
import {CqCicloControllo} from '../../../_models/CqCicloControllo';
import {ControlloQualita} from '../../../_models/ControlloQualita';
import {SaveMessage} from '../../../_helpers/save-message';
import {CqSchede} from '../../../_models/CqSchede';
import {CqCaratteristica} from '../../../_models/CqCaratteristica';
import {NcScheda} from '../../../_models/NcScheda';
import {OrdineGroup} from '../../../_models/OrdineGroup';
import {AuthenticationService} from '../../../_services/authentication.service';
import {NonconformeService} from '../../../_services/nonconforme.service';

@Component({
  selector: 'app-check-controlli-list',
  templateUrl: './check-controlli-list.component.html',
  styleUrls: ['./check-controlli-list.component.scss'],
})
export class CheckControlliListComponent  implements OnInit {
  @Input() ordine: OrdineGroup;
  @Input() rifOdP = '';
  @Input() lotto = '';
  @Input() tipoControllo = 0;
  @Input() codArtOrdine = '';
  @Input() codArt = '';
  @Input() numFase = 0;
  @Input() quantitaOdP = 1;
  @Input() quantitaCq = 0;
  @Output() foundNc = new EventEmitter<NcScheda>();
  schede: CqSchede[] = [];
  controlli: CqCicloControllo[] = [];
  currControllo: CqCicloControllo;
  currIndex = 0;
  currEsito = 0;
  statoControlli = 1;
  caratteristiche: CqCaratteristica[] = [];
  annotazione = '';
  statoNc = false;
  currSchedaNc: NcScheda = {
    organizzazioneId: 0, idSchedaNC: 0, idTipoSchedaNC: 0, dataUnixRegistrazione: 0,
    codArt: '', statoScheda: 0, tipoNC: 1, tipoProvenienza:3, qtaNonConforme: 0
  };
  qtaNc = 0;
  loading = false;
  constructor(
    private authService: AuthenticationService,
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private nonconformeService: NonconformeService,
    private qualitaService: QualitaService) { }

  ngOnInit() {
    this.getSchede();
  }

  getSchede() {
    this.schede = [];
    if (this.rifOdP == null || this.numFase == null) {
      return false;
    }
    this.loading = true;
    const fase = this.numFase > 0 ? this.numFase : 0;
    this.qualitaService.getSchedeOfOdP(this.organizzazioniService.organizzazioneId,
      this.rifOdP.substring(0,13), this.numFase)
      .then((_schede: SearchResult<CqSchede>) => {
        if (_schede.totalCount > 0) {
          this.schede = _schede.result;
          this.getControlli();
        }
        this.statoControlli = _schede.totalCount > 0 ? 1 : 2;
        this.qualitaService.statoControlli.next(this.statoControlli);
      })
      .finally(() => this.loading = false);
  }

  getControlli() {
    this.controlli = [];
    this.currControllo = null;
    this.currIndex = 0;
    if (this.codArtOrdine == null || this.numFase == null) {
      return false;
    }
    this.loading = true;
    const fase = this.numFase > 0 ? this.numFase : 0;
    this.qualitaService.getControlliOfCodArt(this.organizzazioniService.organizzazioneId,
      this.codArtOrdine, fase)
      .then((_controlli: SearchResult<CqCicloControllo>)  => {
        if (_controlli.totalCount > 0) {
          const controlli = _controlli.result.filter((_controllo: CqCicloControllo) => {
            return (this.tipoControllo === 0 ||
              (this.tipoControllo === 1 && _controllo.richiediAdAvvioProd) ||
              (this.tipoControllo === 2 && _controllo.richiediDuranteProd) ||
              (this.tipoControllo === 3 && _controllo.richiediAllaFineProd));
          });
          controlli.forEach((controllo: CqCicloControllo) => {
            const numControlli = controllo.controlloTotale ?  this.quantitaOdP : controllo.dimensioneCampione;
            controllo.numCampione = 1;
            controllo.totCampione = numControlli;
            this.controlli.push({...controllo});
            for (let i = 1; i < numControlli; i++) {
              controllo.numCampione = i + 1;
              this.controlli.push({...controllo});
            }
          });
          //this.controlli = controlli;
          if (this.controlli.length > 0) {
            this.nextControllo();
          }
        }
        this.statoControlli = this.controlli.length > 0 ? 1 : 2;
        this.qualitaService.statoControlli.next(this.statoControlli);
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di controlli'))
      .finally(() => this.loading = false);
  }

  nextControllo = () => {
    this.annotazione = '';
    this.currEsito = 0;
    this.currControllo = this.controlli[this.currIndex];
    this.getCaratteristiche();
  };

  checkControllo = () => {
    this.currEsito = 2;
    if (this.currControllo == null || this.currControllo.valoreInserito == null) {
      return false;
    }
    if (this.currControllo.valoreInserito >= (this.currControllo.valoreNominale + this.currControllo.tolleranzaInferiore) &&
    this.currControllo.valoreInserito <= (this.currControllo.valoreNominale + this.currControllo.tolleranzaSuperiore)) {
      this.currEsito = 1;
    }
  };

  setControllo = (esito?: number, idValore?: number) => {
    if (esito) {
      this.currEsito = esito;
    }
    if (!this.statoNc) {
      this.createControllo(esito, idValore);
    }
    if (this.currEsito === 2) {
      this.currSchedaNc.qtaNonConforme = this.quantitaCq;
      this.statoNc = true;
      return false;
    }
    this.statoNc = false;
    if ((this.controlli.length - 1) > this.currIndex) {
      this.currIndex += 1;
      this.nextControllo();
    } else {
      this.statoControlli = 2;
      this.qualitaService.statoControlli.next(this.statoControlli);
    }
  };

  getCaratteristiche = () => {
    this.caratteristiche = [];
    this.qualitaService.getCaratteristicheById(this.organizzazioniService.organizzazioneId,
      this.currControllo.idCaratteristica)
      .then((_carat: SearchResult<CqCaratteristica>) => {
        if (_carat.totalCount > 0) {
          this.caratteristiche = _carat.result;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di caratterisiche'));
  };

  createControllo = (esito?: number, idValore?: number) => {
    if (this.currControllo.tipoCaratteristica === 1 && esito == null) {
      esito = ((this.currControllo.valoreInserito > (this.currControllo.valoreNominale + this.currControllo.tolleranzaInferiore)) &&
        (this.currControllo.valoreInserito > (this.currControllo.valoreNominale + this.currControllo.tolleranzaInferiore))) ? 1 : 2;
    }
    const idDoc = this.qualitaService.getIdOrdine(this.rifOdP);
    const dataEvento = parseInt((new Date().getTime()/1000).toString(), 10);
    const newControllo: ControlloQualita = {
      organizzazioneId: this.organizzazioniService.organizzazioneId,
      idSchedaControllo: this.schede.length > 0 ? this.schede[0].idSchedaControllo : 0,
      idRigaCicloControllo: this.currControllo.idRigaCicloControllo,
      controlloId: 0,
      dataUnixRegistrazione: dataEvento,
      codArt: this.currControllo.codArt,
      unitaMisura: this.currControllo.unitaMisura,
      idCaratteristica: this.currControllo.idCaratteristica,
      numCampione: this.currControllo.numCampione,
      numProgrCaratt: this.currControllo.numProgrCaratt,
      valoreRiscontrato: this.currControllo.valoreInserito,
      esitoControllo: this.currControllo.tipoCaratteristica > 1 ? esito : 0,
      idValoreAmmesso: this.currControllo.tipoCaratteristica > 1 ? idValore : 0,
      esitoForzato: this.currControllo.tipoCaratteristica > 1 ? 1 : 0,
      desRigaSchedaContr: this.currControllo.descrFaseCicloContr,
      annotazione: this.annotazione,
      risorsaControllo: this.currControllo.codRisorsaStrumMis ?? '',
      idDocumento: idDoc.idDocumento,
      idRiga: idDoc.idRiga
    };
    //this.qualitaService.createControlloAsync(newControllo)
    this.qualitaService.sendControlloAsync(this.organizzazioniService.organizzazioneId,
      dataEvento, this.ordine, newControllo, this.authService.currentUserValue.email)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con salvataggio di controllo'));
  };

  createSchedaNc = () => {
    this.currEsito = 0;
    this.loading = true;
    const dataEvento = parseInt((new Date().getTime()/1000).toString(), 10);
    const idDoc = this.qualitaService.getIdOrdine(this.rifOdP);
    this.currSchedaNc.dataUnixRegistrazione = parseInt((new Date().getTime()/1000).toString(), 10);
    this.currSchedaNc.codArt = this.codArt; //this.tipoControllo === 1 ? this.codMateriale : this.currControllo.codArt;
    this.currSchedaNc.organizzazioneId = this.organizzazioniService.organizzazioneId;
    this.currSchedaNc.idSchedaControllo = this.schede.length > 0 ? this.schede[0].idSchedaControllo : 0;
    this.currSchedaNc.idRigaCicloControllo = this.currControllo.idRigaCicloControllo;
    this.currSchedaNc.idDocumento = idDoc.idDocumento;
    this.currSchedaNc.idRiga = idDoc.idRiga;
    this.currSchedaNc.rifLottoAlfanum = this.lotto;
    //this.nonconformeService.createNcSchedaAsync(this.currSchedaNc)
    this.nonconformeService
      .sendNcSchedaAsync(this.organizzazioniService.organizzazioneId, dataEvento, this.ordine,
        this.currSchedaNc, this.authService.currentUserValue.email)
      .then(() => {
        this.setControllo();
        this.foundNc.emit(this.currSchedaNc);
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con creazione Scheda NC'))
      .finally(() => this.loading = false);
  };
}
