import {Detail} from './Detail';
import {TracciatoFieldTypesEnum} from '../_helpers/TracciatoFieldTypes';
import {TracciatoFieldKeyTypes} from '../_helpers/TracciatoFieldKeyTypes';

export class TracciatoField extends Detail {
  tracciatoId: number;
  fieldId: number;
  columnName: string;
  fieldType: TracciatoFieldTypesEnum;
  fieldName: string;
  sortIndex: number;
  fieldFormat?: string;
  defaultValue?: string;
  fieldAddress?: string;
  fieldLength: number;
  faseControllo?: number;
  idCaratteristica?: number;
  idTipoSchedaNc?: number;
  idCodNC?: number;
  keyType: TracciatoFieldKeyTypes;
}
