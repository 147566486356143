import {Component, OnInit} from '@angular/core';
import {TracciatiService} from '../../../_services/tracciato.service';
import {TracciatoField} from '../../../_models/TracciatoField';
import {ModalController} from '@ionic/angular';
import {SaveMessage} from '../../../_helpers/save-message';
import {TracciatoFieldTypesEnum} from '../../../_helpers/TracciatoFieldTypes';
import {TracciatoFieldsEnum} from '../../../_helpers/TracciatoFields';
import {TracciatoFieldKeyTypes} from '../../../_helpers/TracciatoFieldKeyTypes';

const emptyField: TracciatoField = {
  tracciatoId: 0,
  fieldId: 0,
  fieldType: TracciatoFieldTypesEnum.standard,
  columnName: '',
  fieldName: '',
  sortIndex: 1,
  fieldLength: 0,
  keyType: TracciatoFieldKeyTypes.none
};

@Component({
  selector: 'app-tracciati-field-create',
  templateUrl: './tracciati-field-create.component.html',
  styleUrls: ['./tracciati-field-create.component.scss'],
})
export class TracciatiFieldCreateComponent implements OnInit {
  isNew = true;
  tracciatoId: number;
  tracciatoField;
  loading = false;
  tracciatoFieldsEnum = TracciatoFieldsEnum;
  constructor(
    private tracciatoService: TracciatiService,
    private modalCtrl: ModalController,
    private messageSave: SaveMessage) { }

  ngOnInit() {
    if (this.tracciatoField == null) {
      this.tracciatoField = emptyField;
    }
    this.tracciatoField.tracciatoId = this.tracciatoId;
  }

  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  confirm() {
    this.loading = true;
    if (this.isNew) {
      this.tracciatoService.createTracciatoFieldAsync(this.tracciatoField)
        .then(this.succesSave)
        .catch(this.errorSave)
        .finally(() => this.loading = false);
    } else {
      this.tracciatoService.updateTracciatoFieldAsync(this.tracciatoField)
        .then(this.succesSave)
        .catch(this.errorSave)
        .finally(() => this.loading = false);
    }
  }

  succesSave = (_field: TracciatoField) => {
    this.messageSave.messageInfo('Operazione eseguita con successo');
    this.modalCtrl.dismiss(_field, 'confirm');
  };

  errorSave = () => this.messageSave.messageError('Campo non valido');
}
