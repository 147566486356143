import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'; // <-- NgModel lives here
import {NgxBarcode6Module} from 'ngx-barcode6';
import {IonicModule} from '@ionic/angular';
import {SearchComponentComponent} from './_components/search-component/search-component.component';
import {OrdineStatoLabelComponent} from './_components/ordine-stato-label/ordine-stato-label.component';
import {InstallazioneTypeLabelComponent} from './_components/installazione-type-label/installazione-type-label.component';
import {TracciatiTypeFieldLabelComponent} from './_components/tracciati/tracciati-type-field-label/tracciati-type-field-label.component';
import {TipoEventoLabelComponent} from './_components/tipo-evento-label/tipo-evento-label.component';
import {RisorsaTipoLabelComponent} from './_components/risorsa-tipo-label/risorsa-tipo-label.component';
import {OrdineEsolverItemComponent} from './_components/ordine-esolver-item/ordine-esolver-item.component';
import {OrdineLocalItemComponent} from './_components/ordine-local-item/ordine-local-item.component';
import {OrdiniFilterRowComponent} from './_components/ordini-filter-row/ordini-filter-row.component';
import {OrdineEsolverLaunchComponent} from './_components/ordine-esolver-launch/ordine-esolver-launch.component';
import {OrdineLocalCreateComponent} from './_components/ordine-local-create/ordine-local-create.component';
import {OrdineInfoComponent} from './_components/ordine-info/ordine-info.component';
import {OrdineSessioniComponent} from './_components/ordine-sessioni/ordine-sessioni.component';
import {OrdiniUpdateSendComponent} from './_components/ordini-update-send/ordini-update-send.component';
import {OrdiniEsolverComponent} from './_components/ordini-esolver/ordini-esolver.component';
import {OrdiniLocaliComponent} from './_components/ordini-locali/ordini-locali.component';
import {OrdiniLocaliGroupComponent} from './_components/ordini-locali-group/ordini-locali-group.component';
import {OrdiniLocaliGroupbymanyComponent} from './_components/ordini-locali-groupbymany/ordini-locali-groupbymany.component';
import {OrdiniViewmodeSwitchComponent} from './_components/ordini-viewmode-switch/ordini-viewmode-switch.component';
import {OrdineLocalGroupItemComponent} from './_components/ordine-local-group-item/ordine-local-group-item.component';
import {OrdineLocalGroupbymanyItemComponent} from './_components/ordine-local-groupbymany-item/ordine-local-groupbymany-item.component';
import {OrdiniEsolverGroupbymanyComponent} from './_components/ordini-esolver-groupbymany/ordini-esolver-groupbymany.component';
import {OrdineEsolverGroupbymanyItemComponent} from './_components/ordine-esolver-groupbymany-item/ordine-esolver-groupbymany-item.component';
import {OrdineLocalHeaderComponent} from './_components/ordine-local-header/ordine-local-header.component';
import {OrdiniLocaliListComponent} from './_components/ordini-locali-list/ordini-locali-list.component';
import {OrdiniGroupColumnsComponent} from './_components/ordini-group-columns/ordini-group-columns.component';
import {OrdiniShowColumnsComponent} from './_components/ordini-show-columns/ordini-show-columns.component';
import {OrdiniGroupUpdateSendComponent} from './_components/ordini-group-update-send/ordini-group-update-send.component';
import {TracciatiFieldCreateComponent} from './_components/tracciati/tracciati-field-create/tracciati-field-create.component';
import {TracciatiFieldListComponent} from './_components/tracciati/tracciati-field-list/tracciati-field-list.component';
import {TracciatoFieldStatoComponent} from './_components/tracciati/tracciato-field-stato/tracciato-field-stato.component';
import {HubStateLedComponent} from './_components/hub-state-led/hub-state-led.component';
import {CentroNotificheComponent} from './_components/centro-notifiche/centro-notifiche.component';
import {CentroNotifichePopupComponent} from './_components/centro-notifiche-popup/centro-notifiche-popup.component';
import {OrdiniStatisticsComponent} from './_components/ordini-statistics/ordini-statistics.component';
import {BarChartComponent} from './_components/charts/bar-chart/bar-chart.component';
import {PieChartComponent} from './_components/charts/pie-chart/pie-chart.component';
import {TruncatePipe} from './_helpers/TruncatePipe';
import {OrderByPipe} from './_helpers/order-by.pipe';
import {FloorPipe} from './_helpers/FloorPipe';
import {TimeStringPipe} from './_helpers/TimeStringPipe';
import {KeyboardComponent} from './_components/keyboard/keyboard.component';
import {LicenzeConfigComponent} from './_components/licenze/licenze-config/licenze-config.component';
import {LicenzeConfigItemComponent} from './_components/licenze/licenze-config-item/licenze-config-item.component';
import {OrdiniFilterRowCodiceUnivocoComponent} from './_components/ordini-filter-row-codice-univoco/ordini-filter-row-codice-univoco.component';
import {EventiChartComponent} from './_components/charts/eventi-chart/eventi-chart.component';
import {OrdineEsolverGroupItemComponent} from './_components/ordine-esolver-group-item/ordine-esolver-group-item.component';
import {OrdineEsolverGroupLaunchComponent} from './_components/ordine-esolver-group-launch/ordine-esolver-group-launch.component';
import {UserHeaderComponent} from './_components/user-header/user-header.component';
import {UserInfoComponent} from './_components/user-info/user-info.component';
import {FiltroOrdineComponent} from './_components/filtro-ordine/filtro-ordine.component';
import {MaterialeListComponent} from './_components/materiale-list/materiale-list.component';
import {MovimentoAddComponent} from './_components/movimenti/movimento-add/movimento-add.component';
import {MovimentoTrasferimentoComponent} from './_components/movimenti/movimento-trasferimento/movimento-trasferimento.component';
import {LottoInputComponent} from './_components/lotto-input/lotto-input.component';
import {SchedeQualitaListComponent} from './_components/qualita/schede-qualita-list/schede-qualita-list.component';
import {ControlloQualitaItemComponent} from './_components/qualita/controllo-qualita-item/controllo-qualita-item.component';
import {CheckControlliListComponent} from './_components/qualita/check-controlli-list/check-controlli-list.component';
import {ControlliQualitaOrdineComponent} from './_components/qualita/controlli-qualita-ordine/controlli-qualita-ordine.component';
import {RouterModule} from '@angular/router';
import {BilancaInputComponent} from './_components/bilanca-input/bilanca-input.component';
import {QuantitaInputComponent} from './_components/quantita-input/quantita-input.component';
import {BoxInputComponent} from './_components/box-input/box-input.component';
import {MagazzinoInputComponent} from './_components/magazzino-input/magazzino-input.component';
import {AnagraficaInputComponent} from './_components/anagrafica-input/anagrafica-input.component';
import {AnagraficaIndirizzoInputComponent} from './_components/anagrafica-indirizzo-input/anagrafica-indirizzo-input.component';
import {ArticoloSchedeTecnicheComponent} from './_components/articolo-schede-tecniche/articolo-schede-tecniche.component';
import {SpedizioniLocaliListComponent} from './_components/spedizioni/spedizioni-locali-list/spedizioni-locali-list.component';
import {SpedizioniEsolverListComponent} from './_components/spedizioni/spedizioni-esolver-list/spedizioni-esolver-list.component';
import {SpedizioneLocaleItemComponent} from './_components/spedizioni/spedizione-locale-item/spedizione-locale-item.component';
import {SpedizioneEsolverItemComponent} from './_components/spedizioni/spedizione-esolver-item/spedizione-esolver-item.component';
import {SpedizioneUpdateSendComponent} from './_components/spedizioni/spedizione-update-send/spedizione-update-send.component';
import {SpedizioneEsolverLaunchComponent} from './_components/spedizioni/spedizione-esolver-launch/spedizione-esolver-launch.component';
import {SpedizioneStatoLabelComponent} from './_components/spedizioni/spedizione-stato-label/spedizione-stato-label.component';
import { OrdineAllegatiComponent } from './_components/ordine-allegati/ordine-allegati.component';
import {SelectModule} from './select.module';

@NgModule({
  declarations: [
    SearchComponentComponent,
    OrdineStatoLabelComponent,
    InstallazioneTypeLabelComponent,
    TracciatiTypeFieldLabelComponent,
    TipoEventoLabelComponent,
    RisorsaTipoLabelComponent,
    OrdineEsolverItemComponent,
    OrdineEsolverGroupItemComponent,
    OrdineEsolverGroupLaunchComponent,
    OrdineLocalItemComponent,
    OrdiniFilterRowComponent,
    OrdineEsolverLaunchComponent,
    OrdineLocalCreateComponent,
    OrdineInfoComponent,
    OrdineSessioniComponent,
    OrdiniUpdateSendComponent,
    OrdiniEsolverComponent,
    OrdiniLocaliComponent,
    OrdiniLocaliGroupComponent,
    OrdiniLocaliGroupbymanyComponent,
    OrdiniViewmodeSwitchComponent,
    OrdineLocalGroupItemComponent,
    OrdineLocalGroupbymanyItemComponent,
    OrdiniEsolverGroupbymanyComponent,
    OrdineEsolverGroupbymanyItemComponent,
    OrdineLocalHeaderComponent,
    OrdiniLocaliListComponent,
    OrdiniGroupColumnsComponent,
    OrdiniShowColumnsComponent,
    OrdiniGroupUpdateSendComponent,
    TracciatiFieldCreateComponent,
    TracciatiFieldListComponent,
    TracciatoFieldStatoComponent,
    HubStateLedComponent,
    CentroNotificheComponent,
    CentroNotifichePopupComponent,
    OrdiniStatisticsComponent,
    BarChartComponent,
    PieChartComponent,
    EventiChartComponent,
    KeyboardComponent,
    OrderByPipe,
    TruncatePipe,
    FloorPipe,
    TimeStringPipe,
    LicenzeConfigComponent,
    LicenzeConfigItemComponent,
    OrdiniFilterRowCodiceUnivocoComponent,
    UserHeaderComponent,
    UserInfoComponent,
    FiltroOrdineComponent,
    MaterialeListComponent,
    MovimentoAddComponent,
    MovimentoTrasferimentoComponent,
    SchedeQualitaListComponent,
    ControlloQualitaItemComponent,
    CheckControlliListComponent,
    ControlliQualitaOrdineComponent,
    LottoInputComponent,
    BilancaInputComponent,
    QuantitaInputComponent,
    BoxInputComponent,
    MagazzinoInputComponent,
    AnagraficaInputComponent,
    AnagraficaIndirizzoInputComponent,
    ArticoloSchedeTecnicheComponent,
    SpedizioniLocaliListComponent,
    SpedizioniEsolverListComponent,
    SpedizioneLocaleItemComponent,
    SpedizioneEsolverItemComponent,
    SpedizioneUpdateSendComponent,
    SpedizioneEsolverLaunchComponent,
    SpedizioneStatoLabelComponent,
    OrdineAllegatiComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule.forRoot(),
    SelectModule
  ],
  exports: [
    FormsModule,
    NgxBarcode6Module,
    SearchComponentComponent,
    OrdineStatoLabelComponent,
    InstallazioneTypeLabelComponent,
    TracciatiTypeFieldLabelComponent,
    TipoEventoLabelComponent,
    RisorsaTipoLabelComponent,
    OrdineEsolverItemComponent,
    OrdineEsolverGroupItemComponent,
    OrdineEsolverGroupLaunchComponent,
    OrdineLocalItemComponent,
    OrdiniFilterRowComponent,
    OrdineEsolverLaunchComponent,
    OrdineLocalCreateComponent,
    OrdineInfoComponent,
    OrdineSessioniComponent,
    OrdiniUpdateSendComponent,
    OrdiniEsolverComponent,
    OrdiniLocaliComponent,
    OrdiniLocaliGroupComponent,
    OrdiniLocaliGroupbymanyComponent,
    OrdiniViewmodeSwitchComponent,
    OrdineLocalGroupItemComponent,
    OrdineLocalGroupbymanyItemComponent,
    OrdiniEsolverGroupbymanyComponent,
    OrdineEsolverGroupbymanyItemComponent,
    OrdineLocalHeaderComponent,
    OrdiniLocaliListComponent,
    OrdiniGroupColumnsComponent,
    OrdiniShowColumnsComponent,
    OrdiniGroupUpdateSendComponent,
    TracciatiFieldCreateComponent,
    TracciatiFieldListComponent,
    TracciatoFieldStatoComponent,
    HubStateLedComponent,
    CentroNotificheComponent,
    CentroNotifichePopupComponent,
    OrdiniStatisticsComponent,
    BarChartComponent,
    KeyboardComponent,
    EventiChartComponent,
    OrderByPipe,
    TruncatePipe,
    FloorPipe,
    TimeStringPipe,
    LicenzeConfigComponent,
    LicenzeConfigItemComponent,
    OrdiniFilterRowCodiceUnivocoComponent,
    UserHeaderComponent,
    UserInfoComponent,
    FiltroOrdineComponent,
    MaterialeListComponent,
    MovimentoAddComponent,
    MovimentoTrasferimentoComponent,
    LottoInputComponent,
    SchedeQualitaListComponent,
    ControlloQualitaItemComponent,
    CheckControlliListComponent,
    ControlliQualitaOrdineComponent,
    BilancaInputComponent,
    QuantitaInputComponent,
    BoxInputComponent,
    MagazzinoInputComponent,
    AnagraficaInputComponent,
    AnagraficaIndirizzoInputComponent,
    ArticoloSchedeTecnicheComponent,
    SpedizioniLocaliListComponent,
    SpedizioniEsolverListComponent,
    SpedizioneLocaleItemComponent,
    SpedizioneEsolverItemComponent,
    SpedizioneUpdateSendComponent,
    SpedizioneEsolverLaunchComponent,
    SpedizioneStatoLabelComponent,
    OrdineAllegatiComponent,
    IonicModule
  ],
  providers: []
})
export class SharedModule {
}
