import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-spedizione-stato-label',
  templateUrl: './spedizione-stato-label.component.html',
  styleUrls: ['./spedizione-stato-label.component.scss'],
})
export class SpedizioneStatoLabelComponent {
  @Input() spedizioneStato: number;
}
