import {Component, Input, Output, EventEmitter} from '@angular/core';
import {TRACCIATO_SORGENTES, TracciatoSorgentes} from '../../../_helpers/TracciatoSorgentes';

@Component({
  selector: 'app-select-tracciati-sorgente',
  templateUrl: './select-tracciati-sorgente.component.html',
  styleUrls: ['./select-tracciati-sorgente.component.scss'],
})
export class SelectTracciatiSorgenteComponent {
  @Input() sorgente: TracciatoSorgentes;
  @Output() sorgenteChange = new EventEmitter<TracciatoSorgentes>();
  tracciatoSorgenteItem = TRACCIATO_SORGENTES;
  selectSorgente = (e: any) => this.sorgenteChange.emit(e.target.value);
}
