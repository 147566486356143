import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { OrganizzazioniService} from '../../../_services/organizzazioni.service';
import { RisorseService } from '../../../_services/risorse.service';
import {Risorsa} from '../../../_models/Risorsa';
import {SearchResult} from '../../../_models/SearchResult';

@Component({
  selector: 'app-select-risorsa',
  templateUrl: './select-risorsa.component.html',
  styleUrls: ['./select-risorsa.component.scss'],
})
export class SelectRisorsaComponent implements OnInit {
  @Input() byCode = false;
  @Input() isString = false;
  @Input() risorsaValue;
  @Input() all = false;
  @Input() tipoRisorsa = -1;
  @Output() risorsaValueChange = new EventEmitter<string>();
  risorses = [];
  loading = false;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    public risorseService: RisorseService) {
  }

  ngOnInit() {
    this.getRisorse();
  }

  selectRisorsa = (e: any) => this.risorsaValueChange.emit(this.isString ? e.target.value.toString() : e.target.value);
  getRisorse = () => this.organizzazioniService.risorsePersonal && !this.all ? this.getMyRisorse() : this.getRisorseLocalAsync();

  getMyRisorse() {
    this.setRisorse(this.risorseService.myRisorse.value);
  }

  getRisorseLocalAsync() {
    this.loading = true;
    this.risorses = [];
    this.risorseService
      .getRisorseFilteredAsync(this.organizzazioniService.organizzazioneId, '', 0, 0, 'codice', true, this.tipoRisorsa, '')
      .then((_risorse: SearchResult<Risorsa>) => this.setRisorse(_risorse.totalCount > 0 ? _risorse.result : []))
      .catch((_err) => console.log(_err))
      .finally(() => this.loading = false);
  }

  setRisorse = (_result: Risorsa[]) => {
    if (_result.length > 0) {
      _result.forEach((_risorsa: Risorsa) => {
        if (this.tipoRisorsa < 0 || this.tipoRisorsa === _risorsa.tipoRisorsa || _risorsa.tipoRisorsa === 0) {
          this.risorses
            .push({
              value: this.byCode ? _risorsa.codice : _risorsa.id,
              label: `${_risorsa.codice}: ${_risorsa.descrizione}`
            });
        }
      });
    }
  };
}
