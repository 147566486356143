import {Component, Input} from '@angular/core';
import {CqSchede} from '../../../_models/CqSchede';

@Component({
  selector: 'app-schede-qualita-list',
  templateUrl: './schede-qualita-list.component.html',
  styleUrls: ['./schede-qualita-list.component.scss'],
})
export class SchedeQualitaListComponent  {
  @Input() schede: CqSchede[];
  viewType = 'grid';
  constructor() { }

  setViewType = () =>
    this.viewType = this.viewType === 'list' ? 'grid' : 'list';
}
