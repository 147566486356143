import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrdSpedEsolver } from '../../../_models/OrdSpedEsolver';
import { SpedizioneEsolverLaunchComponent } from '../spedizione-esolver-launch/spedizione-esolver-launch.component';
import {OrdinispedizioniService} from '../../../_services/ordinispedizioni.service';

@Component({
  selector: 'app-spedizione-esolver-item',
  templateUrl: './spedizione-esolver-item.component.html',
  styleUrls: ['./spedizione-esolver-item.component.scss'],
})
export class SpedizioneEsolverItemComponent {
  @Input() spedizione: OrdSpedEsolver;
  @Input() activeLaunch = true;
  @Output() spedizioneSended = new EventEmitter<OrdSpedEsolver>();
  loading = false;
  today = new Date().toISOString();
  constructor(
    private spedizioniService: OrdinispedizioniService,
    private modalCtrl: ModalController) { }

  async handleClick() {
    if (!this.activeLaunch) {
      this.spedizioneSended.emit(this.spedizione);
      return false;
    }
    this.loading = true;
    const modal = await this.modalCtrl.create({
      component: SpedizioneEsolverLaunchComponent,
      backdropDismiss: false,
      componentProps: { spedizione: this.spedizione }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.loading = false;
    if (data) {
      this.spedizioniService.lastOrdSpedLaunched.next(data);
    }
  }
}
