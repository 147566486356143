import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OrganizzazioniService } from './organizzazioni.service';
import {OrdSped} from '../_models/OrdSped';
import {OrdSpedEsolver} from '../_models/OrdSpedEsolver';
import {BehaviorSubject, Subject} from 'rxjs';
import {OrdSpedGroup} from '../_models/OrdSpedGroup';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {SaveMessage} from '../_helpers/save-message';
import {FilterItem} from '../_models/FilterItem';
import {SearchResult} from '../_models/SearchResult';
import {OrdSpedBox} from '../_models/OrdSpedBox';
import * as signalR from "@microsoft/signalr";

const cachePrefix = 'archiconnect-v2-';
const emptyOrdSped: OrdSped = {
  organizzazioneId: 0,
  idDocumento: 0,
  idRiga: 0,
  periodoRifNumeraz: 0,
  numRegistraz: 0,
  dataRegistraz: new Date(),
  dataDoc: new Date(),
  numDocumento: '',
  codCliFor: 0,
  codCausaleTrasporto: '',
  desCausaleTrasporto: '',
  codPorto: '',
  desPorto: '',
  aspettoBeni: '',
  desAspettoBeni: '',
  codTrasportoAmezzo: '',
  desTrasportoAmezzo: '',
  spedNumRifIndir: 0,
  spedRagSoc1: '',
  spedRagSoc2: '',
  spedIndir1: '',
  spedIndir2: '',
  spedCap: '',
  spedLocalita1: '',
  spedLocalita2: '',
  spedProv: '',
  umPeso: '',
  totPesoLordo: 0,
  totPesoNetto: 0,
  umVolume: '',
  totVolume: 0,
  totaleColli: 0,
  spedIdAnagGen: 0,
  statoSpedizione: 0,
  codMagPrelievo: '',
  codAreaMagPrelievo: '',
  codMagTerzista: '',
  codAreaMagTerzista: '',
  codMagSped: '',
  spedCodCliFor: 0,
  codArt: '',
  varianteArt: '',
  desArt: '',
  rigaSaldata: false,
  codMag: '',
  codAreaMag: '',
  riferimentoRiga: '',
  lottoMagTipoGestione: 0,
  dataConsegnaConferm: new Date(),
  unitaMisura: '',
  qtaDaSpedire: 0,
  um2: '',
  qta2DaSpedire: 0,
  qtaPrelevata: 0,
  prelievoConfermato: false,
  numColliDaSpedire: 0,
  numColliPrelevati: 0,
  numUnitaPerCollo: 0,
  codMagazzSpedizione: '',
  codAreaMagSpedizione: '',
  qtaUmMagDaSpedire: 0,
  qtaUmMagPrelevata: 0,
  umMag: '',
  forzaSaldoRigaOrdine: false
};
const emptyOrdSpedEsolver: OrdSpedEsolver = {
  dbgruppo: '',
  idDocumento: 0,
  idRiga: 0,
  codClasseDoc: 0,
  periodoRifNumeraz: 0,
  codSerie: 0,
  numRegistraz: 0,
  dataRegistraz: new Date(),
  dataDoc: new Date(),
  numDocumento: '',
  esercizioContabile: 0,
  gruppoDoc: '',
  codCliFor: 0,
  codCausaleTrasporto: '',
  desCausaleTrasporto: '',
  codPorto: '',
  desPorto: '',
  aspettoBeni: '',
  desAspettoBeni: '',
  codTrasportoAmezzo: '',
  desTrasportoAmezzo: '',
  spedNumRifIndir: 0,
  spedRagSoc1: '',
  spedRagSoc2: '',
  spedIndir1: '',
  spedIndir2: '',
  spedCap: '',
  spedLocalita1: '',
  spedLocalita2: '',
  spedProv: '',
  umPeso: '',
  totPesoLordo: 0,
  totPesoNetto: 0,
  umVolume: '',
  totVolume: 0,
  totaleColli: 0,
  spedIdAnagGen: 0,
  statoSpedizione: 0,
  codMagPrelievo: '',
  codAreaMagPrelievo: '',
  codMagTerzista: '',
  codAreaMagTerzista: '',
  codMagSped: '',
  spedCodCliFor: 0,
  codArt: '',
  varianteArt: '',
  desArt: '',
  rigaSaldata: false,
  codMag: '',
  codAreaMag: '',
  riferimentoRiga: '',
  lottoMagTipoGestione: 0,
  dataConsegnaConferm: new Date(),
  unitaMisura: '',
  qtaDaSpedire: 0,
  um2: '',
  qta2DaSpedire: 0,
  qtaPrelevata: 0,
  prelievoConfermato: false,
  numColliDaSpedire: 0,
  numColliPrelevati: 0,
  numUnitaPerCollo: 0,
  codMagazzSpedizione: '',
  codAreaMagSpedizione: '',
  qtaUmMagDaSpedire: 0,
  qtaUmMagPrelevata: 0,
  umMag: '',
  forzaSaldoRigaOrdine: false
};

@Injectable({ providedIn: 'root' })
export class OrdinispedizioniService {
  public handleRefresh = new Subject();
  public handleSave: Subject<number> = new Subject();

  public searchOrdSpedGroup: BehaviorSubject<OrdSpedGroup> = new BehaviorSubject<OrdSpedGroup>(emptyOrdSped);

  public lastOrdSpedLaunched: Subject<OrdSped> = new Subject<OrdSped>();
  public lastOrdSpedCreated: BehaviorSubject<OrdSped> = new BehaviorSubject<OrdSped>(null);
  public lastOrdSpedUpdated: BehaviorSubject<OrdSped> = new BehaviorSubject<OrdSped>(null);
  public lastOrdSpedDeleted: BehaviorSubject<OrdSped> = new BehaviorSubject<OrdSped>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  private hubConnection: HubConnection;
  private currentOrdSpedGroup: OrdSpedGroup;

  constructor(
    private http: HttpClient,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {
    const ordineGroup = JSON.parse(localStorage.getItem(cachePrefix+'spedizioneGroup')) || emptyOrdSped;
    this.searchOrdSpedGroup.next(ordineGroup);
    this.currentOrdSpedGroup = ordineGroup;
  }

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/ordinispedizioni`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .configureLogging(LogLevel.Debug)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));
      this.hubConnection.onreconnected(() => this.hubState.next(1));
      this.hubConnection.onreconnecting(() => this.hubState.next(-1));
      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(spedizione: OrdSped) => {
        if (spedizione.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuova spedizione creato!');
          this.lastOrdSpedCreated.next(spedizione);
        }
      });

      this.hubConnection.on('SendUpdated',(spedizione: OrdSped) => {
        if (spedizione.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Ordine aggiornato!');
          this.lastOrdSpedUpdated.next(spedizione);
        }
      });

      this.hubConnection.on('SendDeleted',(spedizione: OrdSped) => {
        if (spedizione.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Ordine eliminato!');
          this.lastOrdSpedDeleted.next(spedizione);
        }
      });
    });
  }

  getEmptyOrdSped = () => emptyOrdSped;
  getEmptyOrdSpedEsolver = () => emptyOrdSpedEsolver;

  setOrdSpedFromEsolver(spedizione: OrdSped) {
    if (this.currentOrdSpedGroup.spedizioni == null){
      return false;
    }
    for (let i = 0; i < this.currentOrdSpedGroup.spedizioni.length; i++) {
      if (this.currentOrdSpedGroup.spedizioni[i].idDocumento === spedizione.idDocumento &&
        this.currentOrdSpedGroup.spedizioni[i].idRiga === spedizione.idRiga) {
        this.currentOrdSpedGroup.spedizioni[i] = spedizione;
      }
    }
  }

  setOrdSpedGroup(spedizioneGroup: OrdSpedGroup, onlyStorageUpdate?: boolean) {
    localStorage.setItem(cachePrefix+'spedizioneGroup', JSON.stringify(spedizioneGroup));
    if (!onlyStorageUpdate){
      this.searchOrdSpedGroup.next(spedizioneGroup);
      this.currentOrdSpedGroup = spedizioneGroup;
    }
  }

  resetOrdSpedGroup(onlyStorageUpdate?: boolean, isLoading?: boolean) {
    const copySpedizione = {...emptyOrdSped, isLoading };
    localStorage.setItem(cachePrefix+'spedizioneGroup', JSON.stringify(copySpedizione));
    if (!onlyStorageUpdate){
      this.searchOrdSpedGroup.next(copySpedizione);
    }
  }

  getSpedizioniInviatiFiltered(
    orgId: number,
    terminato: boolean,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    filters: FilterItem<any>[]) {
    return this.http.post<SearchResult<OrdSped>>(`${environment.localApiUrl}Spedizioni/OrdiniSpedizione`,
      { orgId, terminato, search, page, count, sortField, sortAsc, filters })
      .toPromise();
  }

  getSpedizioniEsolver(
    orgId: number,
    group: string,
    connectionName: string,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean,
    saldato: boolean,
    filters: FilterItem<any>[],
    noKeyFilter: boolean) {
    return this.http.post<SearchResult<OrdSpedEsolver>>(`${environment.localApiUrl}Spedizioni/OrdiniSpedizioneEsolver`,
      { orgId, group, connectionName, search, page, count, sortField, sortAsc, saldato, filters, noKeyFilter })
      .toPromise();
  }

  async createOrdineSpedizione(spedizione: OrdSped) {
    return this.http.post<OrdSped>(`${environment.localApiUrl}Spedizioni/CreateOrdineSpedizione`, spedizione)
      .toPromise();
  }

  async updateOrdineSpedizione(spedizione: OrdSped) {
    return this.http.post<OrdSped>(`${environment.localApiUrl}Spedizioni/UpdateOrdineSpedizione`, spedizione)
      .toPromise();
  }

  async deleteOrdineSpedizione(orgId: number, idDocumento: number, idRiga: number) {
    // eslint-disable-next-line max-len
    return this.http.get<OrdSped>(`${environment.localApiUrl}Spedizioni/DeleteOrdineSpedizione?orgId=${orgId}&idDocumento=${idDocumento}&idRiga=${idRiga}`)
      .toPromise();
  }

  getBoxOfSpedizione(orgId: number, idDocumento: number, idRiga: number) {
    return this.http
      .get<SearchResult<OrdSpedBox>>(`${environment.localApiUrl}Spedizioni/BoxOfOrdineSpedizione/${orgId}/${idDocumento}/${idRiga}`)
      .toPromise();
  }

  async createSpedizioneBox(box: OrdSpedBox) {
    return this.http.post<OrdSpedBox>(`${environment.localApiUrl}Spedizioni/CreateBox`, box)
      .toPromise();
  }

  async updateSpedizioneBox(box: OrdSpedBox) {
    return this.http.post<OrdSpedBox>(`${environment.localApiUrl}Spedizioni/UpdateBox`, box)
      .toPromise();
  }

  async deleteSpedizioneBox(orgId: number, idDocumento: number, idRiga: number, idBox: number) {
    // eslint-disable-next-line max-len
    return this.http.get<any>(`${environment.localApiUrl}Spedizioni/DeleteBox?orgId=${orgId}&idDocumento=${idDocumento}&idRiga=${idRiga}&idBox=${idBox}`)
      .toPromise();
  }
}
