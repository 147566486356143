import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FORMATO_BOX, FormatoBox} from '../../../_helpers/FormatoBox';

@Component({
  selector: 'app-select-box-formato',
  templateUrl: './select-box-formato.component.html',
  styleUrls: ['./select-box-formato.component.scss'],
})
export class SelectBoxFormatoComponent {
  @Input() formatoBox: FormatoBox;
  @Output() formatoBoxChange = new EventEmitter<FormatoBox>();
  formatoBoxsItem = FORMATO_BOX;
  selectFormatoBox = (e: any) => this.formatoBoxChange.emit(+e.target.value);
}
