import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {SearchResult, Articolo} from '../_models/_Index';
import { environment } from '../../environments/environment';
import {Giacenza} from '../_models/Giacenza';
import {Magazzino} from '../_models/Magazzino';
import {MagazzinoArea} from '../_models/MagazzinoArea';

@Injectable({ providedIn: 'root' })
export class ArticoliService {

  constructor(
    private http: HttpClient) {}

  async getEntitiesFromOrdiniAsyn(entityType: string, orgid: number, search: string, page: number, count: number) {
    return await this.http.post<SearchResult<Articolo>>(`${environment.localApiUrl}Articoli/${entityType}/${orgid}`,
      { search, page, count, sortField: '', sortAsc: true })
      .toPromise();
  }

  async getGiacenza(orgId: number, codArt: string) {
    return await this.http.post<SearchResult<Giacenza>>(`${environment.localApiUrl}Articoli/Giacenza/${orgId}`, { codArt })
      .toPromise();
  }

  async getMagazzini(orgId: number, tipiAnagr: number) {
    return await this.http.post<SearchResult<Magazzino>>(`${environment.localApiUrl}Articoli/Magazzini/${orgId}/${tipiAnagr}`, {})
      .toPromise();
  }

  async getAreeOfMagazzino(orgId: number, codMag: string) {
    return await this.http
      .post<SearchResult<MagazzinoArea>>(`${environment.localApiUrl}Articoli/AreeOfMagazzino/${orgId}`, { codMag })
      .toPromise();
  }
}
