import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SearchResult} from '../_models/SearchResult';
import {CqSchede} from '../_models/CqSchede';
import {CqCicloControllo} from '../_models/CqCicloControllo';
import {BehaviorSubject} from 'rxjs';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {OrganizzazioniService} from './organizzazioni.service';
import {SaveMessage} from '../_helpers/save-message';
import {ControlloQualita} from '../_models/ControlloQualita';
import {CqCaratteristica} from '../_models/CqCaratteristica';
import {OrdineGroup} from '../_models/OrdineGroup';
import * as signalR from "@microsoft/signalr";

@Injectable({ providedIn: 'root' })
export class QualitaService {
  public statoControlli: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public lastControlloCreated: BehaviorSubject<ControlloQualita> = new BehaviorSubject<ControlloQualita>(null);
  public lastControlloUpdated: BehaviorSubject<ControlloQualita> = new BehaviorSubject<ControlloQualita>(null);
  public lastControlloDeleted: BehaviorSubject<ControlloQualita> = new BehaviorSubject<ControlloQualita>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private hubConnection: HubConnection;

  constructor(
    private http: HttpClient,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {}

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/controlloqualita`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(controllo: ControlloQualita) => {
        if (controllo.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo controllo creato!');
          this.lastControlloCreated.next(controllo);
        }
      });

      this.hubConnection.on('SendUpdated',(controllo: ControlloQualita) => {
        if (controllo.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Controllo aggiornato!');
          this.lastControlloUpdated.next(controllo);
        }
      });

      this.hubConnection.on('SendDeleted',(controllo: ControlloQualita) => {
        if (controllo.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Controllo eliminato!');
          this.lastControlloDeleted.next(controllo);
        }
      });
    });
  }

  async getSchedeOfOdP(orgId: number, rifOdP: string, numFase: number) {
    return await this.http.post<SearchResult<CqSchede>>(`${environment.localApiUrl}Qualita/SchedeByRifOdP/${orgId}`,
      {page: 0, count: 0, search: rifOdP, numFase, sortField: 'IdSchedaControllo', sortAsc: true})
      .toPromise();
  }

  async getCaratteristicheById(orgId: number, idCaratteristica: number) {
    return await this.http
      .get<SearchResult<CqCaratteristica>>(`${environment.localApiUrl}Qualita/CaratteristicheById/${orgId}/${idCaratteristica}`)
      .toPromise();
  }

  async getControlliOfOdP(orgId: number, rifOdP: string, numFase: number) {
    return await this.http.post<SearchResult<CqCicloControllo>>(`${environment.localApiUrl}Qualita/ControlliByRifOdP/${orgId}`,
      {page: 0, count: 0, search: rifOdP, numFase, sortField: 'IdSchedaControllo', sortAsc: true})
      .toPromise();
  }

  async getControlliOfCodArt(orgId: number, codArt: string, numFase: number) {
    return await this.http.post<SearchResult<CqCicloControllo>>(`${environment.localApiUrl}Qualita/ControlliByCodArt/${orgId}`,
      {page: 0, count: 0, search: '', codArt, numFase, sortField: 'IdSchedaControllo', sortAsc: true})
      .toPromise();
  }

  async getControlli(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http
      .post<SearchResult<ControlloQualita>>(`${environment.localApiUrl}Qualita/SearchControlli/${orgId}`,
        {search, page, count, sortField, sortAsc})
      .toPromise();
  }

  async createControlloAsync(controlloQualita: ControlloQualita) {
    return await this.http.post<ControlloQualita>(`${environment.localApiUrl}Qualita/CreateControlloQualita`, controlloQualita)
      .toPromise();
  }

  async sendControlloAsync(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, controlloQualita: ControlloQualita, userName: string) {
    return await this.http.post<ControlloQualita>(`${environment.localApiUrl}Qualita/SendControlloQualita`,
      { orgId, dataEvento, ordineGroup, controlloQualita, userName })
      .toPromise();
  }

  async deleteControlloQualita(orgId: number, idSchedaControllo: number, idRigaCicloControllo: number, controlloId: number) {
    return await this.http
      .get<ControlloQualita>(`${environment.localApiUrl}Qualita/DeleteControlloQualita/${orgId}/${idSchedaControllo}/${idRigaCicloControllo}/${controlloId}`)
      .toPromise();
  }

  getIdOrdine(rifOrdine: string) {
    if (rifOrdine.length < 13) {
      return {idDocumento: 0, idRiga: 0};
    }
    return {idDocumento: parseInt(rifOrdine.substring(0,9),10), idRiga: parseInt(rifOrdine.substring(9,13),10)};
  }
}
