export const constants = {
  ROLE_ADMIN: 'ADMIN',
  ROLE_MANAGER: 'MANAGER',
  ROLE_OPER: 'OPER',
  ROLE_MES: 'MES',
  ROLE_TRANSFER: 'TRANSFER',
  ROLE_QUALITY: 'QUALITY',
  ROLE_RICEZIONE: 'RECEPTION',
  ROLE_SPE: 'SHIPPING',
  MOD_PRO_BASE: 'PRO-BASE',
  MOD_PRO_BASE_UI: 'PRO-BASE-UI',
  MOD_PRO_BASE_MAN: 'PRO-BASE-MAN',
  MOD_PRO_BASE_GROUP: 'PRO-BASE-GROUP',
  MOD_PRO_BASE_COLUMN: 'PRO-BASE-COLUMN',
  MOD_PRO_BASE_STA: 'PRO-BASE-STA',
  MOD_PRO_BASE_AVM_PLAN: 'PRO-BASE-AVM-PLAN',
  MOD_PRO_BASE_LOTTI: 'PRO-BASE-LOTTI',
  MOD_PRO_BASE_BARCODE: 'PRO-BASE-BARCODE',
  MOD_PRO_BASE_TRANSFER: 'PRO-BASE-TRANSFER',
  MOD_PRO_BASE_ATTACHMENT: 'PRO-BASE-ATTACHMENT',
  MOD_PRO_BASE_BALANCE: 'PRO-BASE-BALANCE',
  MOD_PRO_BASE_BOX: 'PRO-BASE-BOX',
  MOD_PRO_BASE_PRINTER: 'PRO-BASE-PRINTER',
  MOD_QUA_BASE_UI: 'QUA-BASE-UI',
  MOD_RIC_BASE_UI: 'RIC-BASE-UI',
  MOD_SPE_BASE_MAN: 'SPE-BASE-MAN',
  DDTACQ: 'DDTACQ',
  DDTMES: 'DDTMES',
  DDTMESL: 'DDTMESL',
  DDTMESLR: 'DDTMESLR'
  
};
