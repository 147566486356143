import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {LocalService} from '../../../_services/local.service';
import {FilterItem} from '../../../_models/FilterItem';
import {TipoFormControl} from '../../../_helpers/TipoFormControl';
import {SearchResult} from '../../../_models/SearchResult';
import {OrdineEsolver} from '../../../_models/OrdineEsolver';
import {SaveMessage} from '../../../_helpers/save-message';

@Component({
  selector: 'app-select-next-fase',
  templateUrl: './select-next-fase.component.html',
  styleUrls: ['./select-next-fase.component.scss'],
})
export class SelectNextFaseComponent  implements OnInit {
  @Input() backNext = 1;
  @Input() idDocumento = 0;
  @Input() idRiga = 0;
  @Input() numFase = 0;
  @Input() rifOdP = '';
  @Output() nextOrdine =  new EventEmitter<OrdineEsolver>();
  selectedOrdine: OrdineEsolver;
  nextOrdini: OrdineEsolver[] = [];
  filters: FilterItem<any>[] = [];
  loading = false;
  showOrdini = false;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private localService: LocalService) { }

  ngOnInit() {
    this.getOrdini(true);
  }

  getOrdini = (selectFirst: boolean) => {
    this.showOrdini = !this.showOrdini;
    if (!this.showOrdini) {
      this.nextOrdini = [];
      return false;
    }
    this.loading = true;
    this.filters = [
      { fieldName:'IdDocumento', operazione: 1, value1:this.idDocumento.toString(),value2:'', tipoFormControl: TipoFormControl.inputText, showColumn: false },
      { fieldName: this.backNext >= 0 ? 'NextPhase' : 'PrevPhase',operazione: 2,value1:this.rifOdP,value2:this.numFase.toString(),tipoFormControl:TipoFormControl.inputText,showColumn:false},
      { fieldName:'IdRiga',operazione: this.backNext >= 0 ? 10 : 9,value1:this.idRiga.toString(),value2:'',tipoFormControl:TipoFormControl.inputText,showColumn:false}
    ];
    this.localService.getOrdiniEsolver(this.organizzazioniService.organizzazioneId, this.organizzazioniService.gruppoCodice,
      this.organizzazioniService.organizzazioneCodice, '',0, 0,
      'RifOdP', this.backNext < 0, true, false, this.filters, true)
      .then((_result: SearchResult<OrdineEsolver>) => {
        if (_result.totalCount === 0) {
          this.nextOrdini = [];
          return false;
        }
        this.nextOrdini = _result.result;
        if (selectFirst) {
          this.selectFase(_result.result[0]);
        }
      })
      .catch((_err) => this.saveMessage.messageError('Problemi con il caricamento degli ordini pianificati in Esolver'))
      .finally(() => this.loading = false);
  };

  selectFase = (ordine: OrdineEsolver) => {
    this.selectedOrdine = ordine;
    this.nextOrdini = [];
    this.showOrdini = false;
    if (ordine){
      this.nextOrdine.next(ordine);
    }
  };
}
