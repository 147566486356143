import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  Risorsa, SearchResult, RisorsaOpcUa, RisorsaSyek, RisorsaLavorazione, RisorsaModBus,
  RisorsaZund, RisorsaMtConnect, RisorsaTracciato, Lavorazione, RisorsaEsolver, Evento
} from '../_models/_Index';
import { environment } from '../../environments/environment';
import {BehaviorSubject, Subject} from 'rxjs';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {OrganizzazioniService} from "./organizzazioni.service";
import {SaveMessage} from '../_helpers/save-message';
import * as signalR from "@microsoft/signalr";

const cachePrefix = 'archiconnect-v2-';

const emptyRisorsa: Risorsa = {
  reparto: '',
  organizzazioneId: 0,
  formato: '',
  indirizzoIp: '',
  id: 0,
  codice: '',
  descrizione: '',
  isActive: true,
  continuousLaunch: false,
  continuousSend: false,
  scartoSend: false,
  condition: 0,
  avanzaMode: 0,
  tipoRisorsa: 0
};
@Injectable({ providedIn: 'root' })
export class RisorseService {
  public lastRisorsaCreated: BehaviorSubject<Risorsa> = new BehaviorSubject<Risorsa>(null);
  public lastRisorsaUpdated: BehaviorSubject<Risorsa> = new BehaviorSubject<Risorsa>(null);
  public lastRisorsaDeleted: BehaviorSubject<Risorsa> = new BehaviorSubject<Risorsa>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private hubConnection: HubConnection;
  
  public handleSave: Subject<number> = new Subject();
  public searchRisorsa: BehaviorSubject<Risorsa> = new BehaviorSubject(emptyRisorsa);
  public myRisorse: BehaviorSubject<Risorsa[]> = new BehaviorSubject([]);

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private http: HttpClient,
    private saveMessage: SaveMessage) {
    const risorsaJson = localStorage.getItem(cachePrefix+'risorsa');
    if (risorsaJson !== undefined && risorsaJson !== 'undefined') {
      const risorsa = JSON.parse(risorsaJson) || emptyRisorsa;
      this.searchRisorsa.next(risorsa);
    }
  }

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/risorse`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(risorsa: Risorsa) => {
        if (risorsa.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuova risorsa creato!');
          this.lastRisorsaCreated.next(risorsa);
        }
      });

      this.hubConnection.on('SendUpdated',(risorsa: Risorsa) => {
        if (risorsa.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Risorsa aggiornata!');
          this.lastRisorsaUpdated.next(risorsa);
        }
      });

      this.hubConnection.on('SendDeleted',(risorsa: Risorsa) => {
        if (risorsa.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Risorsa eliminata!');
          this.lastRisorsaDeleted.next(risorsa);
        }
      });
    });
  }

  setRisorsa(risorsa: Risorsa) {
    if (risorsa !== undefined) {
      this.searchRisorsa.next(risorsa);
      localStorage.setItem(cachePrefix+'risorsa', JSON.stringify(risorsa));
    }
  }

  resetRisorsa() {
    this.searchRisorsa.next(emptyRisorsa);
    localStorage.setItem(cachePrefix+'risorsa', JSON.stringify(emptyRisorsa));
  }

  async getMyRisorseFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean, tipo: number, reparto: string) {
    return await this.http.post<SearchResult<Risorsa>>(`${environment.localApiUrl}Risorse/MyRisorseFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc, tipo, reparto })
      .toPromise();
  }

  async getRisorseFilteredAsync(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean, tipo: number, reparto: string) {
    return await this.http.post<SearchResult<Risorsa>>(`${environment.localApiUrl}Risorse/RisorseFiltered?orgId=${orgId}`,
      { search, page, count, sortField, sortAsc, tipo, reparto })
      .toPromise();
  }

  async getRisorsaAsync(orgId: number, code: string) {
    return await this.http.get<Risorsa>(`${environment.localApiUrl}Risorse/Risorsa?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async getRisorsaFromIdAsync(orgId: number, risorsaId: number) {
    return await this.http.get<Risorsa>(`${environment.localApiUrl}Risorse/RisorsaFromId?orgId=${orgId}&risorsaId=${risorsaId}`)
      .toPromise();
  }

  async createRisorsaAsync(risorsa: Risorsa) {
    return await this.http.post<Risorsa>(`${environment.localApiUrl}Risorse/CreateRisorsa`, risorsa)
      .toPromise();
  }

  async updateRisorsaAsync(risorsa: Risorsa) {
    return await this.http.post<Risorsa>(`${environment.localApiUrl}Risorse/UpdateRisorsa`, risorsa)
      .toPromise();
  }

  async deleteRisorsaAsync(orgId: number, code: string) {
    return await this.http.get<Risorsa>(`${environment.localApiUrl}Risorse/DeleteRisorsa?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async getRisorseOfAccountAsync(orgId: number, accountId: number) {
    return await this.http.get<SearchResult<Risorsa>>(`${environment.localApiUrl}Risorse/RisorseByAccountId?` +
      `orgId=${orgId}&accountId=${accountId}`)
      .toPromise();
  }

  setMyRisorse(orgId: number) {
    this.http.get<SearchResult<Risorsa>>(`${environment.localApiUrl}Risorse/MyRisorse?orgId=${orgId}`)
      .toPromise()
      .then((_risorse: SearchResult<Risorsa>) => this.myRisorse.next(_risorse.result));
  }

  async addRisorseToAccountByRepartoAsync(accountId: number, orgId: number, reparto: string, alsoLavorazioni: boolean) {
    return await this.http
      .post<any>(`${environment.localApiUrl}Risorse/AddRisorseToAccountByReparto`,
        { accountId, orgId, reparto, alsoLavorazioni})
      .toPromise();
  }

  async addRisorsaToAccountAsync(accountId: number, orgId: number, risorsaId: number, alsoLavorazioni: boolean) {
    return await this.http
      .post<Risorsa>(`${environment.localApiUrl}Risorse/AddRisorsaToAccount`,
        { accountId, orgId, risorsaId, alsoLavorazioni})
      .toPromise();
  }

  async removeRisorsaFromAccountAsync(accountId: number, orgId: number, risorsaId: number) {
    return await this.http
      .get<Risorsa>(`${environment.localApiUrl}Risorse/RemoveRisorsaFromAccount?` +
      `accountId=${accountId}&orgId=${orgId}&risorsaId=${risorsaId}`)
      .toPromise();
  }

  async getTracciatoOfRisorsaAsync(risorsaId: number, tracciatoId: number, typeId: number, risorsaTracciatoId: number) {
    return await this.http.get<RisorsaTracciato>(`${environment.localApiUrl}Risorse/TracciatoOfRisorsa?` +
    `risorsaId=${risorsaId}&tracciatoId=${tracciatoId}&typeId=${typeId}&risorsaTracciatoId=${risorsaTracciatoId}`)
      .toPromise();
  }

  async updateTracciatoOfRisorsaAsync(risorsaTracciato: RisorsaTracciato) {
    return await this.http.post<RisorsaTracciato>(`${environment.localApiUrl}Risorse/UpdateTracciatoOfRisorsa`, risorsaTracciato)
      .toPromise();
  }

  async getTracciatiOfRisorsaAsync(orgId: number, code: string) {
    return await this.http.get<SearchResult<RisorsaTracciato>>(`${environment.localApiUrl}Risorse/TracciatiOfRisorsa?` +
    `orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async addTracciatoToRisorsaAsync(risorsaTracciato: RisorsaTracciato) {
    return await this.http.post<RisorsaTracciato>(`${environment.localApiUrl}Risorse/AddTracciatoToRisorsa`, risorsaTracciato)
      .toPromise();
  }

  async removeTracciatoFromRisorsaAsync(risorsaId: number, tracciatoId: number, tracciatoType: number, risorsaTracciatoId: number) {
    return await this.http.get<Risorsa>(`${environment.localApiUrl}Risorse/RemoveTracciatoFromRisorsa?` +
    `risorsaId=${risorsaId}&tracciatoId=${tracciatoId}&tracciatoType=${tracciatoType}&risorsaTracciatoId=${risorsaTracciatoId}`)
      .toPromise();
  }

  async createRisorseLavorazioni(risorsaLavorazione: RisorsaLavorazione) {
    return await this.http.post<RisorsaLavorazione>(`${environment.localApiUrl}Risorse/CreateRisorseLavorazioni`, risorsaLavorazione)
      .toPromise();
  }

  async deleteRisorseLavorazioni(idRisorsa: number, idLavorazione: number) {
    // eslint-disable-next-line max-len
    return await this.http.get<RisorsaLavorazione>(`${environment.localApiUrl}Risorse/DeleteRisorseLavorazioni?idRisorsa=${idRisorsa}&idLavorazione=${idLavorazione}`)
      .toPromise();
  }

  async getLavorazioniRisorsaAsync(orgId: number, code: string) {
    // eslint-disable-next-line max-len
    return await this.http.get<SearchResult<Lavorazione>>(`${environment.localApiUrl}Lavorazioni/GetLavorazioniOfRisorsa?orgId=${orgId}&code=${code}`)
      .toPromise();
  }

  async getRisorsaOpcuaAsync(risorseId: number) {
    return await this.http.get<RisorsaOpcUa>(`${environment.localApiUrl}Risorse/RisorsaOpcUa?risorseId=${risorseId}`)
      .toPromise();
  }

  async createRisorsaOpcuaAsync(risorsaOpcUa: RisorsaOpcUa) {
    return await this.http.post<RisorsaOpcUa>(`${environment.localApiUrl}Risorse/CreateRisorsaOpcUa`, risorsaOpcUa)
      .toPromise();
  }

  async updateRisorsaOpcuaAsync(risorsaOpcUa: RisorsaOpcUa) {
    return await this.http.post<RisorsaOpcUa>(`${environment.localApiUrl}Risorse/UpdateRisorsaOpcUa`, risorsaOpcUa)
      .toPromise();
  }

  async deleteRisorsaOpcuaAsync(risorseId: number) {
    return await this.http.get<RisorsaOpcUa>(`${environment.localApiUrl}Risorse/DeleteRisorsaOpcUa?risorseId=${risorseId}`)
      .toPromise();
  }

  async getRisorsaModBusAsync(risorseId: number) {
    return await this.http.get<RisorsaModBus>(`${environment.localApiUrl}Risorse/RisorsaModBus?risorseId=${risorseId}`)
      .toPromise();
  }

  async createRisorsaModBusAsync(risorsaModBus: RisorsaModBus) {
    return await this.http.post<RisorsaModBus>(`${environment.localApiUrl}Risorse/CreateRisorsaModBus`, risorsaModBus)
      .toPromise();
  }

  async updateRisorsaModBusAsync(risorsaModBus: RisorsaModBus) {
    return await this.http.post<RisorsaModBus>(`${environment.localApiUrl}Risorse/UpdateRisorsaModBus`, risorsaModBus)
      .toPromise();
  }

  async deleteRisorsaModBusAsync(risorsaId: number) {
    return await this.http.get<RisorsaModBus>(`${environment.localApiUrl}Risorse/DeleteRisorsaModBus?risorsaId=${risorsaId}`)
      .toPromise();
  }

  async getRisorsaSyekAsync(risorseId: number) {
    return await this.http.get<RisorsaSyek>(`${environment.localApiUrl}Risorse/RisorsaSyek?risorseId=${risorseId}`)
      .toPromise();
  }

  async createRisorsaSyekAsync(risorsaSyek: RisorsaSyek) {
    return await this.http.post<RisorsaSyek>(`${environment.localApiUrl}Risorse/CreateRisorsaSyek`, risorsaSyek)
      .toPromise();
  }

  async updateRisorsaSyekAsync(risorsaSyek: RisorsaSyek) {
    return await this.http.post<RisorsaSyek>(`${environment.localApiUrl}Risorse/UpdateRisorsaSyek`, risorsaSyek)
      .toPromise();
  }

  async deleteRisorsaSyekAsync(risorseId: number) {
    return await this.http.get<RisorsaSyek>(`${environment.localApiUrl}Risorse/DeleteRisorsaSyek?risorseId=${risorseId}`)
      .toPromise();
  }

  async getRisorsaZundAsync(risorseId: number) {
    return await this.http.get<RisorsaZund>(`${environment.localApiUrl}Risorse/RisorsaZund?risorseId=${risorseId}`)
      .toPromise();
  }

  async createRisorsaZundAsync(risorsaZund: RisorsaZund) {
    return await this.http.post<RisorsaZund>(`${environment.localApiUrl}Risorse/CreateRisorsaZund`, risorsaZund)
      .toPromise();
  }

  async updateRisorsaZundAsync(risorsaZund: RisorsaZund) {
    return await this.http.post<RisorsaZund>(`${environment.localApiUrl}Risorse/UpdateRisorsaZund`, risorsaZund)
      .toPromise();
  }

  async deleteRisorsaZundAsync(risorseId: number) {
    return await this.http.get<RisorsaZund>(`${environment.localApiUrl}Risorse/DeleteRisorsaZund?risorseId=${risorseId}`)
      .toPromise();
  }

  async getRisorsaMtConnectAsync(risorseId: number) {
    return await this.http.get<RisorsaMtConnect>(`${environment.localApiUrl}Risorse/RisorsaMtConnect?risorseId=${risorseId}`)
      .toPromise();
  }

  async createRisorsaMtConnectAsync(risorsaMtConnect: RisorsaMtConnect) {
    return await this.http.post<RisorsaMtConnect>(`${environment.localApiUrl}Risorse/CreateRisorsaMtConnect`, risorsaMtConnect)
      .toPromise();
  }

  async updateRisorsaMtConnectAsync(risorsaMtConnect: RisorsaMtConnect) {
    return await this.http.post<RisorsaMtConnect>(`${environment.localApiUrl}Risorse/UpdateRisorsaMtConnect`, risorsaMtConnect)
      .toPromise();
  }

  async deleteRisorsaMotConnectAsync(risorsaId: number) {
    return await this.http.get<RisorsaMtConnect>(`${environment.localApiUrl}Risorse/DeleteRisorsaMtConnect?risorsaId=${risorsaId}`)
      .toPromise();
  }

  getRisorseEsolver(orgId: number) {
    return this.http.get<SearchResult<RisorsaEsolver>>(`${environment.localApiUrl}Esolver/Risorse/${orgId}`)
      .toPromise();
  }
}
