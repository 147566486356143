import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {LocalService} from '../../../_services/local.service';
import {Anagrafica} from '../../../_models/Anagrafica';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SearchResult} from '../../../_models/SearchResult';
import {SaveMessage} from '../../../_helpers/save-message';
import {Subscription} from 'rxjs';
import {Organizzazione} from '../../../_models/Organizzazione';

@Component({
  selector: 'app-select-fornitori',
  templateUrl: './select-fornitori.component.html',
  styleUrls: ['./select-fornitori.component.scss'],
})
export class SelectFornitoriComponent  implements OnInit, OnDestroy {
  @Input() selectedFornitoreId = 0;
  @Input() gruppoDoc = '';
  @Output() fornitoreChange = new EventEmitter<Anagrafica>();
  @Input() groupDocOriginale = 0;
  fornitori: Anagrafica[] = [];
  isOpen = true;
  subscription: Subscription;
  loading = false;
  constructor(
    private organizzazioneService: OrganizzazioniService,
    private localService: LocalService,
    private saveMessage: SaveMessage) {
    this.subscription = this.organizzazioneService.currentOrganizzazioneSubject
      .subscribe((_org: Organizzazione) => this.getFornitoriOrdiniAperti());
  }

  ngOnInit() {
    this.getFornitoriOrdiniAperti();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getFornitoriOrdiniAperti = () => {
    if (!this.gruppoDoc){
      return false;
    } 
    this.loading = true;
    this.localService
      .getFornitoriOrdiniApertiAsync(this.organizzazioneService.organizzazioneId, this.gruppoDoc, this.groupDocOriginale)
      .then((_fornitori: SearchResult<Anagrafica>) => {
        if (_fornitori.totalCount > 0) {
          this.fornitori = _fornitori.result;
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di fornitori'))
      .finally(() => this.loading = false);
  };

  selectFornitore = (fornitore: Anagrafica) => {
    if (!this.isOpen) {
      this.isOpen = true;
      return false;
    }
    this.isOpen = false;
    this.selectedFornitoreId = fornitore.id;
    this.fornitoreChange.emit(fornitore);
  };

  openList = () => this.isOpen = !this.isOpen;
}
