
import { SelectRisorseFormatComponent } from './_components/select/select-risorse-format/select-risorse-format.component';
import { SelectStatiOrdiniComponent } from './_components/select/select-stati-ordini/select-stati-ordini.component';
import { SelectTypeEventoComponent } from './_components/select/select-type-evento/select-type-evento.component';
import { SelectTracciatiComponent } from './_components/select/select-tracciati/select-tracciati.component';
import { SelectTracciatiFieldComponent } from './_components/select/select-tracciati-field/select-tracciati-field.component';
import { SelectTypeTracciatoComponent } from './_components/select/select-type-tracciato/select-type-tracciato.component';
import { SelectOrganizzazioneComponent } from './_components/select/select-organizzazione/select-organizzazione.component';
import { SelectFilterRowOperationComponent } from './_components/select/select-filter-row-operation/select-filter-row-operation.component';
import { SelectRisorsaComponent } from './_components/select/select-risorsa/select-risorsa.component';
import { SelectLavorazioneComponent } from './_components/select/select-lavorazione/select-lavorazione.component';
import { SelectDateRangeComponent } from './_components/select/select-date-range/select-date-range.component';
import { SelectDateModalComponent } from './_components/select/select-date-modal/select-date-modal.component';
import { SelectTracciatiTypeFieldComponent } from './_components/select/select-tracciati-type-field/select-tracciati-type-field.component';
import { SelectTypeInstallazioneComponent } from './_components/select/select-type-installazione/select-type-installazione.component';
import { SelectTypeUmtempoComponent } from './_components/select/select-type-umtempo/select-type-umtempo.component';
import { SelectTracciatiFieldTypeKeyComponent } from './_components/select/select-tracciati-field-type-key/select-tracciati-field-type-key.component';
import { SelectTracciatiDirectionComponent } from './_components/select/select-tracciati-direction/select-tracciati-direction.component';
import { SelectTracciatiLinkModeComponent } from './_components/select/select-tracciati-link-mode/select-tracciati-link-mode.component';
import { SelectTracciatiTerminatoModeComponent } from './_components/select/select-tracciati-terminato-mode/select-tracciati-terminato-mode.component';
import { SelectModuloComponent } from './_components/select/select-modulo/select-modulo.component';
import { SelectTracciatoFieldTypeFormatComponent } from './_components/select/select-tracciato-field-type-format/select-tracciato-field-type-format.component';
import { SelectGroupTypeComponent } from './_components/select/select-group-type/select-group-type.component';
import {SelectTypeFiltroOrdineComponent} from './_components/select/select-type-filtro-ordine/select-type-filtro-ordine.component';
import { SelectTypeAvvioComponent } from './_components/select/select-type-avvio/select-type-avvio.component';
import { SelectRisorseConditionComponent } from './_components/select/select-risorse-condition/select-risorse-condition.component';
import { SelectNextFaseComponent } from './_components/select/select-next-fase/select-next-fase.component';
import { SelectTypeSchedencComponent} from './_components/select/select-type-schedenc/select-type-schedenc.component';
import { SelectNccodiciComponent } from './_components/select/select-nccodici/select-nccodici.component';
import {SelectFornitoriComponent} from './_components/select/select-fornitori/select-fornitori.component';
import {SelectRisorseTypeComponent} from './_components/select/select-risorse-type/select-risorse-type.component';
import {SelectRisorseAvanzamodeComponent} from './_components/select/select-risorse-avanzamode/select-risorse-avanzamode.component';
import {SelectTypeMovimentoComponent} from './_components/select/select-type-movimento/select-type-movimento.component';
import {SelectBoxTipoComponent} from './_components/select/select-box-tipo/select-box-tipo.component';
import {SelectBoxFormatoComponent} from './_components/select/select-box-formato/select-box-formato.component';
import {SelectAvanzamentiComponent} from './_components/select/select-avanzamenti/select-avanzamenti.component';
import {SelectTerzistiComponent} from './_components/select/select-terzisti/select-terzisti.component';
import {SelectTracciatiSorgenteComponent} from './_components/select/select-tracciati-sorgente/select-tracciati-sorgente.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';

@NgModule({
  declarations: [
    SelectRisorseFormatComponent,
    SelectStatiOrdiniComponent,
    SelectTypeEventoComponent,
    SelectTracciatiComponent,
    SelectTracciatiFieldComponent,
    SelectTypeTracciatoComponent,
    SelectTypeInstallazioneComponent,
    SelectOrganizzazioneComponent,
    SelectFilterRowOperationComponent,
    SelectRisorsaComponent,
    SelectLavorazioneComponent,
    SelectDateRangeComponent,
    SelectDateModalComponent,
    SelectTracciatiTypeFieldComponent,
    SelectTypeUmtempoComponent,
    SelectTracciatiFieldTypeKeyComponent,
    SelectTracciatiDirectionComponent,
    SelectTracciatiLinkModeComponent,
    SelectTracciatiTerminatoModeComponent,
    SelectModuloComponent,
    SelectTracciatoFieldTypeFormatComponent,
    SelectGroupTypeComponent,
    SelectTypeFiltroOrdineComponent,
    SelectTypeAvvioComponent,
    SelectRisorseConditionComponent,
    SelectNextFaseComponent,
    SelectTypeSchedencComponent,
    SelectNccodiciComponent,
    SelectRisorseTypeComponent,
    SelectRisorseAvanzamodeComponent,
    SelectTypeMovimentoComponent,
    SelectFornitoriComponent,
    SelectBoxTipoComponent,
    SelectBoxFormatoComponent,
    SelectAvanzamentiComponent,
    SelectTerzistiComponent,
    SelectTracciatiSorgenteComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    IonicModule.forRoot()
  ],
  exports: [
    FormsModule,
    SelectRisorseFormatComponent,
    SelectStatiOrdiniComponent,
    SelectTypeEventoComponent,
    SelectTracciatiComponent,
    SelectTracciatiFieldComponent,
    SelectTypeTracciatoComponent,
    SelectTypeInstallazioneComponent,
    SelectTypeSchedencComponent,
    SelectRisorseTypeComponent,
    SelectOrganizzazioneComponent,
    SelectFilterRowOperationComponent,
    SelectRisorsaComponent,
    SelectLavorazioneComponent,
    SelectDateRangeComponent,
    SelectDateModalComponent,
    SelectTracciatiTypeFieldComponent,
    SelectTypeUmtempoComponent,
    SelectTracciatiFieldTypeKeyComponent,
    SelectTracciatiDirectionComponent,
    SelectTracciatiLinkModeComponent,
    SelectTracciatiTerminatoModeComponent,
    SelectModuloComponent,
    SelectTracciatoFieldTypeFormatComponent,
    SelectGroupTypeComponent,
    SelectTypeFiltroOrdineComponent,
    SelectTypeAvvioComponent,
    SelectRisorseConditionComponent,
    SelectNextFaseComponent,
    SelectNccodiciComponent,
    SelectRisorseAvanzamodeComponent,
    SelectTypeMovimentoComponent,
    SelectFornitoriComponent,
    SelectBoxTipoComponent,
    SelectBoxFormatoComponent,
    SelectAvanzamentiComponent,
    SelectTerzistiComponent,
    SelectTracciatiSorgenteComponent,
    IonicModule
  ],
  providers: []
})
export class SelectModule {}
