import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OrdSped} from '../../../_models/OrdSped';
import {Subscription} from 'rxjs';
import {SaveMessage} from '../../../_helpers/save-message';
import {LocalService} from '../../../_services/local.service';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {OrdinispedizioniService} from '../../../_services/ordinispedizioni.service';
import {Organizzazione} from '../../../_models/Organizzazione';
import {InfiniteScrollCustomEvent} from '@ionic/angular';
import {SearchResult} from '../../../_models/SearchResult';
import {Ordine} from "../../../_models/Ordine";

@Component({
  selector: 'app-spedizioni-locali-list',
  templateUrl: './spedizioni-locali-list.component.html',
  styleUrls: ['./spedizioni-locali-list.component.scss'],
})
export class SpedizioniLocaliListComponent  implements OnInit {
  @Input() updateEnable: boolean;
  @Input() flagTerminate: boolean;
  @Input() sortKey = 'dataConsegnaConferm';
  @Input() sortDirection = false;
  @Output()  spedizioneSended = new EventEmitter<OrdSped>();
  spedizioni: OrdSped[] = [];
  loading = false;
  page = 0;
  count = 50;
  totalCount = 0;
  loadMore = false;
  subscription: Subscription;
  constructor(
    private saveMessage: SaveMessage,
    private localService: LocalService,
    private organizzazioneService: OrganizzazioniService,
    private spedizioniService: OrdinispedizioniService) { }

  ngOnInit() {
    this.subscription = this.localService.searchText.subscribe((_value: string) => {
      this.getSpedizioni(_value, false);
    });
    this.subscription.add(this.organizzazioneService.currentOrganizzazioneSubject.subscribe((_org: Organizzazione) => {
      this.getSpedizioni('',false);
    }));
    this.subscription.add(this.spedizioniService.lastOrdSpedCreated.subscribe((_spedizione: OrdSped) => {
      if (_spedizione != null) {
        //this.newEventis.unshift(_evento);
        this.spedizioni.unshift(_spedizione);
      }
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  infiniteScrollEventi(event) {
    this.getSpedizioni('', true)
      .then(() => (event as InfiniteScrollCustomEvent).target.complete());
  }

  getSpedizioni = (search: string, add: boolean) => {
    this.loading = true;
    this.loadMore = true;
    if (!add) {
      this.page = 0;
    } else {
      this.page += 1;
    }
    return  this.spedizioniService
      .getSpedizioniInviatiFiltered(this.organizzazioneService.organizzazioneId,
        false, search, this.page, this.count, this.sortKey, this.sortDirection, [])
      .then((_spedizioni: SearchResult<OrdSped>) => {
        this.totalCount = _spedizioni.totalCount;
        if (_spedizioni.totalCount === 0) {
          this.spedizioni = [];
          this.loadMore = false;
          return false;
        }
        if (!add) {
          this.spedizioni = _spedizioni.result;
        } else {
          _spedizioni.result.forEach((spe: OrdSped) => this.spedizioni.push(spe));
        }
        if (_spedizioni.totalCount <= this.spedizioni.length) {
          this.loadMore = false;
        }
      })
      .catch((_err) => this.saveMessage.messageError('Ci sono problemi con il caricamento degli eventi'))
      .finally(() => this.loading = false);
  };

  selectSpedizione = (spedizione: OrdSped) => {
    this.spedizioneSended.emit(spedizione);
  };
}
