import {Injectable} from '@angular/core';
import {AlertController} from '@ionic/angular';

@Injectable({providedIn: 'root'})
export class MessageConfirm {
  constructor(private alertController: AlertController) {
  }

  async presentAlert(message: string, handleDelete: any) {
    await this.presentCustomAlert(message, 'OK', 'success', handleDelete);
  }

  async presentCustomAlert(message: string, confirmBtn: string, cssClass: string, handleDelete: any) {
    const alert = await this.alertController.create({
      header: message,
      buttons: [
        { text: 'Annulla', role: 'cancel'},
        { text: confirmBtn, role: 'confirm', cssClass: `alert-button-confirm-${cssClass}`, 
          handler: () => handleDelete() }
      ],
      cssClass: cssClass
    });
    await alert.present();
    
  }
}
