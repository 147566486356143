import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-quantita-input',
  templateUrl: './quantita-input.component.html',
  styleUrls: ['./quantita-input.component.scss'],
})
export class QuantitaInputComponent {
  @Input() udM = '';
  @Input() quantita = 0;
  @Output() quantitaChange = new EventEmitter<number>();
  @Input() quantitaResidua = 0;
  @Input() color = 'success';
  changeLavorata = (qta: number) => {
    this.quantita += qta;
    this.quantitaChange.emit(this.quantita);
  };
  setLavorata = (qta: number) => {
    this.quantita = qta;
    this.quantitaChange.emit(this.quantita);
  };
}
