import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {LottiService} from '../../_services/lotti.service';
import {Lotto} from '../../_models/Lotto';
import {SaveMessage} from '../../_helpers/save-message';
import {ArticoliService} from '../../_services/articoli.service';
import {SearchResult} from '../../_models/SearchResult';
import {Giacenza} from '../../_models/Giacenza';

@Component({
  selector: 'app-lotto-input',
  templateUrl: './lotto-input.component.html',
  styleUrls: ['./lotto-input.component.scss'],
})
export class LottoInputComponent  implements OnInit {
  @Input() readonly = false;
@Input() codArt: string;
@Input() rifLotto = '';
@Output() rifLottoChange = new EventEmitter<string>();
@Input() checkRifLotto = '';
@Input() codProdotto = '';
@Input() rifMagazzino = '';
@Output() rifMagazzinoChange = new EventEmitter<string>();
@Input() rifMagArea = '';
@Output() rifMagAreaChange = new EventEmitter<string>();
@Input() rifGiacenza = 0;
@Output() rifGiacenzaChange = new EventEmitter<number>();
@Input() giacenza: Giacenza;
@Output() giacenzaChange = new EventEmitter<Giacenza>();
@Input() checkOnInit = true;
lotto: Lotto = {
  codArt: '',
  rifLottoAlfa: '',
  dataAperturaLotto: new Date(),
  validForProdotto: true
};
giacenze: Giacenza[] = [];
showGiacenza = false;
isValid = -1;
loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private organizzazioniService: OrganizzazioniService,
    private lottiService: LottiService,
    private articoliService: ArticoliService) { }

  ngOnInit() {
    this.lotto.codArt = this.codArt;
    this.lotto.rifLottoAlfa = this.rifLotto;
    this.lotto.prodotto = this.codProdotto;
    if (this.rifLotto && this.rifLotto.length > 0 && this.checkOnInit) {
      this.getLotto(false);
    }
  }

  createLotto() {
    this.loading = true;
    this.lotto.des = null;
    this.lotto.dataAperturaLotto = new Date();
    this.lottiService.createLottoAsync(this.organizzazioniService.organizzazioneId, this.lotto)
      .then((_lotto: Lotto) => {
        if (!_lotto.codArt) {
          this.saveMessage.messageError('Problemi con creazione lotto');
          this.isValid = -1;
          return false;
        }
        this.saveMessage.messageInfo('Lotto creato con successo');
        this.lotto = _lotto;
        this.lotto.validForProdotto = true;
        this.isValid = 1;
        this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
      })
      .catch(() => this.saveMessage.messageInfo('Error creating lotto'))
      .finally(() => this.loading = false);
  }

  getLotto(loadGiacenza: boolean) {
    this.loading = true;
    this.isValid = -1;
    this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
    this.lottiService.verificaLottoAsync(this.organizzazioniService.organizzazioneId, this.lotto)
      .then((_lotto: Lotto) => {
        if (_lotto.codArt === this.lotto.codArt) {
          //this.saveMessage.messageInfo('Lotto trovato');
          this.lotto = _lotto;
          this.isValid = 1;
          this.rifLottoChange.emit(this.lotto.rifLottoAlfa);
          if (loadGiacenza) {
            this.getGiacenza(true);
          }
          return;
        }
        if (_lotto.rifLottoAlfa === this.lotto.rifLottoAlfa) {
          this.isValid = 0;
          this.saveMessage.messageError('Lotto trovato ma articolo diverso');
        }
      })
      .catch(() => this.saveMessage.messageInfo('Errore durante ricerca lotto'))
      .finally(() => this.loading = false);
  }

  getGiacenza(onlyLoad: boolean) {
    if (!onlyLoad) {
      this.showGiacenza = !this.showGiacenza;
      if (!this.showGiacenza) {
        this.giacenze = [];
        return false;
      }
    }
    this.loading = true;
    this.articoliService.getGiacenza(this.organizzazioniService.organizzazioneId, this.lotto.codArt)
      .then((_giacenze: SearchResult<Giacenza>) => {
        if (_giacenze.totalCount > 0) {
          if (!onlyLoad) {
            this.giacenze = _giacenze.result;
          } else {
            const giacenza = _giacenze.result
              .filter((_giacenza: Giacenza) => _giacenza.lotto === this.lotto.rifLottoAlfa);
            if (giacenza.length > 0) {
              this.selectGiacenza(giacenza[0], false);
            }
          }
        }
      })
      .catch(() => this.saveMessage.messageInfo('Error giacenza articolo'))
      .finally(() => this.loading = false);
  }

  selectGiacenza(giacenza: Giacenza, checkLotto: boolean) {
    this.lotto.rifLottoAlfa = giacenza.codLottoAlfa;
    this.rifMagazzinoChange.emit(giacenza.codMag);
    this.rifMagAreaChange.emit(giacenza.codAreaMag);
    this.rifGiacenzaChange.emit(giacenza.giacenza);

    this.giacenzaChange.emit(giacenza);

    if (checkLotto) {
      this.giacenze = [];
      this.showGiacenza = false;
      this.getLotto(false);
    }
  }
}
