import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrdSpedEsolver} from '../../../_models/OrdSpedEsolver';
import {Subscription} from 'rxjs';
import {SaveMessage} from '../../../_helpers/save-message';
import {LocalService} from '../../../_services/local.service';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {OrdinispedizioniService} from '../../../_services/ordinispedizioni.service';
import {InfiniteScrollCustomEvent} from '@ionic/angular';
import {SearchResult} from '../../../_models/SearchResult';
import {Organizzazione} from '../../../_models/Organizzazione';

@Component({
  selector: 'app-spedizioni-esolver-list',
  templateUrl: './spedizioni-esolver-list.component.html',
  styleUrls: ['./spedizioni-esolver-list.component.scss'],
})
export class SpedizioniEsolverListComponent  implements OnInit,OnDestroy {
  spedizioniEsolver: OrdSpedEsolver[] = [];
  sortDirection = false;
  sortKey = 'dataConsegnaConferm';
  loading = false;
  page = 0;
  count = 50;
  totalCount = 0;
  loadMore = false;
  subscription: Subscription;
  constructor(
    private saveMessage: SaveMessage,
    private localService: LocalService,
    private organizzazioneService: OrganizzazioniService,
    private spedizioniService: OrdinispedizioniService) { }

  ngOnInit() {
    this.subscription = this.localService.searchText.subscribe((_value: string) => {
      this.getSpedizioniEsolver(_value, false);
    });
    this.subscription.add(this.organizzazioneService.currentOrganizzazioneSubject.subscribe((_org: Organizzazione) => {
      this.getSpedizioniEsolver('',false);
    }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  infiniteScrollEventi(event) {
    this.getSpedizioniEsolver('', true)
      .then(() => (event as InfiniteScrollCustomEvent).target.complete());
  }

  getSpedizioniEsolver = (search: string, add: boolean) => {
    this.loading = true;
    this.loadMore = true;
    if (!add) {
      this.page = 0;
    } else {
      this.page += 1;
    }
    return  this.spedizioniService
      .getSpedizioniEsolver(this.organizzazioneService.organizzazioneId,
        this.organizzazioneService.currentOrganizzazioneValue.codiceGruppo,
        this.organizzazioneService.currentOrganizzazioneValue.codice,
        search, this.page, this.count, this.sortKey, this.sortDirection, false, [], false)
      .then((_spedizioniEsolver: SearchResult<OrdSpedEsolver>) => {
        this.totalCount = _spedizioniEsolver.totalCount;
        if (_spedizioniEsolver.totalCount === 0) {
          this.spedizioniEsolver = [];
          this.loadMore = false;
          return false;
        }
        if (!add) {
          this.spedizioniEsolver = _spedizioniEsolver.result;
        } else {
          _spedizioniEsolver.result.forEach((spe: OrdSpedEsolver) => this.spedizioniEsolver.push(spe));
        }
        if (_spedizioniEsolver.totalCount <= this.spedizioniEsolver.length) {
          this.loadMore = false;
        }
      })
      .catch((_err) => this.saveMessage.messageError('Ci sono problemi con il caricamento degli eventi'))
      .finally(() => this.loading = false);
  };
}
