import { Component, OnInit, Input } from '@angular/core';
import {CqCicloControllo} from "../../../_models/CqCicloControllo";

@Component({
  selector: 'app-controllo-qualita-item',
  templateUrl: './controllo-qualita-item.component.html',
  styleUrls: ['./controllo-qualita-item.component.scss'],
})
export class ControlloQualitaItemComponent  implements OnInit {
  @Input() cicloControllo: CqCicloControllo;
  constructor() { }

  ngOnInit() {}

}
