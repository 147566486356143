import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {SaveMessage} from '../../../_helpers/save-message';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SearchResult} from '../../../_models/SearchResult';
import {NcTipiSchede} from '../../../_models/NcTipiSchede';
import {NonconformeService} from '../../../_services/nonconforme.service';

@Component({
  selector: 'app-select-type-schedenc',
  templateUrl: './select-type-schedenc.component.html',
  styleUrls: ['./select-type-schedenc.component.scss'],
})
export class SelectTypeSchedencComponent  implements OnInit {
  @Input() idTipoSchedaNC: number;
  @Output() idTipoSchedaNCChange = new EventEmitter<number>();
  tipiSchedeNc: NcTipiSchede[] = [];
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private nonconformeService: NonconformeService,
    private organizzazioniService: OrganizzazioniService) { }

  ngOnInit() {
    this.getTipiSchedeNC();
  }

  getTipiSchedeNC = () => {
    this.loading = true;
    this.nonconformeService.getNcTipiSchede(this.organizzazioniService.organizzazioneId)
      .then((_tipiSchede: SearchResult<NcTipiSchede>) => this.tipiSchedeNc = _tipiSchede.result)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di tipi schede nc'))
      .finally(() => this.loading = false);
  };

  selectTipoScheda = (e: any) => this.idTipoSchedaNCChange.emit(+e.target.value);
}
