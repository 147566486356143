import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrdineGroup} from '../../../_models/OrdineGroup';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {MovimentiService} from '../../../_services/movimenti.service';
import {SaveMessage} from '../../../_helpers/save-message';
import {AuthenticationService} from '../../../_services/authentication.service';
import {ModalController} from '@ionic/angular';
import {constants} from '../../../_helpers/constants';
import {Movimento} from '../../../_models/Movimento';
import {AvanzamentoService} from '../../../_services/avanzamento.service';
import {OrdineEsolver} from '../../../_models/OrdineEsolver';
import {LocalService} from '../../../_services/local.service';
import {Giacenza} from '../../../_models/Giacenza';
import {Ordine} from '../../../_models/Ordine';
import {FilterItem} from '../../../_models/FilterItem';
import {OrdineByBarcode} from '../../../_models/OrdineByBarcode';
import {Subscription} from 'rxjs';
import {Organizzazione} from '../../../_models/Organizzazione';
import {Contenitore} from "../../../_models/Contenitore";

@Component({
  selector: 'app-movimento-trasferimento',
  templateUrl: './movimento-trasferimento.component.html',
  styleUrls: ['./movimento-trasferimento.component.scss'],
})
export class MovimentoTrasferimentoComponent  implements OnInit, OnDestroy {
  ordine: OrdineGroup;
  articoli: Ordine[] = [];
  codArt = '';
  udM = '';
  fromAvanzamento = false;
  quantitaTotale = 0;
  quantitaTrasferita = 0;
  useMagScarico = false;
  magazzinoScarico = '';
  magAreaScarico = '';
  magazzinoTraferimento = '';
  magAreaTrasferimento = '';
  generaDdt = false;
  codCliFor = 0;
  indirizzoNumProg = -1;
  codBox = '';
  loading = false;
  subscriptions: Subscription;
  protected readonly constants = constants;
  constructor(
    private localService: LocalService,
    private avanzamentoService: AvanzamentoService,
    private organizzazioneService: OrganizzazioniService,
    private movimentiService: MovimentiService,
    private saveMessage: SaveMessage,
    public authService: AuthenticationService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    if (this.ordine && this.ordine.ordini) {
      this.articoli.push(this.ordine.ordini[0]);
      this.codArt = this.ordine.codiceArticolo;
      this.udM = this.ordine.udM;
    }
    if (this.fromAvanzamento) {
      this.magazzinoScarico = this.ordine.codMagPrincipale;
      this.magAreaScarico = this.ordine.codAreaMagPrinc;
      // Riferimento per tracciato Esolver (Mag Richiamato)
      //this.ordine.risorsa.codMagazzino = this.ordine.codMagPrincipale;
      //this.ordine.risorsa.codMagArea = this.ordine.codAreaMagPrinc;
    }
    this.subscriptions = this.localService.searchText.subscribe((_search: string) => {
      if (_search == null || _search.length === 0) {
        return false;
      }
      // Check valid barcode
      const regex9 = /^[0-9]{9}$/gm;
      const regex13 = /^[0-9]{13}$/gm;
      const regex17 = /^[0-9]{17}$/gm;
      if (regex9.test(_search) ||regex13.test(_search) || regex17.test(_search)) {
        this.searchByBarcode(_search)
          .catch(() => this.saveMessage.messageError('Ci sono problemi con codice a barre di ordine.'));
      }
    });
    this.codCliFor = this.organizzazioneService.currentOrganizzazioneValue.codCliForEsolver;
    this.subscriptions.add(this.organizzazioneService.currentOrganizzazione
      .subscribe((_organizzazione: Organizzazione) => {
        this.codCliFor = _organizzazione.codCliForEsolver;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  selContenitore = (giacenza: Contenitore) => {
    this.quantitaTotale = giacenza.giacenza;
    if (!giacenza.rifOdP){
      this.saveMessage.messageError('Riferimento ad Ordine non disponibile')
      return false;
    }
    this.loading = true;
    this.localService
      .getOrdineByRifOdP(this.organizzazioneService.organizzazioneId,giacenza.rifOdP)
      .then((_ordine: Ordine) => {
        if (!_ordine || !_ordine.rifOdP){
          this.saveMessage.messageError('Ordine non disponibile')
          return false;
        }
        _ordine.codMagPrincipale = giacenza.codMagazzino;
        _ordine.codAreaMagPrinc = giacenza.codMagArea;
        _ordine.quantitaLavorata = giacenza.giacenza;
        _ordine.codBox = giacenza.codice;
        this.articoli.push(_ordine);
        const ordineGroup: OrdineGroup = {..._ordine, ordini: [_ordine], avvioType: 0 };
        this.ordine = ordineGroup;
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di ordine'))
      .finally(() => this.loading = false);
  };

  async searchByBarcode(barCode: string) {
    this.loading = true;
    const filters: FilterItem<any>[] = [
      { fieldName:'RifOrdine', operazione: 1, value1: barCode,value2:'', tipoFormControl: null, showColumn: false }
    ];
    this.avanzamentoService
      .getOrdiniByBarcode(this.organizzazioneService.organizzazioneId, false, filters, this.organizzazioneService.filtroOrdini)
      .then((_ordini: OrdineByBarcode) => {
        if (_ordini.ordini != null && _ordini.ordini.length > 0) {
          const ordineGroup: OrdineGroup = {..._ordini.ordini[0], ordini: [_ordini.ordini[0]], avvioType: 0 };
          this.ordine = ordineGroup;
          this.articoli.push(_ordini.ordini[0]);
        }
      })
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di ordine'))
      .finally(() => this.loading = false);
  }

  setMagazzino = (nextOrdine: OrdineEsolver,backNext: number) => {
    if (nextOrdine && nextOrdine.risorsaLocale && backNext >= 0) {
      this.magazzinoTraferimento = nextOrdine.risorsaLocale.codMagazzino;
      this.magAreaTrasferimento = nextOrdine.risorsaLocale.codMagArea;
    }
    if (nextOrdine && nextOrdine.risorsaLocale && backNext < 0) {
      this.magazzinoScarico = nextOrdine.risorsaLocale.codMagazzino;
      this.magAreaScarico = nextOrdine.risorsaLocale.codMagArea;
    }
  };
  confirm = () => {
    const dataEvento = Math.floor(new Date().getTime() / 1000);
    if (this.articoli.length > 0) {
      this.createMovimento(dataEvento);
      this.inviaTrasferimento(dataEvento);
      this.articoli = [];
      this.codBox = '';
      this.generaDdt = false;
      this.magazzinoScarico = '';
      this.magAreaScarico = '';
      this.magazzinoTraferimento = '';
      this.magAreaTrasferimento = '';
    }
  };
  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  inviaTrasferimento = (dataEvento: number) => {
    this.loading = true;
    this.articoli.forEach((_ordine: Ordine) => {
      _ordine.codMagPrincipale = this.magazzinoScarico;
      _ordine.codAreaMagPrinc = this.magAreaScarico;
      const ordineGroup: OrdineGroup = {..._ordine,
        magazzino:this.magazzinoTraferimento,magArea: this.magAreaTrasferimento,
        ordini: [_ordine], avvioType: 0 };
      this.avanzamentoService.invioTrasferimentoGroup(this.organizzazioneService.organizzazioneId,dataEvento,
        ordineGroup, _ordine.quantitaLavorata, this.authService.currentUserValue.userName)
        .catch(() => this.saveMessage.messageError('Ci sono problemi con creazione trasferimento'));
    });
    this.loading = false;
    if (this.fromAvanzamento) {
      this.modalCtrl.dismiss({}, 'confirm');
    }
  };

  createMovimento = (dataEvento: number) => {
    const movimenti = [];
    this.articoli.forEach((_ordine: Ordine) => {
      if (!_ordine.codiceArticolo || _ordine.quantitaLavorata <= 0 ||
        (_ordine.lastFase && _ordine.numFase !== _ordine.lastFase)) {
        return;
      }
      const movimento: Movimento = {
        organizzazioneId: this.organizzazioneService.organizzazioneId,
        id: 0,
        dataUnixRegistrazione: dataEvento,
        rifOdP: _ordine.rifOdP,
        idRigaMateriale: _ordine.idRiga,
        codArt: _ordine.codiceArticolo,
        unitaMisura: _ordine.udM,
        quantita: _ordine.quantitaLavorata,
        coeffX1: 1,
        codLottoAlfa: _ordine.rifLottoProdotto,
        codBox: _ordine.codBox,
        contesto: 0,
        tipoMovimento: 1,
        idAnagrafica: this.codCliFor,
        codMagazzinoIn: this.magazzinoTraferimento,
        codMagAreaIn: this.magAreaTrasferimento,
        codMagazzinoOut: this.useMagScarico ? this.magazzinoScarico : _ordine.codMagPrincipale,
        codMagAreaOut: this.useMagScarico ? this.magAreaScarico : _ordine.codAreaMagPrinc
      };
      movimenti.push(movimento);
      this.movimentiService.createMovimentoAsync(movimento)
        .catch(() => this.saveMessage
          .messageError(`Ci sono problemi con creazione movimento ${_ordine.codiceArticolo}`));
    });
    if (this.generaDdt) {
      this.movimentiService.ddtTrasferimentoInternoAsync(this.organizzazioneService.organizzazioneId,
        constants.DDTMES, movimenti)
        .then(() => this.saveMessage.messageInfo('Richiesta DDT inviata con successo'))
        .catch(() => this.saveMessage
          .messageError(`Ci sono problemi con creazione DDT`));
    }
  };

  removeMateriale = (materiale: Ordine) =>
    this.articoli = this.articoli.filter((_art: Ordine) => _art !== materiale);
}
