import {TracciatoFieldTypesEnum} from './TracciatoFieldTypes';

export enum TracciatoFieldsEnum {
  organizzazioneId = 'OrganizzazioneId',
  idDocumento = 'IdDocumento',
  idRiga = 'IdRiga',
  idRigaLavorazione = 'IdRigaLavorazione',
  rifOdP = 'RifOdP',
  codiceUnivoco = 'CodiceUnivoco',
  numFase = 'NumFase',
  dataEvento = 'DataEvento',
  dataRegistrazione = 'DataRegistrazione',
  dataRegistrazioneDate = 'DataRegistrazioneDate',
  dataRegistrazioneTime = 'DataRegistrazioneTime',
  dataTermine = 'DataTermine',
  dataTermineDate = 'DataTermineDate',
  dataTermineTime = 'DataTermineTime',
  numRegistrazione = 'NumRegistrazione',
  commessa = 'Commessa',
  risorsaId = 'RisorsaId',
  codiceRisorsa = 'CodiceRisorsa',
  lavorazioneId = 'LavorazioneId',
  codiceLavorazione = 'CodiceLavorazione',
  codiceArticolo = 'CodiceArticolo',
  quantita = 'Quantita',
  quantitaLavorata = 'QuantitaLavorata',
  quantitaLavorataCumolo = 'QuantitaLavorataCumolo',
  quantitaScartata = 'QuantitaScartata',
  quantitaScartataCumolo = 'QuantitaScartataCumolo',
  quantitaNonConforme = 'QuantitaNonConforme',
  quantitaAvanzata = 'QuantitaAvanzata',
  lottoProdotto = 'RifLottoProdotto',
  lottoMateriale = 'RifLottoMateriale',
  stato = 'StatoSpecial',
  statoRisorsa = 'StatoRisorsa',
  omacState = 'OmacState',
  flgTerminato = 'FlgTerminato',
  boxScarico = 'BoxScarico',
  giacenza = 'Giacenza',
  codice = 'Codice',
  codLottoAlfa = 'CodLottoAlfa',
  printAvanza = 'PrintAvanza',
  idTipoNc = 'IdTipoNc',
  idCodNc = 'IdCodNc',
  cqEsito = 'CqEsito',
  cqOperatore = 'CqOperatore',
  annotazioneNc = 'AnnotazioniNc'
}

export const TRACCIATO_FIELDS = [
  { value: TracciatoFieldsEnum.organizzazioneId, label: 'ID Organizzazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.organizzazioneId, label: 'ID Organizzazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.organizzazioneId, label: 'ID Organizzazione', fieldType: TracciatoFieldTypesEnum.box },
  { value: TracciatoFieldsEnum.idDocumento, label: 'ID Documento', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.idRiga, label: 'ID Riga', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.idRigaLavorazione, label: 'ID Riga Lavorazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.rifOdP, label: 'Rif. Ordine', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.rifOdP, label: 'Rif. Ordine', fieldType: TracciatoFieldTypesEnum.box },
  { value: TracciatoFieldsEnum.codiceUnivoco, label: 'Codice Univoco', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.numFase, label: 'Numero Fase', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataEvento, label: 'Data e Ora Evento', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataRegistrazione, label: 'Data e Ora Registrazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataRegistrazioneDate, label: 'Data Registrazione', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataRegistrazioneTime, label: 'Ora Registrazione', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataTermine, label: 'Data e Ora Termine', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.dataTermineDate, label: 'Data Termine', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.dataTermineTime, label: 'Ora Termine', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.numRegistrazione, label: 'Numero Registrazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.numRegistrazione, label: 'Numero Registrazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.commessa, label: 'Commessa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.commessa, label: 'Commessa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.risorsaId, label: 'ID Risorsa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.risorsaId, label: 'ID Risorsa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codiceRisorsa, label: 'Codice Risorsa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceRisorsa, label: 'Codice Risorsa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.lavorazioneId, label: 'ID Lavorazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.lavorazioneId, label: 'ID Lavorazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codiceLavorazione, label: 'Codice Lavorazione', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceLavorazione, label: 'Codice Lavorazione', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codiceArticolo, label: 'Codice Articolo', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.codiceArticolo, label: 'Codice Articolo', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.lottoProdotto, label: 'Lotto Prodotto', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.lottoProdotto, label: 'Lotto Prodotto', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.lottoMateriale, label: 'Lotto Materiale', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.lottoMateriale, label: 'Lotto Materiale', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.boxScarico, label: 'Contenitore materiale', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantita, label: 'Quantità Totale', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantita, label: 'Quantità Totale', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantitaLavorata, label: 'Quantità Lavorata', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantitaLavorata, label: 'Quantità Lavorata', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantitaLavorataCumolo, label: 'Quantità Lavorata Cumulo', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.quantitaScartata, label: 'Quantità Scartata', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantitaScartata, label: 'Quantità Scartata', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.quantitaScartataCumolo, label: 'Quantità Scartata Cumulo', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.quantitaNonConforme, label: 'Quantità Non conforme', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.quantitaAvanzata, label: 'Quantità Avanzata', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.stato, label: 'Stato', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.stato, label: 'Stato', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.statoRisorsa, label: 'Stato Risorsa', fieldType: TracciatoFieldTypesEnum.special },
  { value: TracciatoFieldsEnum.omacState, label: 'Ultimo Stato Risorsa', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.omacState, label: 'Ultimo Stato Risorsa', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.flgTerminato, label: 'Terminato', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.flgTerminato, label: 'Terminato', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.codice, label: 'Codice contenitore', fieldType: TracciatoFieldTypesEnum.box },
  { value: TracciatoFieldsEnum.codLottoAlfa, label: 'Lotto', fieldType: TracciatoFieldTypesEnum.box },
  { value: TracciatoFieldsEnum.giacenza, label: 'Giacenza contenitore', fieldType: TracciatoFieldTypesEnum.box },
  { value: TracciatoFieldsEnum.printAvanza, label: 'Stampa Avanzamento', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.printAvanza, label: 'Stampa Avanzamento', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.idTipoNc, label: 'Tipo NC', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.idTipoNc, label: 'Tipo NC', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.idCodNc, label: 'Codice NC', fieldType: TracciatoFieldTypesEnum.standard },
  { value: TracciatoFieldsEnum.idCodNc, label: 'Codice NC', fieldType: TracciatoFieldTypesEnum.fixed },
  { value: TracciatoFieldsEnum.cqEsito, label: 'Esito CQ', fieldType: TracciatoFieldTypesEnum.quality },
  { value: TracciatoFieldsEnum.cqOperatore, label: 'Operatore CQ', fieldType: TracciatoFieldTypesEnum.quality },
  { value: TracciatoFieldsEnum.annotazioneNc, label: 'Annotazione NC', fieldType: TracciatoFieldTypesEnum.quality },
];
