import {Component, Input} from '@angular/core';
import {Ordine} from '../../_models/Ordine';
import {AuthenticationService} from '../../_services/authentication.service';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {ModalController} from '@ionic/angular';
import {constants} from '../../_helpers/constants';
import {MovimentoTrasferimentoComponent} from '../movimenti/movimento-trasferimento/movimento-trasferimento.component';
import {OrdineAllegatiComponent} from "../ordine-allegati/ordine-allegati.component";

@Component({
  selector: 'app-ordine-info',
  templateUrl: './ordine-info.component.html',
  styleUrls: ['./ordine-info.component.scss'],
})
export class OrdineInfoComponent {
  @Input() ordine: Ordine;
  @Input() lavorata: number;
  @Input() scartata: number;
  @Input() nonConforme: number;
  @Input() qtaRimanente: number;
  @Input() isSendToEsolver: boolean;
  loading = false;
  protected readonly constants = constants;
  constructor(
    public authService: AuthenticationService,
    public organizzazioniService: OrganizzazioniService,
    private modalCtrl: ModalController) {}

  async launchTrasferimento() {
    const modal = await this.modalCtrl.create({
      component: MovimentoTrasferimentoComponent,
      backdropDismiss: false,
      componentProps: { ordine: this.ordine, fromAvanzamento: true },
      cssClass: 'custom-modal'
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.loading = false;
  };

  async openAllegati() {
    const modal = await this.modalCtrl.create({
      component: OrdineAllegatiComponent,
      backdropDismiss: false,
      componentProps: {rifOdP: this.ordine.rifOdP},
      cssClass: 'custom-modal'
    });
    await modal.present();
    const {data, role} = await modal.onWillDismiss();
    if (role === 'confirm') {}
  }
}
