import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NcCodici} from '../../../_models/NcCodici';
import {SaveMessage} from '../../../_helpers/save-message';
import {OrganizzazioniService} from '../../../_services/organizzazioni.service';
import {SearchResult} from '../../../_models/SearchResult';
import {NonconformeService} from '../../../_services/nonconforme.service';

@Component({
  selector: 'app-select-nccodici',
  templateUrl: './select-nccodici.component.html',
  styleUrls: ['./select-nccodici.component.scss'],
})
export class SelectNccodiciComponent  implements OnInit {
  @Input() idCodNC: number;
  @Output() idCodNCChange = new EventEmitter<number>();
  ncCodici: NcCodici[] = [];
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private nonconformeService: NonconformeService,
    private organizzazioniService: OrganizzazioniService) { }

  ngOnInit() {
    this.getNCCodici();
  }

  getNCCodici = () => {
    this.loading = true;
    this.nonconformeService.getNcCodici(this.organizzazioniService.organizzazioneId)
      .then((_ncCodici: SearchResult<NcCodici>) => this.ncCodici = _ncCodici.result)
      .catch(() => this.saveMessage.messageError('Ci sono problemi con caricamento di codici nc'))
      .finally(() => this.loading = false);
  };

  selectCodice = (e: any) => this.idCodNCChange.emit(+e.target.value);
}
