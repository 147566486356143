export enum TracciatoFieldTypesEnum {
  standard = 1,
  fixed = 2,
  special = 3,
  skip = 4,
  external = 5,
  quality = 6,
  box = 7
}

export const TRACCIATO_FIELD_TYPES = [
  { value: TracciatoFieldTypesEnum.standard, label: 'Standard' },
  { value: TracciatoFieldTypesEnum.fixed, label: 'Fisso' },
  { value: TracciatoFieldTypesEnum.special, label: 'Speciali' },
  { value: TracciatoFieldTypesEnum.skip, label: 'Salta' },
  { value: TracciatoFieldTypesEnum.external, label: 'Esterno' },
  { value: TracciatoFieldTypesEnum.quality, label: 'Controllo Qualità'},
  { value: TracciatoFieldTypesEnum.box, label: 'Contenitori'}
];
