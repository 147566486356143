import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Pesata} from '../_models/Pesata';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {OrganizzazioniService} from './organizzazioni.service';
import {SaveMessage} from '../_helpers/save-message';
import {environment} from '../../environments/environment';
import * as signalR from "@microsoft/signalr";

const cachePrefix = 'archiconnect-v2-';
@Injectable({ providedIn: 'root' })
export class BilanceService {
  public searchBilanciaCode: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public lastPesataCreated: BehaviorSubject<Pesata> = new BehaviorSubject<Pesata>(null);
  public lastPesataUpdated: BehaviorSubject<Pesata> = new BehaviorSubject<Pesata>(null);
  public lastPesataDeleted: BehaviorSubject<Pesata> = new BehaviorSubject<Pesata>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public activeCodArt: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private hubConnection: HubConnection;

  constructor(
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {
    const bilanciaCodeJson = localStorage.getItem(cachePrefix+'bilanciacode');
    if (bilanciaCodeJson !== undefined && bilanciaCodeJson !== 'undefined') {
      const bilanciaCode = bilanciaCodeJson || '';
      this.searchBilanciaCode.next(bilanciaCode);
    }
  }

  setBilanciaCode(bilanciaCode: string) {
    if (bilanciaCode !== undefined) {
      this.searchBilanciaCode.next(bilanciaCode);
      localStorage.setItem(cachePrefix+'bilanciacode', bilanciaCode);
    }
  }

  resetBilanciaCode() {
    this.searchBilanciaCode.next('');
    localStorage.setItem(cachePrefix+'bilanciacode', '');
  }

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.pesataHubUrl}hubs/pesata`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(pesata: Pesata) => {
        if (pesata.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo pesata creato!');
          this.lastPesataCreated.next(pesata);
        }
      });

      this.hubConnection.on('SendUpdated',(pesata: Pesata) => {
        if (pesata.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Pesata aggiornato!');
          this.lastPesataUpdated.next(pesata);
        }
      });

      this.hubConnection.on('SendDeleted',(pesata: Pesata) => {
        if (pesata.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Pesata eliminato!');
          this.lastPesataDeleted.next(pesata);
        }
      });
    });
  }
}
