import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-select-risorse-type',
  templateUrl: './select-risorse-type.component.html',
  styleUrls: ['./select-risorse-type.component.scss'],
})
export class SelectRisorseTypeComponent {
  @Input() tipoRisorsa = 0;
  @Output() tipoRisorsaChange = new EventEmitter<number>();
  tipiRisorses = [
    {value: 0, label: 'Tutto'},
    {value: 1, label: 'Produzione'},
    {value: 2, label: 'Qualità'},
    {value: 3, label: 'Bilance'},
    {value: 4, label: 'Centro di lavoro'},
    {value: 5, label: 'Stampante'},
    {value: 6, label: 'Raspberry'}
  ];
  selectTipoRisorsa = (e: any) => this.tipoRisorsaChange.emit(+e.target.value);
}
