export enum TipoBox {
  tutto = 0,
  conforme = 1,
  scarto = 2,
  nonConforme = 3
}

export const TIPO_BOX = [
  { value: TipoBox.tutto, label: 'Tutto' },
  { value: TipoBox.conforme, label: 'Conforme' },
  { value: TipoBox.scarto, label: 'Scarto' },
  { value: TipoBox.nonConforme, label: 'Non conforme' }
];
