import {Component, OnDestroy, OnInit} from '@angular/core';
import {OrdineEsolverGroup} from '../../_models/OrdineEsolverGroup';
import {Subscription} from 'rxjs';
import {GroupColumn} from '../../_models/OrdiniGroupColumns';
import {LocalService} from '../../_services/local.service';
import {ModalController} from '@ionic/angular';
import {Ordine, OrdineCreate} from '../../_models/Ordine';
import { OrdineEsolver } from '../../_models/OrdineEsolver';
import {StatoRisorsa} from '../../_helpers/stato-risorsa';
import {SaveMessage} from '../../_helpers/save-message';
import {EventiService} from '../../_services/eventi.service';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {OrdineGroup} from '../../_models/OrdineGroup';
import {Organizzazione} from '../../_models/Organizzazione';

@Component({
  selector: 'app-ordine-esolver-group-launch',
  templateUrl: './ordine-esolver-group-launch.component.html',
  styleUrls: ['./ordine-esolver-group-launch.component.scss'],
})
export class OrdineEsolverGroupLaunchComponent  implements OnInit,OnDestroy {
  public ordineEsolverGroup: OrdineEsolverGroup;
  ordineGroup: OrdineGroup;
  subscriptions: Subscription;
  groupColumns: GroupColumn[];
  changeRisorsa = true;
  changeLavorazione = true;
  loading = false;
  constructor(
    private saveMessage: SaveMessage,
    private localService: LocalService,
    private eventiService: EventiService,
    private organizzazioniService: OrganizzazioniService,
    private modalCtrl: ModalController) { }

  ngOnInit() {
    this.groupColumns = this.organizzazioniService.groupColumns;
    this.subscriptions = this.organizzazioniService.currentOrganizzazione
      .subscribe((_org: Organizzazione)=> {
        this.groupColumns = this.organizzazioniService.groupColumns;
      });
  }

  async launchOrdineEsolverGroup() {
    this.loading = true;
    this.ordineGroup = this.localService.getEmptyOrder();
    const promiseArray = [];
    this.ordineEsolverGroup.ordini.forEach((_ord: OrdineEsolver) => {
      const newOrdine: OrdineCreate = {
        idDocumento: _ord.idDocumento,
        idRiga: _ord.idRiga,
        idRigaLavorazione: _ord.idRigaLavorazione,
        organizzazioneId: this.organizzazioniService.organizzazioneId,
        risorsaId: 0,
        lavorazioneId: 0,
        dataRegistrazione: _ord.dataRegistrazione,
        numRegistrazione: _ord.numRegistrazione,
        codiceArticolo: _ord.codiceArticolo,
        descArticolo: _ord.descArt,
        quantita: _ord.quantita,
        lotto: 1,
        quantitaOrdinataUdM2: _ord.quantitaOrdinataUdM2,
        quantitaLavorata: 0,
        quantitaScartata: 0,
        quantitaNonConforme: 0,
        tempoLav: _ord.tempo,
        codMagPrincipale: _ord.codMagPrincipale,
        codAreaMagPrinc: _ord.codAreaMagPrinc,
        omacState: '',
        stato: StatoRisorsa.LAUNCH,
        flgTerminato: false,
        statoElimina: 0,
        dataTermine: _ord.dataTermine,
        codiceRisorsa: this.changeRisorsa ? this.ordineEsolverGroup.risorsa : _ord.risorsa,
        codiceLavorazione: this.changeLavorazione ? this.ordineEsolverGroup.lavorazione : _ord.lavorazione,
        commessa: _ord.commessa,
        umTempo: _ord.umTempo,
        note: '',
        rifLottoProdotto: _ord.rifLottoAlfanum,
        udM: _ord.udM,
        udM2: _ord.udM2,
        numFase: _ord.numFase,
        rifOdP: _ord.rifOdP,
        codCliente: _ord.id,
        ragSocialeCliente: _ord.ragSocialeCliente,
        faseContoLavoro: _ord.faseContoLavoro,
        codTerzista: _ord.codTerzista,
        ragSocialeTerzista: _ord.ragSocialeTerzista,
        lottoCode: this.organizzazioniService.autoLottoCode
      };
      promiseArray.push(this.createOrdine(newOrdine));
    });
    await Promise.all(promiseArray)
      .then(() => this.modalCtrl.dismiss(this.ordineGroup, 'launch'))
      .finally(() => this.loading = false);
  }

  createOrdine(ordine: OrdineCreate) {
    return this.localService.createOrdine(ordine)
      .then((ordineLocal: Ordine) => {
        this.ordineGroup.ordini.push(ordineLocal);
        this.saveMessage.messageInfo('Ordine lanciato con successo');
        this.eventiService.creaEvento(StatoRisorsa.LAUNCH,ordineLocal,new Date());
      })
      .catch(() => this.saveMessage.messageError('Problemi durante il lancio di ordine'));
  }

  checkGroupColumn = (columnName: string) => this.groupColumns
    .some(column => column.columnName === columnName && column.active);

  dismiss = () => this.modalCtrl.dismiss(true, 'save');

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
