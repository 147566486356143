import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DashboardHomeComponent } from './dashboard-home/dashboard-home.component';
import { AuthGuard } from './_services/auth.gard';
import {MovimentoTrasferimentoComponent} from './_components/movimenti/movimento-trasferimento/movimento-trasferimento.component';

const routes: Routes = [
  { path: '', component: DashboardHomeComponent },
  { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
  { path: 'ordini', loadChildren: () => import('./ordini/ordini.module').then(m => m.OrdiniModule), canActivate: [AuthGuard] },
  { path: 'risorse', loadChildren: () => import('./risorse/risorse.module').then(m => m.RisorseModule), canActivate: [AuthGuard] },
  { path: 'tracciati', loadChildren: () => import('./tracciati/tracciati.module').then(m => m.TracciatiModule), canActivate: [AuthGuard] },
  { path: 'lavorazioni', loadChildren: () => import('./lavorazioni/lavorazioni.module').then(m => m.LavorazioniModule), canActivate: [AuthGuard] },
  { path: 'eventi', loadChildren: () => import('./log/eventi.module').then(m => m.LogModule), canActivate: [AuthGuard] },
  { path: 'movimenti', loadChildren: () => import('./movimenti/movimenti.module').then(m => m.MovimentiModule), canActivate: [AuthGuard] },
  { path: 'articoli', loadChildren: () => import('./articoli/articoli.module').then(m => m.ArticoliModule), canActivate: [AuthGuard] },
  { path: 'qualita', loadChildren: () => import('./qualita/qualita.module').then(m => m.QualitaModule), canActivate: [AuthGuard] },
  { path: 'operatori', loadChildren: () => import('./operatori/operatori.module').then(m => m.OperatoriModule), canActivate: [AuthGuard] },
  { path: 'ruoli', loadChildren: () => import('./ruoli/ruoli.module').then(m => m.UserRoleModule), canActivate: [AuthGuard] },
  { path: 'organizzazioni', loadChildren: () => import('./organizzazioni/organizzazioni.module').then(m => m.OrganizzazioniModule), canActivate: [AuthGuard] },
  { path: 'licenze', loadChildren: () => import('./licenze/licenze.module').then(m => m.LicenzeModule), canActivate: [AuthGuard] },
  { path: 'interfacciaManualeBase', loadChildren: () => import('./interfaccia-manuale/interfaccia-manuale.module').then(m => m.InterfacciaManualeModule), canActivate: [AuthGuard] },
  { path: 'trasferimentoManuale', component: MovimentoTrasferimentoComponent, canActivate: [AuthGuard] },
  { path: 'spedizioneManualeBase', loadChildren: () => import('./spedizione-manuale/spedizione-manuale.module').then(m => m.SpedizioneManualeModule), canActivate: [AuthGuard]  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
