import { Ordine } from './Ordine';
import {TipoAvvio} from '../_helpers/TipoAvvio';

export class OrdineGroup extends Ordine {
  reparto?: string;
  ordini?: Ordine[];
  avvioType?: TipoAvvio;
  magazzino?: string;
  magArea?: string;
}
