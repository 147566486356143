import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-risorsa-tipo-label',
  templateUrl: './risorsa-tipo-label.component.html',
  styleUrls: ['./risorsa-tipo-label.component.scss'],
})
export class RisorsaTipoLabelComponent {
  @Input() risorsaTipo: number;
}
