import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-spedizione-update-send',
  templateUrl: './spedizione-update-send.component.html',
  styleUrls: ['./spedizione-update-send.component.scss'],
})
export class SpedizioneUpdateSendComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
