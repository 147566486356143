import { Injectable } from '@angular/core';
import {SearchResult, Organizzazione, Operatore, GroupColumn} from '../_models/_Index';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {AccountOrganizzazione} from '../_models/AccountOrganizzazione';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {DuplicateOrganizzazione} from "../_models/DuplicateOrganizzazione";

const cachePrefix = 'archiconnect-v2-';

const baseOrdiniGroupColumns: GroupColumn[] = [
  {order: 1, columnName: 'RifOrd', viewName: 'Riferimento Ordine', active: false},
  {order: 1, columnName: 'NumFase', viewName: 'Numero Fase', active: false},
  {order: 1, columnName: 'RisorsaId', viewName: 'Risorsa', active: false },
  {order: 1, columnName: 'Reparto', viewName: 'Reparto', active: false},
  {order: 1, columnName: 'CodCliente', viewName: 'Cliente', active: false},
  {order: 1, columnName: 'FaseContoLavoro', viewName: 'C/Lavoro', active: false},
  {order: 1, columnName: 'CodTerzista', viewName: 'Terzista', active: false},
  {order: 1, columnName: 'CodiceArticolo', viewName: 'Articolo', active: true},
  {order: 2, columnName: 'LavorazioneId', viewName: 'Lavorazione', active: true},
  {order: 1, columnName: 'Commessa', viewName: 'Commessa', active: false},
  {order: 1, columnName: 'NumRegistrazione', viewName: 'Numero Registrazione', active: false},
  {order: 1, columnName: 'IdDocumento', viewName: 'Id Documento', active: false},
  {order: 1, columnName: 'IdRiga', viewName: 'Id Riga', active: false},
  {order: 1, columnName: 'IdRigaLavorazione', viewName: 'Id Riga Lavorazione', active: false},
  {order: 1, columnName: 'UdM', viewName: 'Unità di misura', active: false},
  {order: 1, columnName: 'RifLottoProdotto', viewName: 'Lotto Prodotto', active: false},
  {order: 1, columnName: 'Stato', viewName: 'Stato', active: false}
];

@Injectable({ providedIn: 'root' })
export class OrganizzazioniService {
  public currentOrganizzazione: Observable<Organizzazione>;
  public handleSave: Subject<number> = new Subject();
  public showMenu: Subject<boolean> = new Subject();
  public currentOrganizzazioneSubject = new BehaviorSubject<Organizzazione>(null);
  constructor(private http: HttpClient) {
    this.currentOrganizzazioneSubject.next(JSON.parse(localStorage.getItem(cachePrefix+'currentOrg')));
    this.currentOrganizzazione = this.currentOrganizzazioneSubject.asObservable();
  }

  public get currentOrganizzazioneValue(): Organizzazione {
    return this.currentOrganizzazioneSubject.value;
  }
  public get organizzazioneId(): number {
    if (this.currentOrganizzazioneValue === null) {
      return 0;
    }
    return this.currentOrganizzazioneValue.id;
  };
  public get organizzazioneCodice(): string {
    if (this.currentOrganizzazioneValue === null) {
      return '';
    }
    return this.currentOrganizzazioneValue.codice;
  }
  public get gruppoCodice(): string {
    if (this.currentOrganizzazioneValue === null) {
      return '';
    }
    return this.currentOrganizzazioneValue.codiceGruppo;
  }
  public get esolverApiUrl(): string {
    if (this.currentOrganizzazioneValue === null) {
      return '';
    }
    return this.currentOrganizzazioneValue.indirizzoApiEsolver;
  }
  public get filtroOrdini(): number {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return 0;
    }
    return this.currentOrganizzazioneValue.accountSettings.tipoFiltroOrdini;
  }
  public get confirmQtaAvvio(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeQtaAvvio;
  }
  public get confirmQtaPausa(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeQtaPausa;
  }
  public get chiudiQtaFine(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeQtaFine;
  }
  public get confirmFine(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeConfirmFine;
  }
  public get transferMaterial(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeTranfer;
  }
  public get confirmAvanza(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeConfirmAvanza;
  }
  public get autoLottoCode(): string {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return '';
    }
    return this.currentOrganizzazioneValue.accountSettings.autoLottoCode;
  }
  public get risorsePersonal(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeFiltroRisorse;
  }
  public get lavorazioniPersonal(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeFiltroLavorazioni;
  }
  public get avvioType(): number {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return 0;
    }
    return this.currentOrganizzazioneValue.accountSettings.tipoAvvioRisorse;
  }
  public get viewGroup(): boolean {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings) {
      return false;
    }
    return this.currentOrganizzazioneValue.accountSettings.activeViewGroup;
  }
  public get groupColumns(): GroupColumn[] {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings ||
      !this.currentOrganizzazioneValue.accountSettings.groupColumns) {
      return baseOrdiniGroupColumns;
    }
    const columns = this.currentOrganizzazioneValue.accountSettings.groupColumns.split(',');
    const myOrdiniGroupColumns  = [...baseOrdiniGroupColumns];
    myOrdiniGroupColumns.forEach(baseColumn => {
      const colIndex = columns.indexOf(baseColumn.columnName);
      baseColumn.active = colIndex > -1;
      baseColumn.order = colIndex;
    });
    return myOrdiniGroupColumns;
  }
  public get groupColumnsArray(): string[] {
    if (!this.currentOrganizzazioneValue || !this.currentOrganizzazioneValue.accountSettings ||
      !this.currentOrganizzazioneValue.accountSettings.groupColumns) {
      return [];
    }
    return this.currentOrganizzazioneValue.accountSettings.groupColumns.split(',');
  }
  public setCurrentOrganizzazioneValue(organizzazione: Organizzazione) {
    this.currentOrganizzazioneSubject.next(organizzazione);
    localStorage.setItem(cachePrefix+'currentOrg', JSON.stringify(organizzazione));
  }

  public setCurrentOrganizzazioneValueById(organizzazioneId: number) {
    this.getMyOrganizzazioneAsync(organizzazioneId)
      .then((_organizzazione: Organizzazione) => this.setCurrentOrganizzazioneValue(_organizzazione));
  }

  public isStandalone(): boolean {
    if (this.currentOrganizzazioneValue == null) {
      return false;
    }
    return this.currentOrganizzazioneValue.tipoInstallazione === 3;
  }

  async getOrganizzazioniFilteredAsync(search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http.post<SearchResult<Organizzazione>>(`${environment.localApiUrl}Organizzazione/OrganizzazioniFiltered`,
      { search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getOrganizzazioneAsync(orgId: number) {
    return await this.http.get<Organizzazione>(`${environment.localApiUrl}Organizzazione/Organizzazione?orgId=${orgId}`)
      .toPromise();
  }

  async getMyOrganizzazioneAsync(orgId: number) {
    return await this.http.get<Organizzazione>(`${environment.localApiUrl}Organizzazione/MyOrganizzazione?orgId=${orgId}`)
      .toPromise();
  }

  async createOrganizzazioneAsync(organizzazione: Organizzazione) {
    return await this.http.post<Organizzazione>(`${environment.localApiUrl}Organizzazione/CreateOrganizzazione`, organizzazione)
      .toPromise();
  }

  async updateOrganizzazioneAsync(organizzazione: Organizzazione) {
    return await this.http.post<Organizzazione>(`${environment.localApiUrl}Organizzazione/UpdateOrganizzazione`, organizzazione)
      .toPromise();
  }

  async deleteOrganizzazioneAsync(orgId: number) {
    return await this.http.get<Organizzazione>(`${environment.localApiUrl}Organizzazione/DeleteOrganizzazione?orgId=${orgId}`)
      .toPromise();
  }

  async duplicateOrganizzazioneAsync(duplicateOrg: DuplicateOrganizzazione) {
    return await this.http.post<DuplicateOrganizzazione>(`${environment.localApiUrl}Organizzazione/DuplicateOrganizzazione`, duplicateOrg)
      .toPromise();
  }

  async getOperatoriOfOrganizzazioneAsync(orgId: number, search: string, page: 0, count: 0) {
    return await this.http.post<SearchResult<Operatore>>(`${environment.localApiUrl}Organizzazione/OperatoriOfOrganizzazione/${orgId}`,
      {search, page, count, })
      .toPromise();
  }

  async getOrganizzazioniOfOperatoreAsync(accountId: number) {
    return await this.http
      .get<SearchResult<Organizzazione>>(`${environment.localApiUrl}Organizzazione/OrganizzazioniOfOperatore?accountId=${accountId}`)
      .toPromise();
  }

  async getAccountOrganizzazioneAsync(orgId: number, accountId: number) {
    return await this.http
      .get<AccountOrganizzazione>(`${environment.localApiUrl}Organizzazione/GetAccountOrganizzazione?orgId=${orgId}&accountId=${accountId}`)
      .toPromise();
  }

  async addAccountToOrganizzazioneAsync(orgId: number, accId: number) {
    return await this.http.post<AccountOrganizzazione>(`${environment.localApiUrl}Organizzazione/AddAccountToOrganizzazione`,
      { organizzazioneId: orgId, accountId: accId})
      .toPromise();
  }

  async removeAccountFromOrganizzazioneAsync(orgId: number, accId: number) {
    return await this.http.get<any>(`${environment.localApiUrl}Organizzazione/RemoveAccountFromOrganizzazione?orgId=${orgId}&accountId=${accId}`)
      .toPromise();
  }

  async saveAccountOrganizzazioneAsync(accountOrganizzazione: AccountOrganizzazione) {
    return await this.http.post<any>(`${environment.localApiUrl}Organizzazione/SaveAccountOrganizzazione`, accountOrganizzazione)
      .toPromise();
  }
}
