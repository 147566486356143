import { Component, OnInit } from '@angular/core';
import {OrdineEsolver} from '../../_models/OrdineEsolver';
import {OrganizzazioniService} from '../../_services/organizzazioni.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {RisorseService} from '../../_services/risorse.service';
import {LocalService} from '../../_services/local.service';
import {EventiService} from '../../_services/eventi.service';
import {ModalController} from '@ionic/angular';
import {Ordine, OrdineCreate} from '../../_models/Ordine';
import {StatoRisorsa} from '../../_helpers/stato-risorsa';
import {FileZundComponent} from '../../ordini/file-zund/file-zund.component';
import {SaveMessage} from '../../_helpers/save-message';
import { constants } from '../../_helpers/constants';
import {Movimento} from "../../_models/Movimento";
import {MovimentiService} from "../../_services/movimenti.service";

@Component({
  selector: 'app-ordine-esolver-launch',
  templateUrl: './ordine-esolver-launch.component.html',
  styleUrls: ['./ordine-esolver-launch.component.scss'],
})
export class OrdineEsolverLaunchComponent implements OnInit {
  ordine: OrdineEsolver;
  newOrdine: OrdineCreate;
  loadFile = false;
  loading = false;
  ordineUse: Ordine;
  ordineInUse = false;
  today = new Date().getTime();
  constants = constants;
  constructor(
    public authService: AuthenticationService,
    private organizzazioniService: OrganizzazioniService,
    private risorseService: RisorseService,
    private localService: LocalService,
    private eventiService: EventiService,
    private movimentiService: MovimentiService,
    private modalCtrl: ModalController,
    private saveMessage: SaveMessage) { }

  ngOnInit() {
    this.newOrdine = {
      rifOrdine: this.ordine.rifOrdine,
      idDocumento: this.ordine.idDocumento,
      idRiga: this.ordine.idRiga,
      idRigaLavorazione: this.ordine.idRigaLavorazione,
      organizzazioneId: this.organizzazioniService.organizzazioneId,
      risorsaId: 0,
      lavorazioneId: 0,
      dataRegistrazione: this.ordine.dataRegistrazione,
      numRegistrazione: this.ordine.numRegistrazione,
      codiceArticolo: this.ordine.codiceArticolo,
      descArticolo: this.ordine.descArt,
      quantita: this.ordine.quantita,
      lotto: 1,
      quantitaOrdinataUdM2: this.ordine.quantitaOrdinataUdM2,
      quantitaLavorata: 0,
      quantitaScartata: 0,
      quantitaNonConforme: 0,
      tempoLav: this.ordine.tempo,
      omacState: '',
      stato: StatoRisorsa.LAUNCH,
      flgTerminato: false,
      statoElimina: 0,
      dataTermine: new Date(this.ordine.dataTermine),
      codiceRisorsa: this.ordine.risorsa,
      codiceLavorazione: this.ordine.lavorazione,
      commessa: this.ordine.commessa,
      umTempo: this.ordine.umTempo,
      note: '',
      codMagPrincipale: this.ordine.codMagPrincipale,
      codAreaMagPrinc: this.ordine.codAreaMagPrinc,
      udM: this.ordine.udM,
      udM2: this.ordine.udM2,
      numFase: this.ordine.numFase,
      rifLottoProdotto: this.ordine.rifLottoAlfanum,
      rifOdP: this.ordine.rifOdP,
      //codCliente: this.ordine.id,
      ragSocialeCliente: this.ordine.ragSocialeCliente,
      faseContoLavoro: this.ordine.faseContoLavoro,
      codTerzista: this.ordine.codTerzista,
      ragSocialeTerzista: this.ordine.ragSocialeTerzista,
      lottoCode: this.organizzazioniService.autoLottoCode
    };
    this.getOrdine();
  }

  getOrdine = () => {
    this.ordineInUse = false;
    this.ordineUse = null;
    this.localService.getOrdineAsync(this.organizzazioniService.organizzazioneId,
      this.ordine.idDocumento,this.ordine.idRiga,this.ordine.idRigaLavorazione)
      .then((_ordine: Ordine) => {
        this.ordineUse = _ordine;
        this.ordineInUse = true;
      });
  };

  sendOrdine() {
    // da modificare
    if (this.risorseService.searchRisorsa.getValue().formato === 'ZUND') {
      this.openModal(this.newOrdine, this.risorseService.searchRisorsa.getValue())
        .then(() => {
          if (this.loadFile) {
            this.createOrdine(this.newOrdine);
          }
        });
    } else {
      this.createOrdine(this.newOrdine);
    }
  }

  createOrdine(ordine: OrdineCreate) {
    this.loading = true;
    this.localService.createOrdine(ordine)
      .then((ordineLocal: Ordine) => {
        this.saveMessage.messageInfo('Ordine lanciato con successo');
        this.eventiService.creaEvento(StatoRisorsa.LAUNCH,ordineLocal,new Date())
          .then(() => this.modalCtrl.dismiss(ordineLocal, 'confirm'));
      })
      .catch(() => this.saveMessage.messageError('Problemi durante il lancio di ordine'))
      .finally(() => this.loading = false);
  }

  updateOrdine() {
    this.ordineUse.rifOrdine = this.newOrdine.rifOrdine;
    this.ordineUse.dataRegistrazione = this.newOrdine.dataRegistrazione;
    this.ordineUse.numRegistrazione = this.newOrdine.numRegistrazione;
    this.ordineUse.codiceArticolo = this.newOrdine.codiceArticolo;
    this.ordineUse.descArticolo = this.newOrdine.descArticolo;
    this.ordineUse.quantita = this.newOrdine.quantita;
    this.ordineUse.quantitaLavorata = this.newOrdine.quantitaLavorata;
    this.ordineUse.quantitaScartata = this.newOrdine.quantitaScartata;
    this.ordineUse.quantitaOrdinataUdM2 = this.newOrdine.quantitaOrdinataUdM2;
    this.ordineUse.tempoLav = this.newOrdine.tempoLav;
    this.ordineUse.stato = StatoRisorsa.LAUNCH;
    this.ordineUse.flgTerminato = false;
    this.ordineUse.statoElimina = 0;
    this.ordineUse.dataTermine = this.newOrdine.dataTermine;
    this.ordineUse.commessa = this.newOrdine.commessa;
    this.ordineUse.umTempo = this.newOrdine.umTempo;
    this.ordineUse.note = this.newOrdine.note;
    this.ordineUse.udM = this.newOrdine.udM;
    this.ordineUse.udM2 = this.newOrdine.udM2;
    this.ordineUse.numFase = this.newOrdine.numFase;
    this.ordineUse.codMagPrincipale = this.newOrdine.codMagPrincipale;
    this.ordineUse.codAreaMagPrinc = this.newOrdine.codAreaMagPrinc;
    this.ordineUse.rifLottoProdotto = this.newOrdine.rifLottoProdotto;
    this.ordineUse.rifOdP = this.newOrdine.rifOdP;
    this.ordineUse.codCliente = this.newOrdine.codCliente;
    this.ordineUse.ragSocialeCliente = this.newOrdine.ragSocialeCliente;
    this.ordineUse.faseContoLavoro = this.newOrdine.faseContoLavoro;
    this.ordineUse.codTerzista = this.newOrdine.codTerzista;
    this.ordineUse.ragSocialeTerzista = this.newOrdine.ragSocialeTerzista;
    this.loading = true;
    this.localService.updateOrdine(this.ordineUse)
      .then((ordineLocal: Ordine) => {
        this.saveMessage.messageInfo('Ordine aggiornato con successo');
        this.eventiService.creaEvento(StatoRisorsa.LAUNCH,ordineLocal,new Date())
          .then(() => this.modalCtrl.dismiss(ordineLocal, 'confirm'));
      })
      .catch(() => this.saveMessage.messageError('Problemi durante il lancio di ordine'))
      .finally(() => this.loading = false);
  }

  createMovimento(ordine: OrdineCreate) {
    this.loading = true;
    const movimento: Movimento = {
      organizzazioneId: this.organizzazioniService.organizzazioneId,
      id: 0,
      dataUnixRegistrazione: Math.floor(new Date().getTime() / 1000),
      rifOdP: ordine.rifOdP,
      idRigaMateriale: 0,
      codArt: ordine.codiceArticolo,
      unitaMisura: ordine.udM,
      quantita: ordine.quantitaLavorata,
      coeffX1: 1,
      codLottoAlfa: ordine.rifLottoProdotto,
      codMagazzinoIn: '', // _riga.codMag,
      codMagAreaIn: '', // _riga.codAreaMag,
      codBox: '',
      contesto: 4,  // Invio a Terzista
      codLav: ordine.codiceLavorazione,
      tipoMovimento: 1,
      currentGiacenza: 0,
      currentGiacenzaUdM2: 0,
      udM2: ordine.udM2,
      rifDocumento: ordine.rifOrdine,
      dataDocumento: ordine.dataRegistrazione,
      idAnagrafica: ordine.codTerzista,
      saldaRiga: false
    };

    this.movimentiService.ddtTrasferimentoInternoAsync(this.organizzazioniService.organizzazioneId,
      constants.DDTMESL,[movimento])
      .then((_movimento: Movimento) => this.modalCtrl.dismiss(true, 'confirm'))
      .catch(() => this.saveMessage.messageError('Ci sono problemi con creazione di movimenti carico'))
      .finally(() => this.loading = false);
  }
  
  cancel = () => this.modalCtrl.dismiss(false, 'cancel');

  async openModal(element, _risorsa) {
    const modal = await this.modalCtrl.create({
      component: FileZundComponent,
      backdropDismiss: false,
      componentProps: { ordine: element, risorsa: _risorsa, }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data) {
      this.loadFile = true;
    } else if (!data) {
      this.loadFile = false;
    }
  }
}
