import { BaseModel, RisorsaOpcUa, RisorsaSyek, RisorsaZund } from './_Index';

export class Risorsa extends BaseModel {
    reparto: string;
    organizzazioneId: number;
    formato: string;
    indirizzoIp: string;
    inputMask?: string;
    continuousLaunch: boolean;
    continuousSend: boolean;
    scartoSend: boolean;
    condition: number;
    stato?: number;
    opcUa?: RisorsaOpcUa;
    syek?: RisorsaSyek;
    zund?: RisorsaZund;
    odlEnd?: number;
    odlStart?: number;
    odlPause?: number;
    codMagazzino?: string;
    codMagArea?: string;
    codMagNc?: string;
    codMagAreaNc?: string;
    codMagScarti?: string;
    codMagAreaScarti?: string;
    avanzaMode: number;
    generaBox?: number;
    tipoRisorsa: number;
    printerId?: number;
    reportName?: string;
    reportNameScarto?: string;
    reportNameNc?: string;
}
