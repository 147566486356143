import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { LocalService } from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {EventiService} from '../../_services/eventi.service';
import {MovimentiService} from '../../_services/movimenti.service';
import {AvanzamentoService} from '../../_services/avanzamento.service';
import {QualitaService} from '../../_services/qualita.service';
import {SpedizioniService} from '../../_services/spedizioni.service';
import {AuthenticationService} from '../../_services/authentication.service';
import {constants} from '../../_helpers/constants';
import {RisorseService} from '../../_services/risorse.service';
import {BilanceService} from '../../_services/bilance.service';
import {OrdinispedizioniService} from "../../_services/ordinispedizioni.service";

@Component({
  selector: 'app-hub-state-led',
  templateUrl: './hub-state-led.component.html',
  styleUrls: ['./hub-state-led.component.scss'],
})
export class HubStateLedComponent implements OnInit, OnDestroy {
  @ViewChild('popover') popover;
  hubState = 1;
  hubRisorseState = 1;
  hubEventiState = 1;
  hubMovimentiState = 1;
  hubAvanzamentoState = 1;
  hubQualitaState = 1;
  hubOrdiniSpedizioniState = 1;
  hubSpedizioniState = 1;
  hubBilanceState = 1;
  isOpen = false;
  subscription: Subscription;
  protected readonly constants = constants;
  constructor(
    public authService: AuthenticationService,
    private localService: LocalService,
    private risorseService: RisorseService,
    private eventiService: EventiService,
    private movimentiService: MovimentiService,
    private avanzamentoService: AvanzamentoService,
    private qualitaService: QualitaService,
    private ordiniSpedizioniService: OrdinispedizioniService,
    private spedizioniService: SpedizioniService,
    private bilanceService: BilanceService) { }

  ngOnInit() {
    this.hubState = this.localService.hubState.value;
    this.subscription = this.localService.hubState.subscribe((_hubState: number) => {
        this.hubState = _hubState;
        if (_hubState === 0) {
          this.isOpen = true;
        }
      });
    this.subscription.add(this.risorseService.hubState.subscribe((_hubState: number) => {
      this.hubRisorseState = _hubState;
    }));
    this.subscription.add(this.eventiService.hubState.subscribe((_hubState: number) => {
      this.hubEventiState = _hubState;
    }));
    this.subscription.add(this.movimentiService.hubState.subscribe((_hubState: number) => {
      this.hubMovimentiState = _hubState;
    }));
    this.subscription.add(this.avanzamentoService.hubState.subscribe((_hubState: number) => {
      this.hubAvanzamentoState = _hubState;
    }));
    this.subscription.add(this.qualitaService.hubState.subscribe((_hubState: number) => {
      this.hubQualitaState = _hubState;
    }));
    this.subscription.add(this.ordiniSpedizioniService.hubState.subscribe((_hubState: number) => {
      this.hubOrdiniSpedizioniState = _hubState;
    }));
    this.subscription.add(this.spedizioniService.hubState.subscribe((_hubState: number) => {
      this.hubSpedizioniState = _hubState;
    }));
    this.subscription.add(this.bilanceService.hubState.subscribe((_hubState: number) => {
      this.hubBilanceState = _hubState;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  presentPopover(e: Event) {
    this.popover.event = e;
    this.isOpen = true;
  }
}
