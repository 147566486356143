import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TIPO_BOX, TipoBox} from '../../../_helpers/TipoBox';

@Component({
  selector: 'app-select-box-tipo',
  templateUrl: './select-box-tipo.component.html',
  styleUrls: ['./select-box-tipo.component.scss'],
})
export class SelectBoxTipoComponent {
  @Input() tipoBox: TipoBox;
  @Output() tipoBoxChange = new EventEmitter<TipoBox>();
  tipoBoxsItem = TIPO_BOX;
  selectTipoBox = (e: any) => this.tipoBoxChange.emit(+e.target.value);
}
