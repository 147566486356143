import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {SearchResult} from '../_models/SearchResult';
import {BehaviorSubject} from 'rxjs';
import {HubConnection, HubConnectionBuilder} from '@microsoft/signalr';
import {OrganizzazioniService} from './organizzazioni.service';
import {SaveMessage} from '../_helpers/save-message';
import {NcScheda} from '../_models/NcScheda';
import {NcTipiSchede} from '../_models/NcTipiSchede';
import {NcCodici} from '../_models/NcCodici';
import * as signalR from "@microsoft/signalr";
import {OrdineGroup} from "../_models/OrdineGroup";
import {ControlloQualita} from "../_models/ControlloQualita";

@Injectable({ providedIn: 'root' })
export class NonconformeService {
  public statoSchedaNC: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public lastSchedaNCCreated: BehaviorSubject<NcScheda> = new BehaviorSubject<NcScheda>(null);
  public lastSchedaNCUpdated: BehaviorSubject<NcScheda> = new BehaviorSubject<NcScheda>(null);
  public lastSchedaNCDeleted: BehaviorSubject<NcScheda> = new BehaviorSubject<NcScheda>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private hubConnection: HubConnection;

  constructor(
    private http: HttpClient,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {}

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/ncscheda`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));

      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(scheda: NcScheda) => {
        if (scheda.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuovo scheda nc creato!');
          this.lastSchedaNCCreated.next(scheda);
        }
      });

      this.hubConnection.on('SendUpdated',(scheda: NcScheda) => {
        if (scheda.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Scheda nc aggiornato!');
          this.lastSchedaNCCreated.next(scheda);
        }
      });

      this.hubConnection.on('SendDeleted',(scheda: NcScheda) => {
        if (scheda.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Controllo eliminato!');
          this.lastSchedaNCDeleted.next(scheda);
        }
      });
    });
  }

  async getNcTipiSchede(orgId: number) {
    return await this.http
      .get<SearchResult<NcTipiSchede>>(`${environment.localApiUrl}Qualita/NcTipiSchede/${orgId}`)
      .toPromise();
  }

  async getNcCodici(orgId: number) {
    return await this.http
      .get<SearchResult<NcCodici>>(`${environment.localApiUrl}Qualita/NcCodici/${orgId}`)
      .toPromise();
  }

  async createNcSchedaAsync(ncScheda: NcScheda) {
    return await this.http.post<NcScheda>(`${environment.localApiUrl}Qualita/CreateNcScheda`, ncScheda)
      .toPromise();
  }

  async sendNcSchedaAsync(orgId: number, dataEvento: number, ordineGroup: OrdineGroup, ncScheda: NcScheda, userName: string) {
    return await this.http.post<ControlloQualita>(`${environment.localApiUrl}Qualita/SendNcScheda`,
      { orgId, dataEvento, ordineGroup, ncScheda, userName })
      .toPromise();
  }

  async getSchedeNC(orgId: number, search: string, page: number, count: number, sortField: string, sortAsc: boolean) {
    return await this.http
      .post<SearchResult<NcScheda>>(`${environment.localApiUrl}Qualita/SearchSchedeNC/${orgId}`,
        {search, page, count, sortField, sortAsc})
      .toPromise();
  }

  getIdOrdine(rifOrdine: string) {
    if (rifOrdine.length < 13) {
      return {idDocumento: 0, idRiga: 0};
    }
    return {idDocumento: parseInt(rifOrdine.substring(0,9),10), idRiga: parseInt(rifOrdine.substring(9,13),10)};
  }
}
