import {Component, OnInit, Input, ViewChild} from '@angular/core';
import { LocalService } from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {IonInput,IonSearchbar} from '@ionic/angular';

@Component({
  selector: 'app-search-component',
  templateUrl: './search-component.component.html',
  styleUrls: ['./search-component.component.scss'],
})
export class SearchComponentComponent  implements OnInit {
  @Input() placeholder = 'Ricerca';
  @ViewChild('mySearchBar', { static: false }) searchbar!: IonSearchbar;
  showSearch = true;
  searchText: string;
  timeout = null;
  subscription: Subscription;
  constructor(
    private localService: LocalService) { }

  ngOnInit() {
    this.subscription = this.localService.searchText
      .subscribe((value: string) => this.searchText = value);
  }

  openSearch(){
    this.showSearch = true;
    //const searchbarInput = this.searchbar.getInputElement();
    //searchbarInput.then(input => input.focus());
  }
  search = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.localService.searchText.next(this.searchText.trim());
    }, 500);
  };

  cancelSearch = () => {
    this.searchText = '';
    this.localService.searchText.next(this.searchText);
  };

  closeSearch = () => {
    this.searchText = '';
    this.showSearch = false;
    this.localService.searchText.next(this.searchText);
  };
}
