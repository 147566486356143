import {BaseModel, Ordine} from './_Index';

export class Evento extends BaseModel {
  organizzazioneId?: number;
  dataRegistrazione: Date;
  dataUnixRegistrazione?: number;
  tipoEvento: number;
  idDocumento: number;
  idRiga: number;
  idRigaLavorazione: number;
  risorsaId: number;
  statoRisorsa: number;
  ordine?: Ordine;
  executedBy?: string;
  note?: string;
  idDocImportazione?: number;
  idMovimento?: number;
}

