import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OrganizzazioniService } from './organizzazioni.service';
import {BehaviorSubject, Subject} from 'rxjs';
import {HubConnection, HubConnectionBuilder, LogLevel} from '@microsoft/signalr';
import {SaveMessage} from '../_helpers/save-message';
import {Spedizione} from "../_models/Spedizione";
import {SearchResult} from "../_models/SearchResult";
import * as signalR from "@microsoft/signalr";

const cachePrefix = 'archiconnect-v2-';

@Injectable({ providedIn: 'root' })
export class SpedizioniService {
  public handleRefresh = new Subject();
  public handleSave: Subject<number> = new Subject();

  public lastSpedizioneLaunched: Subject<Spedizione> = new Subject<Spedizione>();
  public lastSpedizioneCreated: BehaviorSubject<Spedizione> = new BehaviorSubject<Spedizione>(null);
  public lastSpedizioneUpdated: BehaviorSubject<Spedizione> = new BehaviorSubject<Spedizione>(null);
  public lastSpedizioneDeleted: BehaviorSubject<Spedizione> = new BehaviorSubject<Spedizione>(null);
  public hubState: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  private hubConnection: HubConnection;
  
  constructor(
    private http: HttpClient,
    private organizzazioniService: OrganizzazioniService,
    private saveMessage: SaveMessage) {}

  public startConnection() {
    const retryTimes = [0, 3000, 10000, 60000];
    return new Promise((resolve, reject) => {
      this.hubConnection = new HubConnectionBuilder()
        .withUrl(`${environment.localHubUrl}hubs/spedizioni`, {
          skipNegotiation: true,
          transport: signalR.HttpTransportType.WebSockets,
          withCredentials: false
        })
        .configureLogging(LogLevel.Debug)
        .withAutomaticReconnect({
          nextRetryDelayInMilliseconds: context => {
            const index = context.previousRetryCount < retryTimes.length ? context.previousRetryCount : retryTimes.length - 1;
            return retryTimes[index];
          }
        })
        .configureLogging(signalR.LogLevel.Debug)
        .build();

      this.hubConnection.onclose(() => this.hubState.next(0));
      this.hubConnection.onreconnected(() => this.hubState.next(1));
      this.hubConnection.onreconnecting(() => this.hubState.next(-1));
      this.hubConnection.start()
        .then(() => {
          this.hubState.next(1);
          resolve(true);
        })
        .catch((err: any) => reject(err));

      this.hubConnection.on('SendCreated',(spedizione: Spedizione) => {
        if (spedizione.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Nuova spedizione creato!');
          this.lastSpedizioneCreated.next(spedizione);
        }
      });

      this.hubConnection.on('SendUpdated',(spedizione: Spedizione) => {
        if (spedizione.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Ordine aggiornato!');
          this.lastSpedizioneUpdated.next(spedizione);
        }
      });

      this.hubConnection.on('SendDeleted',(spedizione: Spedizione) => {
        if (spedizione.organizzazioneId === this.organizzazioniService.organizzazioneId) {
          this.saveMessage.messageInfo('Spedizione eliminato!');
          this.lastSpedizioneDeleted.next(spedizione);
        }
      });
    });
  }

  getSpedizioni(
    orgId: number,
    search: string,
    page: number,
    count: number,
    sortField: string,
    sortAsc: boolean) {
    return this.http.post<SearchResult<Spedizione>>(`${environment.localApiUrl}Spedizioni/Spedizioni/${orgId}`,
      { orgId, search, page, count, sortField, sortAsc })
      .toPromise();
  }

  async getSpedizione(orgId: number, idSpedizione: number) {
    // eslint-disable-next-line max-len
    return this.http.get<Spedizione>(`${environment.localApiUrl}Spedizioni/Spedizione/${orgId}/${idSpedizione}`)
      .toPromise();
  }

  async createSpedizione(spedizione: Spedizione) {
    return this.http.post<Spedizione>(`${environment.localApiUrl}Spedizioni/CreateSpedizione`, spedizione)
      .toPromise();
  }

  async updateSpedizione(spedizione: Spedizione) {
    return this.http.post<Spedizione>(`${environment.localApiUrl}Spedizioni/UpdateSpedizione`, spedizione)
      .toPromise();
  }

  async deleteSpedizione(orgId: number, idSpedizione: number) {
    // eslint-disable-next-line max-len
    return this.http.get<Spedizione>(`${environment.localApiUrl}Spedizioni/DeleteSpedizione?orgId=${orgId}&idSpedizione=${idSpedizione}`)
      .toPromise();
  }
}
