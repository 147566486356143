import {Component, EventEmitter, Input, OnInit, OnDestroy, Output} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { OrdineEsolver } from '../../_models/OrdineEsolver';
import { OrdineEsolverLaunchComponent } from '../ordine-esolver-launch/ordine-esolver-launch.component';
import {LocalService} from '../../_services/local.service';
import {Subscription} from 'rxjs';
import {OrdiniShowColumns} from '../../_models/OrdiniShowColumns';

@Component({
  selector: 'app-ordine-esolver-item',
  templateUrl: './ordine-esolver-item.component.html',
  styleUrls: ['./ordine-esolver-item.component.scss'],
})
export class OrdineEsolverItemComponent implements OnInit, OnDestroy{
  @Input() ordine: OrdineEsolver;
  @Input() activeLaunch = true;
  @Output() ordineSended = new EventEmitter<OrdineEsolver>();
  showColumns: OrdiniShowColumns;
  subscription: Subscription;
  loading = false;
  today = new Date().toISOString();
  constructor(
    private localService: LocalService,
    private modalCtrl: ModalController) {}

  ngOnInit() {
    this.showColumns = this.localService.showColumns.value;
    this.subscription = this.localService
      .showColumns.subscribe((_showColumns: OrdiniShowColumns) => this.showColumns = _showColumns);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async handleClick() {
    if (!this.activeLaunch) {
      this.ordineSended.emit(this.ordine);
      return false;
    }
    this.loading = true;
    const modal = await this.modalCtrl.create({
      component: OrdineEsolverLaunchComponent,
      backdropDismiss: false,
      componentProps: { ordine: this.ordine }
    });
    await modal.present();
    const { data } = await modal.onWillDismiss();
    this.loading = false;
    if (data) {
      this.localService.lastOrdineLaunched.next(data);
    }
  }
}
